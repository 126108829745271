import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { logout } from "../../store/authenticate";
import { get_platform } from "../../helpers/common";
import { gtm_date_time, webEngageEvent, webEngageUserLogout } from "../../helpers/google_tracking";
import { useEffect } from "react";
import { Modal } from "../common/common";
import M from "materialize-css";

function DashboardHeader(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const authState = useSelector((state) => state.auth);
  const location = useLocation();

  //to logout user call logout api
  const logOut = () => {
    dispatch(logout());
    webEngageEvent({
      event: "TAP_LOGOUT",
      platform: get_platform(),
      ...gtm_date_time(),
      uid: authState.userId || 0,
      mobile: authState.mobile || "",
    });

    webEngageUserLogout()
    if (window.IS_MOBILE_APP) {
      if (window.APP_LOGOUT_URL) window.location.href = decodeURIComponent(window.APP_LOGOUT_URL)
      else {
        if (typeof window.WebView != "undefined") window.WebView.setPreference("logout", "true");

        navigate("/login");
      }
    } else {
      // window.location.href = `${process.env.REACT_APP_TRIBYTE_DOMAIN}/logout?ts=${new Date().getTime()}`;
      navigate("/");
    }
  };

  useEffect(() => {
    let elem = document.querySelectorAll(".dropdown-trigger");
    M.Dropdown.init(elem);
  })

  return (
    <>
      <div className="container-fluid bgwhite headersticky">
        <div className="container">
          <nav>
            <div className="nav-wrapper">
              <div className="FlexHeaderContainer">
                <div className="FlexHeaderContainerLeft">
                  <div onClick={() => navigate("/dashboard")}
                    className="brand-logo"
                  ></div>
                </div>

                <ul className="header-right-content">
                  {location.pathname != "/purchase" &&
                    <li className="explore-plans">
                      <a
                        onClick={() => {
                          webEngageEvent({
                            event: "PLANS_LAND",
                            platform: get_platform(),
                            ...gtm_date_time(),
                            uid: authState.userId || 0
                          });
                          navigate("/purchase");
                        }}
                      >
                        Explore Plans
                      </a>
                    </li>}
                  <li className="user-phone modal-trigger" href="#contactInfoHeader" ></li>
                  <li className="user-icon dropdown-trigger btn" data-target="user-profile-drop"></li>
                  <div className="user-profile-popup dropdown-content" id="user-profile-drop">
                    <ul>
                      <li className="user-name">{authState.name}</li>
                      <li className="logout" onClick={logOut}>Logout</li>
                    </ul>
                  </div>
                </ul>

              </div>
            </div>
          </nav>
        </div>
      </div>

      <Modal modalId={"contactInfoHeader"} >
        <div className="contact-info">
          <h4>Contact us</h4>
          <div className="contact-details">
            <div className="phone-details">
              <div className="phone-icon"></div>
              <div className="phone-number">
                <span className="label">Call</span>
                <a href="tel:+918374884738">83748 84738 (10 am - 7 pm)</a>
              </div>
            </div>
            <div className="mail-details">
              <div className="mail-icon"></div>
              <div className="mail-id">
                <span className="label">EMail ID</span>
                <a href="mailto:pgpluscustomercare@aesl.in">pgpluscustomercare@aesl.in</a>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default DashboardHeader;
