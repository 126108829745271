import axios from "axios";
import { ApiUrls } from "../constants/constants";

export const getStates = async (countryId = 1) => {
    const responseData = await axios
    .get(
      `${ApiUrls.StateList}`+countryId,
      { withCredentials: true }
    )
    .then((res) => {

      if (!res.data.status || res.data.status != "1") {
        console.log("Error on getting user states");
        return null;
      }

      //if status success but no data (api return success with any userId)
      if (!res.data?.data?.states || Object.keys(res.data?.data?.states).length === 0) {
        console.log("Error on getting user states");
        return null;
      }

      return res.data.data.states;
    }).catch((err) => {
      console.log(err);
      return null;
    });

    return responseData;
};


export const getColgList = async (stateId = 1) => {
    const responseData = await axios
    .get(
      `${ApiUrls.ColgList}`+stateId+'&limit=250',
      { withCredentials: true }
    )
    .then((res) => {

      if (!res.data.status || res.data.status != "1") {
        console.log("Error on getting colg");
        return null;
      }

      //if status success but no data (api return success with any userId)
      if (!res.data?.data?.schools || res.data?.data?.schools.length === 0) {
        console.log("Error on getting colg");
        return null;
      }

      return res.data.data.schools;
    }).catch((err) => {
      console.log(err);
      return null;
    });

    return responseData;
};

export const getCities = async (stateId = 1) => {
  const responseData = await axios
  .get(
    `${ApiUrls.CityList}`+stateId,
    { withCredentials: true }
  )
  .then((res) => {

    if (!res.data.status || res.data.status != "1") {
      console.log("Error on getting user cities");
      return null;
    }

    //if status success but no data (api return success with any userId)
    if (!res.data?.data || Object.keys(res.data?.data).length === 0) {
      console.log("Error on getting user cities");
      return null;
    }

    return res.data.data;
  }).catch((err) => {
    console.log(err);
    return null;
  });

  return responseData;
};

export const getGradesList = async (countryId = 1) => {
  const responseData = await axios
  .get(
    ApiUrls.GradeList,
    { withCredentials: true }
  )
  .then((res) => {

    if (!res.data.status || res.data.status != "1") {
      console.log("Error on getting user states");
      return null;
    }

    //if status success but no data (api return success with any userId)
    if (res.data.data.length == '0') {
      console.log("Error on getting grade details");
      return null;
    }
    return res.data.data;
  }).catch((err) => {
    console.log(err);
    return null;
  });

  return responseData;
};
