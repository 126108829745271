import { useEffect, useState } from "react"
import StaticPagesLayout from "./staticPagesLayout"
import { getMobileOperatingSystem } from "../../helpers/common";
import { isMobile } from "react-device-detect";

function GetApp() {
    const [ua, setUa] = useState("desktop")
    useEffect(() => {
        if (isMobile) {
            const ua = getMobileOperatingSystem()
            setUa(ua)
            if (ua == "Android") {
                console.log("Android")
                window.location.href = "https://play.google.com/store/apps/details?id=com.tribyte.aakashpgplus"
            } else if (ua == "iOS") {
                window.location.href = "https://itunes.apple.com/in/app/aakash-pg-plus/id1660895666"
            }
        }
    }, [])

    return (
        <StaticPagesLayout>
            <center>
                <div className="qr-box">
                    <h3>Scan below QR code to download our App</h3>
                    <img src={process.env.REACT_APP_PUBLIC_URL +
                        "dist/img/app-qr-code.png?v=1.0"} alt="QR-code" />
                </div>
            </center>
        </StaticPagesLayout>
    )
}

export default GetApp