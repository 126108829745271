import StaticPagesLayout from "./staticPagesLayout";
import M from "materialize-css";
import { useEffect } from "react";
import { faqs } from "../../constants/constants";
import HTMLReactParser from "html-react-parser";

function Faq() {
  useEffect(() => {
    var elems = document.querySelectorAll(".collapsible");
    M.Collapsible.init(elems);
  });

  return (
    <StaticPagesLayout>
      <div className="row">
        <div className="FaqHeading">
          <h1>Frequently Asked Questions</h1>
        </div>
      </div>
      <div className="row">
        <div className="FaqCollapsibleMainDiv">
          <ul className="collapsible">
            {faqs.map((v, i) => (
              <li key={i} className={i == 0 ? "active" : ""}>
                <div className="collapsible-header">
                  <div className="col s11">{v.que}</div>
                  <div className="col s1">
                    <i className="material-icons iconcollapse">arrow_upward</i>
                  </div>
                </div>
                <div className="collapsible-body">
                  {HTMLReactParser(v.ans)}
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </StaticPagesLayout>
  );
}

export default Faq;
