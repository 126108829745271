import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { authActions } from "../../store/authenticate";
import M from 'materialize-css';

export const AlertMsg = (msg) => {
  const dispatch = useDispatch();
  const authState = useSelector((state) => state.auth);

  const hideErrorMsg = (e) => {
    e.preventDefault();
    dispatch(authActions.hideErrorMsg());
  };

  const hideSuccessMsg = (e) => {
    e.preventDefault();
    dispatch(authActions.hideSuccessMsg());
  };

  useEffect(() => {
    //clear error and success msg after 3 seconds
    if (authState.errorMsg) {
      dispatch(authActions.hideErrorMsg());
      console.log(authState.errorMsg);
      M.Toast.dismissAll();
      M.toast({html: '<div><span>'+authState.errorMsg+'</span>'});

      // setTimeout(() => {
      //   M.Toast.dismissAll();
      //  dispatch(authActions.hideErrorMsg());
      // }, 3000); 
    }
    if (authState.successMsg) {
      dispatch(authActions.hideSuccessMsg());
      console.log(authState.successMsg);
      M.Toast.dismissAll();
      M.toast({html: '<div><span>'+authState.successMsg+'</span>'});

      // setTimeout(() => {
      //   M.Toast.dismissAll();
      //   dispatch(authActions.hideSuccessMsg());
      // }, 3000); 
    }
  });

  return (
    <>
      {/* <div className="alertMsg row p-1">
      {authState.successMsg && (
        <div className="col s12 m12 l12 card-alert card green lighten-4 green-text text-darken-4 ">
          <div className="card-content ">
            <p>
              <i className="material-icons">check_circle</i>{" "}
              {authState.successMsg}
            </p>
          </div>
          <button
            type="button"
            onClick={hideSuccessMsg}
            className="close green-text text-darken-4"
            data-dismiss="alert"
            aria-label="Close"
          >
            <span aria-hidden="true">×</span>
          </button>
        </div>
      )}
      {authState.errorMsg && (
        <div className="col s12 m12 l12 card-alert card red lighten-4 red-text text-darken-4">
          <div className="card-content ">
            <p>
              <i className="material-icons">info_outline</i>
              {authState.errorMsg}
            </p>
          </div>
          <button
            type="button"
            onClick={hideErrorMsg}
            className="close red-text text-darken-4"
            data-dismiss="alert"
            aria-label="Close"
          >
            <span aria-hidden="true">×</span>
          </button>
        </div>
      )}
    </div> */}
    </>
  );
};
