import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ApiUrls } from "../../constants/constants";
import { getCities, getStates } from "../../helpers/address";
import M from "materialize-css";
import { Modal } from "../common/common";
import { authActions } from "../../store/authenticate";
import { getLocalItem } from "../../helpers/common";

const Address = (props) => {
  const authState = useSelector((state) => state.auth);
  const [address, setAddress] = useState({});
  const [addressString, setAddressString] = useState("");
  const [isAddressAvailable, setIsAddressAvailable] = useState(null); // three states - null- initial,0 - address not found from api, 1 - address found
  //order params
  //const [userAddressId, setUserAddressId] = useState(null); //defined in parent component
  const [stateList, setStateList] = useState({});
  const [cityList, setCityList] = useState({});
  const [postalAddress, setPostalAddress] = useState("");
  const [pincode, setPincode] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const [phone, setPhone] = useState("");
  const [addressType, setAddressType] = useState(1);
  const [isAddressUpdated, setIsAddressUpdated] = useState(false);
  const [isOnlyStateAddressChanged, setIsOnlyStateAddressChanged] = useState(false);
  const closeModalRef = useRef(null);
  const dispatch = useDispatch();

  let selectedAddOn = getLocalItem("productPgPlusaddOn");

  let errorInputStyle = {
    borderBottom: "1px solid #d73131",
    WebkitBoxShadow: "none",
    boxShadow: "none",
  };

  let errorSelectStyle = {
    border: "1px solid #d73131",
  };

  let inputStyle = {};

  // func to validate and set mobile
  const validateMobile = (phone) => {
    let mob = /^[6-9]\d{9}$/;
    if (mob.test(phone) === false) {
      setPhone("");
      dispatch(authActions.showErrorMsg({ msg: "Please enter valid mobile number!!" }));
      return false;
    } else {
      setPhone(phone);
    }
  }

  //func to validate pincode details
  const validatePinCode = (pinCode) => {
    axios.post(ApiUrls.PinCodeDetails, { pincode: pinCode }, { withCredentials: true })
      .then((res) => {
        if (res.data.data && res.data.data.pincode) {
          setPincode(res.data.data.pincode);
          setSelectedState(res.data.data.StateId);
          setSelectedCity(res.data.data.CityId);
        } else {
          setPincode("");
          setSelectedState("");
          setSelectedCity("");
          throw new Error("Oops! Seems this pincode is not valid as per our system!!");
        }
      }).catch((err) => {
        setPincode("");
        setSelectedState("");
        setSelectedCity("");
        console.log(err);
        dispatch(authActions.showErrorMsg({ msg: "Oops! Seems this pincode is not valid as per our system!!" }));
        return false;
      })
  }

  //function for address validation
  const validateAddress = (validationType = "1", selectedStateVal = "", showErr = true) => {
    if (validationType == "2") {
      // validation when only state is selected
      if ((selectedState == "" && selectedStateVal == "")) {
        dispatch(authActions.showErrorMsg({ msg: "Please select state" }));
        return false;
      }

      if (!phone || phone.trim() == "") {
        dispatch(authActions.showErrorMsg({ msg: "Your Phone number is missing. Please login to save address!!" }));
        setSelectedState("")
        return false;
      }

      if (authState.userId == "") {
        dispatch(authActions.showErrorMsg({ msg: "Details missing in payment link. Please contact support!!" }));
        return false;
      }

      props.setIsCorrectAddress(true)
    } else {
      if (selectedCity == "" || phone.trim() == "" || pincode.trim() == "" || postalAddress.trim() == "" || selectedState == "" || authState.userId == "") {
        if (showErr)
          dispatch(authActions.showErrorMsg({ msg: "Please fill all address fields" }));
        return false;
      }

      let adressReg = /^[a-zA-Z0-9.\s,'-]{4,50}$/;
      if (adressReg.test(postalAddress) === false) {
        dispatch(authActions.showErrorMsg({ msg: "Address can contain only alphanumeric,comma,space and dots within a range of 4 to 50 characters!!", }));
        return false;
      }

      props.setIsCorrectAddress(true)
    }
    return true;
  }

  // func to validate and save state based address
  const updateStateBasedAddress = (stateVal) => {
    let selectedStateVal = stateVal;
    setSelectedState(selectedStateVal);
    if (validateAddress(2, selectedStateVal) === false) {
      return false;
    }

    // if state is changed reset state related fields
    setPostalAddress("");
    setPincode("");
    setSelectedCity("");
    setSelectedCity("");

    //save data in useEffect with updated properties
    setIsOnlyStateAddressChanged(true);
  }

  // func to validate and then save address 
  const saveUserAddress = (e) => {
    e.preventDefault();

    if (validateAddress(1) === false) {
      return false;
    }

    //save data
    prepareAndSaveData();
  }

  // function to prepare and save data
  const prepareAndSaveData = () => {
    let bodyFormData = new FormData();
    bodyFormData.append("data[data][postalAddress]", postalAddress);
    bodyFormData.append("data[data][countryId]", 1);
    bodyFormData.append("data[data][stateId]", selectedState);
    bodyFormData.append("data[data][state]", stateList[selectedState] === undefined ? "" : stateList[selectedState]);
    bodyFormData.append("data[data][pincode]", pincode);
    bodyFormData.append("data[data][cityId]", selectedCity);
    let selectedCityName = (selectedState && selectedCity && selectedState >= '1' && selectedCity >= '1') ? cityList[selectedState][selectedCity] : "";
    bodyFormData.append("data[data][city]", selectedCityName === undefined ? "" : selectedCityName);
    bodyFormData.append("data[data][phone]", phone);
    bodyFormData.append("data[data][typeId]", addressType);
    bodyFormData.append("data[data][id]", props.userAddressId);
    bodyFormData.append("data[data][userId]", authState.userId);


    axios.post(ApiUrls.UpdateShippingAddress, bodyFormData, { withCredentials: true, })
      .then((res) => {
        if (res?.data?.status == "0") {
          throw new Error("Oops! Some error occurred while saving address!!");
        }

        if (res.data.status == "1" && res.data.status_code == 200) {
          setIsAddressUpdated(true);
          props.setUserAddressId(res.data.data.id);

          let addressCombined = postalAddress ? postalAddress : "";
          if (selectedState && selectedCity) {
            addressCombined += cityList[selectedState][selectedCity] ? (addressCombined != "" ? ", " : "") + cityList[selectedState][selectedCity] : "";
          }

          if (selectedState) {
            addressCombined += stateList[selectedState] ? (addressCombined != "" ? ", " : "") + stateList[selectedState] : "";
          }

          addressCombined += phone ? (addressCombined != "" ? ", " : "") + "Phone - " + phone : "";
          addressCombined += pincode ? (addressCombined != "" ? ", " : "") + "Pin - " + pincode : "";
          setAddressString(addressCombined);
          if ((parseFloat(props.finalAmount) >= parseFloat(process.env.REACT_APP_FULL_ADDRESS_AMOUNT) || selectedAddOn)) {
            closeModalRef.current.click();
          }

          console.log("Address Updated!!");
        }
      }).catch((err) => {
        console.log(err);
        dispatch(authActions.showErrorMsg({ msg: "Oops! Some error occurred while saving address!!" }));
        return false;
      })
  }

  //get state is async function so it returns a promise - so we need to wait promise to resolve
  const setStateDataList = () => {
    (async () => {
      setStateList(await getStates("1"));
    })();
  }

  //get city is async function so it returns a promise - so we need to wait promise to resolve
  const setCityDataList = () => {
    (async () => {
      setCityList(await getCities(selectedState));
    })();
  }

  useEffect(() => {
    setStateDataList();
    axios.get(ApiUrls.GetUserAddress, { params: { filters: "user_id:" + authState.userId, limit: 1 } })
      .then((res) => {
        if (res.data?.data && res.data?.data.length > 0) {
          props.setUserAddressId(res.data.data["0"].id);
          setAddress(res.data.data["0"]);
          setPostalAddress(res.data.data["0"].postal_address);

          let responsePinCode = res.data.data["0"].pincode ? res.data.data["0"].pincode : "";
          setPincode(responsePinCode);
          setSelectedState(res.data.data["0"].state_id);
          setSelectedCity(res.data.data["0"].city_id);
          setPhone(res.data.data["0"].phone);
          setIsAddressAvailable(1);

          let addressString = res.data.data["0"].postal_address ? res.data.data["0"].postal_address : "";
          addressString += res.data.data["0"].city ? (addressString != "" ? ", " : "") + res.data.data["0"].city : "";
          addressString += res.data.data["0"].state ? (addressString != "" ? ", " : "") + res.data.data["0"].state : "";
          addressString += res.data.data["0"].phone ? (addressString != "" ? ", " : "") + "Phone - " + res.data.data["0"].phone : "";
          addressString += res.data.data["0"].pincode ? (addressString != "" ? ", " : "") + "Pin - " + res.data.data["0"].pincode : "";
          setAddressString(addressString);
        } else {
          setIsAddressAvailable(0);
        }
      }).catch((err) => {
        setIsAddressAvailable(0);
        console.log(err);
      })
  }, [isAddressUpdated]);

  useEffect(() => {
    if (selectedState && selectedState >= 1) {
      setCityDataList(selectedState);
    }

    //save data
    if (selectedState >= 1 && isOnlyStateAddressChanged) {
      prepareAndSaveData();
    }
  }, [selectedState, isOnlyStateAddressChanged]);

  useEffect(() => { // when no address exist show state address
    if (!phone && authState.mobile10 != "" && phone != authState.mobile10) {
      setPhone(authState.mobile10)
    }

    if (!selectedState && authState.stateId >= 1 && selectedState != authState.stateId) {
      setSelectedState(authState.stateId)
    }
  }, [authState.stateId, authState.mobile10])

  useEffect(() => { //if any address not found from api save address
    if (isAddressAvailable === 0 && parseFloat(props.finalAmount) < parseFloat(process.env.REACT_APP_FULL_ADDRESS_AMOUNT) && selectedState >= 1 && phone > 1) {
      setIsOnlyStateAddressChanged(true) //to save
    }

    if (isAddressAvailable === 1 && (parseFloat(props.finalAmount) >= parseFloat(process.env.REACT_APP_FULL_ADDRESS_AMOUNT) || selectedAddOn)) {
      validateAddress(1, "", false)
    }
  }, [isAddressAvailable, props.finalAmount])

  return (
    <>
      {parseFloat(props.finalAmount) < parseFloat(process.env.REACT_APP_FULL_ADDRESS_AMOUNT) && !selectedAddOn && (
        <div className="row">
          <div className="col s12 m12">
            <div className="input-field AddressSelect">
              <p className="SelectStateP">Select Your State</p>
            </div>
          </div>
          <div className="input-field col s12 m12">
            <select
              className="browser-default PaymentSelectBox"
              name="state"
              id="state"
              value={selectedState}
              onChange={(e) => updateStateBasedAddress(e.target.value)}
              style={selectedState == "" ? errorSelectStyle : inputStyle}
            >
              <option value="">Select State</option>
              {stateList &&
                Object.keys(stateList).map((index) => {
                  return (
                    <option key={index} value={index}>
                      {stateList[index]}
                    </option>
                  );
                })}
            </select>
          </div>
        </div>
      )}
      {(parseFloat(props.finalAmount) >= parseFloat(process.env.REACT_APP_FULL_ADDRESS_AMOUNT) || selectedAddOn) && (
        <>
          <form className="paymentstateform">
            <div className="row">
              <div className="col s12 m12">
                <div className="paymentstate">

                  {selectedAddOn && <div className="ProductCartLeadForm"><h4 style={{ marginBottom: "10px" }}>Shipping address</h4></div>}
                  {!selectedAddOn && <p>Enter Your Address</p>}
                </div>
              </div>
              <div className="col s12 m12">
                <div className="input-field col s12">
                  <a className="modal-trigger" href="#modalAddress" ref={props.openModalRef}>
                    <input
                      id="address"
                      type="text"
                      defaultValue={addressString}
                      style={props.isCorrectAddress === false ? errorInputStyle : inputStyle}
                    />
                  </a>
                </div>
              </div>
            </div>
          </form>

          <Modal modalId={"modalAddress"} closeModalRef={closeModalRef}>
            <div className="ProductCartLeadForm">
              <form onSubmit={saveUserAddress}>
                {selectedAddOn && <h4>Shipping address</h4>}
                <div className="ProductFormInnerBox">
                  <div className="LeadFormLabel">
                    <label >Street address*</label>
                  </div>
                  <div className="LeadFormInput">
                    <input type="text" placeholder="Enter street address"
                      id="address"
                      onChange={(e) => setPostalAddress(e.target.value)}
                      value={postalAddress}
                      style={postalAddress == "" ? errorInputStyle : inputStyle} />
                  </div>
                </div>

                <div className="ProductFormInnerBox">
                  <div className="LeadFormLabel">
                    <label >Pincode*</label>
                  </div>
                  <div className="LeadFormInput">
                    <input id="pin"
                      type="text"
                      placeholder="Enter pincode"
                      value={pincode}
                      onBlur={(e) => validatePinCode(e.target.value)}
                      onChange={(e) => setPincode(e.target.value)}
                      style={pincode == "" ? errorInputStyle : inputStyle} />
                  </div>
                </div>

                <div className="ProductFormInnerBox">
                  <div className="LeadFormLabel">
                    <label >State*</label>
                  </div>
                  <div className="LeadFormInput">
                    <select
                      className="browser-default PaymentSelectBox"
                      name="state"
                      id="state"
                      value={selectedState}
                      onChange={(e) => {
                        setSelectedState(e.target.value);
                        setSelectedCity("");
                      }}
                      style={selectedState == "" ? errorSelectStyle : inputStyle}
                    >
                      <option value="">Select State</option>
                      {stateList &&
                        Object.keys(stateList).map((index) => {
                          return (
                            <option key={index} value={index}>
                              {stateList[index]}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                </div>

                <div className="ProductFormInnerBox">
                  <div className="LeadFormLabel">
                    <label >City*</label>
                  </div>
                  <div className="LeadFormInput">
                    <select
                      className="browser-default PaymentSelectBox"
                      name="city"
                      id="city"
                      value={selectedCity}
                      onChange={(e) => setSelectedCity(e.target.value)}
                      style={selectedCity == "" ? errorSelectStyle : inputStyle}
                    >
                      <option value="">Select City</option>
                      {cityList &&
                        cityList.hasOwnProperty(selectedState) === true &&
                        Object.keys(cityList[selectedState]).map((index) => {
                          return (
                            <option key={index} value={index}>
                              {cityList[selectedState][index]}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                </div>

                <div className="ProductFormInnerBox">
                  <div className="LeadFormLabel">
                    <label >Mobile*</label>
                  </div>
                  <div className="LeadFormInput">
                    <input id="phone"
                      type="text"
                      placeholder="Contact No."
                      value={phone}
                      onBlur={(e) => validateMobile(e.target.value)}
                      onChange={(e) => setPhone(e.target.value)}
                      style={phone == "" ? errorInputStyle : inputStyle} />
                  </div>
                </div>

                <div className="row">
                  <div className="col s12 m12">
                    <div className="input-field col s12 ProceedPageBTNBox nmp">
                      <button type="submit" className="btn btn-small ProceedBTN">
                        Save
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </Modal>
        </>
      )}
    </>
  );
};

export default Address;
