import { useEffect } from "react";
import { useSelector } from "react-redux";
import { ApiUrls } from "../../constants/constants";
import { removeLocalItem } from "../../helpers/common";

const PayuCheckout = (props) => {
  removeLocalItem("productPgPlus");
  const authState = useSelector((state) => state.auth);
  const orderResponse = props.orderResponse;
  const userEmail =
    orderResponse.transactionDetails.email != ""
      ? orderResponse.transactionDetails.email
      : authState.mobile10 + "@pg.com";

  useEffect(() => {
    setTimeout("document.productcheckout.submit();", 100);
  }, []);

  return (
    <>
      <form name="productcheckout" action={orderResponse.postUrl} method="post">
        <input
          type="hidden"
          name="key"
          value={orderResponse.transactionDetails.key}
        />
        <input type="hidden" name="hash" value={orderResponse.checksum} />
        <input
          type="hidden"
          name="txnid"
          value={orderResponse.transactionDetails.txnid}
        />
        <input
          type="hidden"
          name="amount"
          value={orderResponse.transactionDetails.amount}
        />
        <input
          type="hidden"
          name="firstname"
          value={orderResponse.transactionDetails.firstname}
        />
        <input type="hidden" name="phone" value={authState.mobile10} />
        <input type="hidden" name="email" value={userEmail} />
        <input
          type="hidden"
          name="productinfo"
          value={orderResponse.transactionDetails.productinfo}
        />
        <input type="hidden" name="surl" value={ApiUrls.PGResponse} />
        <input type="hidden" name="furl" value={ApiUrls.PGResponse} />
        <input type="hidden" name="curl" value={ApiUrls.PGResponse} />
        <input type="hidden" name="bankcode" value="" />
        <input type="hidden" name="pg" value="" />
      </form>

      <div className="container-fluid">
        <div className="row PaymentSuccPageHeight">
          <div className="col s12 m12">
            <div className="PaymentSuccess">
              <div className="Paymentprocform">
                <div className="PaymentProcessing">
                  <div className="preloader-wrapper big active">
                    <div className="spinner-layer spinner-blue-only">
                      <div className="circle-clipper left">
                        <div className="circle"></div>
                      </div>
                      <div className="gap-patch">
                        <div className="circle"></div>
                      </div>
                      <div className="circle-clipper right">
                        <div className="circle"></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <form className="col s12">
                    <div className="row">
                      <div className="col s12">
                        <div className="PaymentProsubheading">
                          <p>
                            Processing...! You are being redirected to payment
                            gateway.
                          </p>
                          <p>
                            <span>
                              [ Please do not close/refresh this window ]
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PayuCheckout;
