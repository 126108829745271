import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ApiUrls } from "../../../constants/constants";
import { getCities, getStates } from "../../../helpers/address";
import { Modal } from "../../common/common";
import { authActions, isUserLoggedIn } from "../../../store/authenticate";

const InstantAddress = (props) => {
  const authState = props.authState;
  const [addressString, setAddressString] = useState("");
  const [isAddressAvailable, setIsAddressAvailable] = useState(null); // three states - null- initial,0 - address not found from api, 1 - address found
  //order params
  //const [userAddressId, setUserAddressId] = useState(null); //defined in parent component
  const [stateList, setStateList] = useState({});
  const [cityList, setCityList] = useState({});
  const [postalAddress, setPostalAddress] = useState("");
  const [pincode, setPincode] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const [phone, setPhone] = useState();
  const [addressType, setAddressType] = useState(1);
  const [rerenderAdderss, setRerenderAdderss] = useState(0);
  const [isOnlyStateAddressChanged, setIsOnlyStateAddressChanged] =
    useState(false);
  const closeModalRef = useRef(null);
  const dispatch = useDispatch();

  let errorInputStyle = {
    borderBottom: "1px solid #d73131",
    WebkitBoxShadow: "none",
    boxShadow: "none",
  };
  let errorSelectStyle = {
    border: "1px solid #d73131",
  };
  let inputStyle = {
    borderBottom: "1px solid #9e9e9e",
  };

  // func to validate and set mobile
  const validateMobile = (phone) => {
    let mob = /^[6-9]\d{9}$/;
    if (mob.test(phone) === false) {
      setPhone("");
      dispatch(
        authActions.showErrorMsg({ msg: "Please enter valid mobile number!!" })
      );
      return false;
    } else {
      setPhone(phone);
    }
  };

  //func to validate pincode details
  const validatePinCode = (pinCode) => {
    axios
      .post(
        ApiUrls.PinCodeDetails,
        { pincode: pinCode },
        { withCredentials: true }
      )
      .then((res) => {
        if (res.data.data && res.data.data.pincode) {
          setPincode(res.data.data.pincode);
          setSelectedState(res.data.data.StateId);
          setSelectedCity(res.data.data.CityId);
        } else {
          setPincode("");
          setSelectedState("");
          setSelectedCity("");
          throw new Error(
            "Oops! Seems this pincode is not valid as per our system!!"
          );
        }
      })
      .catch((err) => {
        setPincode("");
        setSelectedState("");
        setSelectedCity("");
        console.log(err);
        dispatch(
          authActions.showErrorMsg({
            msg: "Oops! Seems this pincode is not valid as per our system!!",
          })
        );
        return false;
      });
  };

  //function for address validation
  const validateAddress = (validationType = "1",selectedStateVal = "", showErr = true) => {
    if (validationType == "2") {
      // validation when only state is selected
      if (
        (selectedState == "" && selectedStateVal == "")
      ) {
        dispatch(authActions.showErrorMsg({ msg: "Please select state" }));
        return false;
      }

      if (!phone || phone.trim() == "") {
        dispatch(authActions.showErrorMsg({ msg: "Your Phone number is missing. Please login to save address!!" }));
        setSelectedState("")
        return false;
      }

      if (props.productApiData.userId == "") {
        dispatch(authActions.showErrorMsg({ msg: "Details missing in payment link. Please contact support!!" }));
        return false;
      }
      props.setIsCorrectAddress(true)
    } else {
      if (
        selectedCity == "" ||
        phone.trim() == "" ||
        pincode.trim() == "" ||
        postalAddress.trim() == "" ||
        selectedState == "" ||
        props.productApiData.userId == ""
      ) {
        if(showErr)
          dispatch(authActions.showErrorMsg({ msg: "Please fill all fields" }));
        return false;
      }

      let adressReg = /^[a-zA-Z0-9.\s,'-]{4,50}$/;
      if (adressReg.test(postalAddress) === false) {
        dispatch(
          authActions.showErrorMsg({
            msg: "Address can contain only alphanumeric,comma,space and dots within a range of 4 to 50 characters!!",
          })
        );
        return false;
      }
      props.setIsCorrectAddress(true)
    }
    return true;
  };

  // func to validate and save state based address
  const updateStateBasedAddress = (e) => {
    e.preventDefault();
    let selectedStateVal = e.target.value;
    setSelectedState(selectedStateVal);
    if (validateAddress(2,selectedStateVal) === false) {
      return false;
    }

    // if state is changed reset state related fields
    setPostalAddress("");
    setPincode("");
    setSelectedCity("");
    setSelectedCity("");
    //save data in useEffect with updated properties
    setIsOnlyStateAddressChanged(true);
  };

  // func to validate and then save address
  const saveUserAddress = (e) => {
    e.preventDefault();

    if (validateAddress(1) === false) {
      return false;
    }
    //save data
    prepareAndSaveData();
  };

  // function to prepare and save data
  const prepareAndSaveData = () => {
    let bodyFormData = new FormData();
    bodyFormData.append("data[data][postalAddress]", postalAddress);
    bodyFormData.append("data[data][countryId]", 1);
    bodyFormData.append("data[data][stateId]", selectedState);
    bodyFormData.append("data[data][state]", stateList[selectedState] === undefined ? "" : stateList[selectedState]);
    bodyFormData.append("data[data][pincode]", pincode);
    bodyFormData.append("data[data][cityId]", selectedCity);
    let selectedCityName =
      selectedState && selectedCity && selectedState >= "1" && selectedCity >= "1"
        ? cityList[selectedState][selectedCity]
        : "";
    bodyFormData.append(
      "data[data][city]",
      selectedCityName === undefined ? "" : selectedCityName
    );
    bodyFormData.append("data[data][phone]", phone);
    bodyFormData.append("data[data][typeId]", addressType);
    let userAddressId = "";
    if (props.productApiData?.shippingData) {
      //if shipping info available
      userAddressId = props.productApiData?.shippingData?.user_addresses?.id;
    }
    if (!userAddressId) { //in instatnt address user address can be updated
      userAddressId = props.userAddressId;
    }

    bodyFormData.append(
      "data[data][id]",
      (userAddressId === undefined || userAddressId === null) ? "" : userAddressId
    );
    bodyFormData.append("data[data][userShipingAddress]", 1);
    bodyFormData.append("data[data][userId]", props.productApiData.userId);
    bodyFormData.append(
      "data[data][paymentIdVal]",
      props.productApiData.paymentIdVal
    );
    bodyFormData.append(
      "data[data][bookingIdVal]",
      props.productApiData.bookingIdVal
    );

    axios
      .post(ApiUrls.UpdateShippingAddress, bodyFormData, {
        withCredentials: true,
      })
      .then((res) => {
        if (res?.data?.status == "0") {
          throw new Error("Oops! Some error occurred while saving address!!");
        }
        if (res.data.status == "1" && res.data.status_code == 200) {
          setRerenderAdderss(1);
          props.setUserAddressId(res.data.data.id);

          let addressCombined = postalAddress ? postalAddress : "";
          if (selectedState && selectedCity) {
            addressCombined += cityList[selectedState][selectedCity]
              ? (addressCombined != "" ? ", " : "") +
                cityList[selectedState][selectedCity]
              : "";
          }
          if (selectedState) {
            addressCombined += stateList[selectedState]
              ? (addressCombined != "" ? ", " : "") + stateList[selectedState]
              : "";
          }
          addressCombined += phone
            ? (addressCombined != "" ? ", " : "") + "Phone - " + phone
            : "";
          addressCombined += pincode
            ? (addressCombined != "" ? ", " : "") + "Pin - " + pincode
            : "";
          setAddressString(addressCombined);
          if (
            parseFloat(props.finalAmount) >=
            parseFloat(process.env.REACT_APP_FULL_ADDRESS_AMOUNT)
          ) {
            closeModalRef.current.click();
          }
          console.log("Address Updated!!");
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch(
          authActions.showErrorMsg({
            msg: "Oops! Some error occurred while saving address!!",
          })
        );
        return false;
      });
  };

  //get state is async function so it returns a promise - so we need to wait promise to resolve
  const setStateDataList = () => {
    (async () => {
      setStateList(await getStates("1"));
    })();
  };

  //get city is async function so it returns a promise - so we need to wait promise to resolve
  const setCityDataList = () => {
    (async () => {
      setCityList(await getCities(selectedState));
    })();
  };

  useEffect(() => {
    console.log("refreshing login state instant address layout comp");
    dispatch(isUserLoggedIn());

    setStateDataList();
    if (props.productApiData.shippingData && props.productApiData.shippingData?.user_addresses?.id>0) {      
      props.setUserAddressId(
        props.productApiData.shippingData.user_addresses.id
      );
      setPostalAddress(
        props.productApiData.shippingData.user_addresses.postalAddress
      );
      let responsePinCode = props.productApiData.shippingData.user_addresses
        .pincode
        ? props.productApiData.shippingData.user_addresses.pincode
        : "";
      setPincode(responsePinCode);
      setSelectedState(
        props.productApiData.shippingData.user_addresses.stateId
      );
      setSelectedCity(props.productApiData.shippingData.user_addresses.cityId);
      setPhone(props.productApiData.shippingData.user_addresses.phone);
      setIsAddressAvailable(1)
      let addressCombined = props.productApiData.shippingData.user_addresses
        .postalAddress
        ? props.productApiData.shippingData.user_addresses.postalAddress
        : "";
      addressCombined += props.productApiData.shippingData.user_addresses.city
        ? (addressCombined != "" ? ", " : "") +
          props.productApiData.shippingData.user_addresses.city
        : "";
      addressCombined += props.productApiData.shippingData.user_addresses.state
        ? (addressCombined != "" ? ", " : "") +
          props.productApiData.shippingData.user_addresses.state
        : "";
      addressCombined += props.productApiData.shippingData.user_addresses.phone
        ? (addressCombined != "" ? ", " : "") +
          "Phone - " +
          props.productApiData.shippingData.user_addresses.phone
        : "";
      addressCombined += props.productApiData.shippingData.user_addresses
        .pincode
        ? (addressCombined != "" ? ", " : "") +
          "Pin - " +
          props.productApiData.shippingData.user_addresses.pincode
        : "";
      setAddressString(addressCombined);
    }
    else{
      setIsAddressAvailable(0)
    }
  }, []);

  // when address is updated get latest address id from api
  useEffect(() => {
    setStateDataList();
    axios
      .get(ApiUrls.GetUserAddress, {
        params: { filters: "user_id:" + props.productApiData.userId, limit: 1 },
      })
      .then((res) => {
        if (res.data?.data && res.data?.data.length > 0) {
          props.setUserAddressId(res.data.data["0"].id);
          setPostalAddress(res.data.data["0"].postal_address);
          let responsePinCode = res.data.data["0"].pincode ? res.data.data["0"].pincode : "";
          setPincode(responsePinCode);
          setSelectedState(res.data.data["0"].state_id);
          setSelectedCity(res.data.data["0"].city_id);
          setPhone(res.data.data["0"].phone);
          setIsAddressAvailable(1);
          let addressString = res.data.data["0"].postal_address
            ? res.data.data["0"].postal_address
            : "";
          addressString += res.data.data["0"].city
            ? (addressString!="" ? ", " : "") + res.data.data["0"].city
            : "";
          addressString += res.data.data["0"].state
            ? (addressString!="" ? ", " : "") + res.data.data["0"].state
            : "";
          addressString += res.data.data["0"].phone
            ? (addressString!="" ? ", " : "") + "Phone - " + res.data.data["0"].phone
            : "";
          addressString += res.data.data["0"].pincode
            ? (addressString!="" ? ", " : "") + "Pin - " + res.data.data["0"].pincode
            : "";
          setAddressString(addressString);
        }
        else{
          setIsAddressAvailable(0);
        }
      })
      .catch((err) => {   
        setIsAddressAvailable(0);
        console.log(err);
      });
  }, [rerenderAdderss]);

  useEffect(() => {
    if (selectedState && selectedState >= 1) {
      setCityDataList(selectedState);
    }
    if (selectedState>=1 && isOnlyStateAddressChanged === true) {
      //save data
      prepareAndSaveData();
    }
  }, [selectedState, isOnlyStateAddressChanged]);

  useEffect(() => {
    // when no address exist show state address
    if (!phone && authState.mobile10 != "" && phone != authState.mobile10) {
      setPhone(authState.mobile10);
    }
    if (
      !selectedState &&
      authState.stateId >= 1 &&
      selectedState != authState.stateId
    ) {
      setSelectedState(authState.stateId);
    }
  }, [authState.stateId, authState.mobile10]);

  useEffect(()=>{ //if any address not found from api save address
    if(isAddressAvailable === 0 && parseFloat(props.finalAmount) <
    parseFloat(process.env.REACT_APP_FULL_ADDRESS_AMOUNT) && selectedState>=1 && phone>1){ 
      setIsOnlyStateAddressChanged(true) //to save
    }
    if(isAddressAvailable === 1 && parseFloat(props.finalAmount) >= parseFloat(process.env.REACT_APP_FULL_ADDRESS_AMOUNT)){                 
      validateAddress(1, "", false)      
    }
  },[isAddressAvailable,props.finalAmount])

  return (
    <>
      {parseFloat(props.finalAmount) <
        parseFloat(process.env.REACT_APP_FULL_ADDRESS_AMOUNT) && (
        <div className="row">
          <div className="col s12 m12">
            <div className="input-field AddressSelect">
              <p className="SelectStateP">Select Your State</p>
            </div>
          </div>
          <div className="input-field col s12 m12">
            <select
              className="browser-default PaymentSelectBox"
              name="state"
              id="state"
              value={selectedState}
              onChange={(e) => updateStateBasedAddress(e)}
              style={selectedState == "" ? errorSelectStyle : inputStyle}
            >
              <option value="">Select State</option>
              {stateList &&
                Object.keys(stateList).map((index) => {
                  return (
                    <option key={index} value={index}>
                      {stateList[index]}
                    </option>
                  );
                })}
            </select>
          </div>
        </div>
      )}
      {parseFloat(props.finalAmount) >=
        parseFloat(process.env.REACT_APP_FULL_ADDRESS_AMOUNT) && (
        <>
          <form className="paymentstateform">
            <div className="row">
              <div className="col s12 m12">
                <div className="paymentstate">
                  <p>Enter Your Address</p>
                </div>
              </div>
              <div className="col s12 m12">
                <div className="input-field col s12">
                  <a className="modal-trigger" href="#modalAddress" ref={props.openModalRef}>
                    <input
                      id="address"
                      type="text"
                      defaultValue={addressString}
                      style={props.isCorrectAddress === false ? errorInputStyle : inputStyle}
                    />
                  </a>
                </div>
              </div>
            </div>
          </form>

          <Modal modalId={"modalAddress"} closeModalRef={closeModalRef}>
            <form onSubmit={saveUserAddress}>
              <div className="row">
                <div className="col s12 m12">
                  <div className="input-field col s12">
                    <input
                      id="address"
                      type="text"
                      placeholder="Address Line"
                      onChange={(e) => setPostalAddress(e.target.value)}
                      value={postalAddress}
                      style={postalAddress == "" ? errorInputStyle : inputStyle}
                    />
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col s12 m12">
                  <div className="input-field col s12">
                    <input
                      id="pin"
                      type="text"
                      placeholder="Pincode"
                      value={pincode}
                      onBlur={(e) => validatePinCode(e.target.value)}
                      onChange={(e) => setPincode(e.target.value)}
                      style={pincode == "" ? errorInputStyle : inputStyle}
                    />
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col s12 m12">
                  <div className="input-field col s12">
                    <select
                      className="browser-default"
                      name="state"
                      id="state"
                      value={selectedState}
                      onChange={(e) => {
                        setSelectedState(e.target.value);
                        setSelectedCity("");
                      }}
                      style={
                        selectedState == "" ? errorSelectStyle : inputStyle
                      }
                    >
                      <option value="">Select State</option>
                      {stateList &&
                        Object.keys(stateList).map((index) => {
                          return (
                            <option key={index} value={index}>
                              {stateList[index]}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col s12 m12">
                  <div className="input-field col s12">
                    <select
                      className="browser-default"
                      name="city"
                      id="city"
                      value={selectedCity}
                      onChange={(e) => setSelectedCity(e.target.value)}
                      style={selectedCity == "" ? errorSelectStyle : inputStyle}
                    >
                      <option value="">Select City</option>
                      {cityList &&
                        cityList.hasOwnProperty(selectedState) === true &&
                        Object.keys(cityList[selectedState]).map((index) => {
                          return (
                            <option key={index} value={index}>
                              {cityList[selectedState][index]}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col s12 m12">
                  <div className="input-field col s12">
                    <input
                      id="phone"
                      type="text"
                      placeholder="Contact No."
                      value={phone}
                      onBlur={(e) => validateMobile(e.target.value)}
                      onChange={(e) => setPhone(e.target.value)}
                      style={phone == "" ? errorInputStyle : inputStyle}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col s12 m12">
                  <div className="input-field col s12 LoginPageBTNBox">
                    <button type="submit" className="btn btn-small LoginPageBTN">
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </Modal>
        </>
      )}
    </>
  );
};

export default InstantAddress;
