import HomeLayout from "./home/homeLayout"


function Home(){
    return(
        <div className="App">
            <HomeLayout/>
        </div>
    )
}

export default Home