import { useState } from "react";
import {
  FacebookShareButton,
  TwitterShareButton,
  PinterestShareButton,
  WhatsappShareButton,
  FacebookIcon,
  TwitterIcon,
  PinterestIcon,
  WhatsappIcon,
} from "react-share";
import { get_platform, isMobileDevice } from "../../helpers/common";
import { gtm_date_time, gtm_event } from "../../helpers/google_tracking";

const SocialWidget = () => {
  const [showContactUs, setShowContactUs] = useState(false);
  const [
    enableCallUsWidgetHover,
    setEnableCallUsWidgetHover,
  ] = useState(true);
  const [
    enableShareWidgetHover,
    setEnableShareWidgetHover,
  ] = useState(true);
  const [showShareOptions, setShowShareOptions] = useState(false);
  const shareUrl = process.env.REACT_APP_PUBLIC_URL;
  const title = "Aakash NEET-PG Plus - Your Gateway to Medical PG Prep";
  const hashtag = "#byjus";
  const sharedImage =
    process.env.REACT_APP_PUBLIC_URL + "dist/img/banner_3.png";

  const callAction = () => {
    gtm_event({
      event: "tap_callus_business",
      platform: get_platform(),
      ...gtm_date_time(),
    });

    setShowShareOptions(false);
    if (isMobileDevice()) {
      document.location.href = "tel:9289680055";
    } else {
      setShowContactUs(true);
    }
  };

  return (
    <>
      <div className="banneractiondiv">
        {showContactUs && (
          <div className="bannercontactdiv">
            <div
              className="closebtn"
              style={{ cursor: "pointer" }}
              onClick={(e) => {
                setShowContactUs(false);
                setEnableShareWidgetHover(true);
                setEnableCallUsWidgetHover(true);
              }}
            >
              X
            </div>
            <div className="callimagebnr">
              <img
                className="responsive-img"
                src={
                  process.env.REACT_APP_PUBLIC_URL + "dist/img/calliconft.png"
                }
              />
            </div>
            <p>
              {" "}
              9289680055 <br /> <span>(10 AM to 7 PM)</span>
            </p>
            <div className="emailimagebnr">
              <img
                className="responsive-img"
                src={
                  process.env.REACT_APP_PUBLIC_URL + "dist/img/emailiconft.png"
                }
              />
            </div>
            <p>
              <a href="mailto:pgpluscustomercare@aesl.in">
                pgpluscustomercare@aesl.in
              </a>
            </p>
          </div>
        )}
        <div className="HideMobile SocialWidgetDiv">
          <div
            className={`fl-fl float-call ${enableCallUsWidgetHover?'enableSocialWidgetHover':''}`}
            style={{cursor: "pointer"}}
            onClick={() => {
              callAction();
              if (enableCallUsWidgetHover) {
                setEnableCallUsWidgetHover(false);
              }
              setEnableShareWidgetHover(true);
            }}
          >
            <i className="material-icons">
              <img
                className="responsive-img"
                src={
                  process.env.REACT_APP_PUBLIC_URL + "dist/img/call-icon.png"
                }
              />
            </i>
            <a
            >
              {" "}
              Call Us
            </a>
          </div>
          <div
            className={`fl-fl float-share ${enableShareWidgetHover?'enableSocialWidgetHover':''}`}
            onClick={(e) => {
              gtm_event({
                event: "tap_share_business",
                platform: get_platform(),
                ...gtm_date_time(),
              });
              setShowContactUs(false);
              setShowShareOptions(showShareOptions === false ? true : false);

              if (enableShareWidgetHover) {
                setEnableShareWidgetHover(false);
              }
              setEnableCallUsWidgetHover(true);
            }}
          >
            <i className="material-icons">
              <img
                className="responsive-img"
                src={
                  process.env.REACT_APP_PUBLIC_URL + "dist/img/share-icon.png"
                }
              />
            </i>
            <a> Share</a>
          </div>
          {/* <div className="fl-fl float-whatsapp" style={{ cursor: "pointer" }}>
            <i className="material-icons">
              <img
                className="responsive-img"
                src={process.env.REACT_APP_PUBLIC_URL + "dist/img/wa-icon.png"}
              />
            </i>
            <a href="https://api.whatsapp.com/send?phone=8800422601&text=Hi" target="_blank">
              {" "}
              Whatsapp
            </a>
          </div> */}
        </div>
        {showShareOptions && (
          <div className="shareContainer">
            <div
              className="closebtn"
              style={{ cursor: "pointer" }}
              onClick={(e) => {
                setShowShareOptions(false);
                setEnableShareWidgetHover(true);
                setEnableCallUsWidgetHover(true);
              }}
            >
              X
            </div>
            <FacebookShareButton
              url={shareUrl}
              quote={title}
              hashtag={hashtag}
              className="socialShareBtn facebookShareBtn"
            >
              <FacebookIcon size={32} round />
            </FacebookShareButton>
            <TwitterShareButton
              url={shareUrl}
              title={title}
              className="socialShareBtn twitterShareBtn"
            >
              <TwitterIcon size={32} round />
            </TwitterShareButton>
            <PinterestShareButton
              url={String(shareUrl)}
              media={`${String(sharedImage)}`}
              className="socialShareBtn pinterestShareBtn"
            >
              <PinterestIcon size={32} round />
            </PinterestShareButton>
            <WhatsappShareButton
              url={shareUrl}
              title={title}
              separator=":: "
              className="socialShareBtn whatsappShareBtn"
            >
              <WhatsappIcon size={32} round />
            </WhatsappShareButton>
          </div>
        )}

        <div className="ShowMobile">
          <div
            className="fl-fl float-call"
            style={{ cursor: "pointer" }}
            onClick={callAction}
          >
            <a>
              <i className="material-icons">
                <img
                  className="responsive-img"
                  src={
                    process.env.REACT_APP_PUBLIC_URL + "dist/img/call-icon.png"
                  }
                />
              </i>
            </a>
          </div>
          <div
            className="fl-fl float-share"
            onClick={(e) => {
              gtm_event({
                event: "tap_share_business",
                platform: get_platform(),
                ...gtm_date_time(),
              });
              setShowContactUs(false);
              setShowShareOptions(showShareOptions === false ? true : false);
            }}
          >
            <a>
              <i className="material-icons">
                <img
                  className="responsive-img"
                  src={
                    process.env.REACT_APP_PUBLIC_URL + "dist/img/share-icon.png"
                  }
                />
              </i>
            </a>
          </div>
          {/* <div className="fl-fl float-whatsapp" style={{ cursor: "pointer" }}>
            <a href="https://api.whatsapp.com/send?phone=8800422601&text=Hi" target="_blank">
              <i className="material-icons">
                <img
                  className="responsive-img"
                  src={
                    process.env.REACT_APP_PUBLIC_URL + "dist/img/wa-icon.png"
                  }
                />
              </i>
            </a>
          </div> */}
        </div>
      </div>
    </>
  );
};

export default SocialWidget;
