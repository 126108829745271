import { useSelector } from "react-redux";
import { get_platform } from "../../helpers/common";
import { gtm_date_time, gtm_event } from "../../helpers/google_tracking";
import StaticPagesLayout from "./staticPagesLayout";

function ContactUs(props) {
  const authState = useSelector((state) => state.auth);

  const tapContactUs = (e) => {
    gtm_event({
      event: e,
      platform: get_platform(),
      ...gtm_date_time(),
      uid: authState.userId || 0,
      mobile: authState.mobile || "",
    });
  }

  return (
    <StaticPagesLayout>
      <div className="row mbottomnill">
        <div className="col s12 m10 offset-m1 ContactUsHeading">
          <h1>Need Help?</h1>
          <p>
            We Are Here to Help you. You can call our support team or can just
            drop a mail to us
          </p>
          <div className="p10">
            <hr />
          </div>
          <div className="row ContactUsContentBox">
            <div className="col s12 m6 l6 lrnpd">
              <div className="ContactInnerBox">
                <img
                  className="responsive-img"
                  src={
                    process.env.REACT_APP_PUBLIC_URL +
                    "dist/img/contactus-icon1.png"
                  }
                />
                <p onClick={() => { tapContactUs("TAP_CONTACT_US_PHONE") }}>
                  <span>Customer Support :</span>
                  <br />
                  +91 9289680055 <sub>(10am-7pm)</sub>
                </p>
              </div>
            </div>
          </div>
          <hr />
          <div className="row ContactUsContentBox">
            <div className="col s12 m6 l6 offset-s6 offset-m6 offset-l6 lrnpd">
              <div className="ContactInnerBox">
                <img
                  className="responsive-img"
                  src={
                    process.env.REACT_APP_PUBLIC_URL +
                    "dist/img/contactus-icon2.png"
                  }
                />
                <p onClick={() => { tapContactUs("TAP_CONTACT_US_EMAIL") }}>
                  <span>Mail ID :</span>
                  <br />
                  pgpluscustomercare@aesl.in
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </StaticPagesLayout>
  );
}

export default ContactUs;
