export function getCurrentDate(separator = "-") {
  let newDate = new Date();
  let date = newDate.getDate();
  let month = newDate.getMonth() + 1;
  let year = newDate.getFullYear();

  return `${year}${separator}${
    month < 10 ? `0${month}` : `${month}`
  }${separator}${date < 10 ? `0${date}` : `${date}`}`;
}

export function getApiDateFormat(datedata, separator = "-") {
  let newDate = new Date(datedata);
  let date = newDate.getDate();
  let month = newDate.getMonth() + 1;
  let year = newDate.getFullYear();

  return `${year}${separator}${
    month < 10 ? `0${month}` : `${month}`
  }${separator}${date < 10 ? `0${date}` : `${date}`}`;
}

export const monthNames = ["Jan", "Feb", "Mar", "April", "May", "June",
  "July", "August", "Sep", "Oct", "Nov", "Dec"
];

//exm format - 01th May 2020
export function getDatethMonthYearFormat(datedata) {
  let newDate = new Date(datedata);
  let date = newDate.getDate();
  let month = newDate.getMonth();
  let year = newDate.getFullYear();

  const abbr = {1:"st ", 2:"nd ", 3:"rd "}
  return `${date < 10 ? `0${date}${abbr[date] || 'th '}` : `${date+'th '}`}${monthNames[month]}${' '+year}`;
}

export function calculateValidityInMonths(expiryDate,startDate) {
  let d2 = new Date(expiryDate);
  let d1 = new Date(startDate);
  let months;
  months = (d2.getFullYear() - d1.getFullYear()) * 12;
  months -= d1.getMonth();
  months += d2.getMonth();
  return months <= 0 ? 0 : months;
}