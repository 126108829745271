import StaticPagesLayout from "./staticPagesLayout";

function PrivacyPolicy() {
  return (
    <StaticPagesLayout>
      <div className="row">
        <div className="PrivacyPolicyHeading">
          <h1>Privacy Policy</h1>
        </div>
      </div>
      <div className="row">
        <div className="PrivacyPolicyMainDiv">
          <p>
            <strong>You certainly understand and concur that:</strong>
          </p>
          <p>
            <b className="policyBoldFont">Aakash Edutech Private Limited (“AEPL”)</b> has created
            this privacy policy in order to demonstrate own organization
            commitment to privacy. Throughout the cyberspace we want to
            contribute towards providing a safe and secure environment, safe
            information gathering and dissemination practices for all our sites.
            This policy applies to{" "}
            <a href={process.env.REACT_APP_PUBLIC_URL}>
                  {process.env.REACT_APP_PUBLIC_URL}
                </a>
            and mobile application user app related to <b className="policyBoldFont">
              AEPL
            </b>{" "}
            brand but not to any other companies or organizations' websites or
            mobile applications to which we link.
            <br />
          </p>
        </div>
      </div>

      <div className="row">
        <div className="PrivacyPolicyMainDiv">
          <p>
            <strong>Information Gathering And Usage</strong>
          </p>
          <ul>
            <li>
              <p>
                <strong>Registration / Information</strong>
              </p>
              <p>
                When you sign up with Website or App we ask you for personal
                information. We may combine the information you submit under
                your account with information from other services / third
                parties in order to provide you with a better experience and to
                improve the quality of our services. For certain services, we
                may give you the opportunity to opt out of combining such
                information.
              </p>
              <p>
                You may provide us with certain information such as your Name,
                E-mail address, Correspondence address when registering for
                certain services such as Online Registration / Submit Resume,
                Contests. This information will primarily be used for the
                purpose of providing personalisation and verification.
              </p>
            </li>
            <li>
              <p>
                <strong>Cookies</strong>
              </p>
              <p>
                A cookie is a small data file that Websites or App write to your
                hard drive when you visit them. A cookie file can contain
                information such as a user ID that the site uses to track the
                pages you have visited. A cookie can contain information you
                supply yourself. A cookie can't read data of your hard disk or
                read cookie files created by other sites. Our Website/App uses
                cookies to track user traffic patterns and for the
                personalisation feature.
              </p>
            </li>
            <li>
              <p>
                <strong>User communications</strong>
              </p>
              <p>
                When you send email or other communications to our Website or
                App , we may retain those communications in order to process
                your inquiries, respond to your requests and improve our
                services. When you send and receive SMS messages to or from one
                of our services that provides SMS functionality, we may collect
                and maintain information associated with those messages, such as
                the phone number, the content of the message, and the date and
                time of the transaction. We may use your email address to
                communicate with you about our services.
              </p>
            </li>
            <li>
              <p>
                <strong>Log information</strong>
              </p>
              <p>
                When you access our Website or App services via a browser or,
                mobile application or other client, our servers automatically
                record certain information. These server logs may include
                information such as your web request, your interaction with a
                service, Internet Protocol address, browser type, browser
                language, the date and time of your request and one or more
                cookies that may uniquely identify your browser or your account.
              </p>
            </li>
            <li>
              <p>
                <strong>Electronic Newsletter/E-mail</strong>
              </p>
              <p>
                <b className="policyBoldFont">AEPL</b> circulates free electronic newsletter to
                its users. who voluntarily subscribe to opt the same. Users may
                unsubscribe themselves from availing this facility by AEPL by
                using the link provided in every newsletter.
              </p>
            </li>
            <li>
              <p>
                <strong>Confidential</strong>
              </p>
              <p>
                <b className="policyBoldFont">AEPL</b> Privacy Policy applies to its Website and
                App. AEPL do not exercise control over the sites displayed as
                search results, sites that include other applications, products
                or services, or links from within our various services. Personal
                information that you provide to other sites may be sent to our
                Website and/or App in order to deliver the service. We process
                such information under this Privacy Policy.
              </p>
            </li>
            <li>
              <p>
                <strong>Feedback</strong>
              </p>
              <p>
                <b className="policyBoldFont">AEPL's</b> Feedback Form requires personal
                information of users like contact number, name and e-mail
                address and demographic information like their zip code, age
                etc. for any feedback by the users pertaining to the services
                rendered by AEPL.
              </p>
            </li>
            <li>
              <p>
                <strong>Further Improvement</strong>
              </p>
              <p>
                Apart from the above, we may use user information to provide,
                maintain, protect, improve and develop our services.
              </p>
            </li>
            <li>
              <p>
                <strong>Queries regarding the Website or APP</strong>
                <p>
                  If you have any query regarding this Privacy Policy, you may
                  contact at-{" "}
                  <a href="mailto:pgpluscustomercare@aesl.in">pgpluscustomercare@aesl.in </a>
                </p>
              </p>
            </li>
          </ul>
        </div>
      </div>
    </StaticPagesLayout>
  );
}

export default PrivacyPolicy;
