import axios from "axios";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ApiUrls } from "../../constants/constants";
import { get_platform, setLocalItem } from "../../helpers/common";
import { gtm_date_time, gtm_event } from "../../helpers/google_tracking";
import Carousel from "../home/carausel";
import parse from "html-react-parser";

const PremiumOffersSection = () => {
  const [currentOfferProducts, setCurrentOfferProducts] = useState([]);
  const authState = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const carauselOptions = {
    items: 4,
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 3,
      },
      1000: {
        items: 4,
      },
    },
    nav: true,
    dots: true,
    autoplay: true,
    navText: [
      `<div class='nav-btn prev-slide'>
      <a class="prev btn-floating btn sliderarrowcust">
        <i class="material-icons">chevron_right</i>
      </a>
    </div> `,
      `<div class='nav-btn next-slide'>
      <a class="next btn-floating btn sliderarrowcust">
        <i class="material-icons">chevron_left</i>
      </a>
    </div>`],
  };

  const getCardClass = (i) => {
    const cardClasses = ['red-10', 'blue-10', 'yellow-10', 'brown-20', 'purple-10']
    return i > 4 ? cardClasses[(i % 4) - 1] : cardClasses[i]
  }

  useEffect(() => {
    axios
      .get(ApiUrls.CurrentOfferProducts)
      .then((res) => {
        setCurrentOfferProducts(res.data.data.products);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const buyProduct = (productId, name) => {
    gtm_event({
      event: "tap_BuyNow",
      platform: get_platform(),
      ...gtm_date_time(),
      uid: authState.userId || 0,
      name: authState.name || "",
      mobile: authState.mobile || "",
      product_name: name,
      product_id: productId,
      is_study_material: false
    });
    setLocalItem("productPgPlus", productId);
    navigate("/purchase", { state: { step: 2 } });
  };

  return (
    <>
      {currentOfferProducts.length > "0" && (
        <div className="container CurrentOfferSection">
          <div className="heading">
            <p>Get closer to your goal!</p>
            <p className="view-all" onClick={()=>{navigate("/purchase")}}>View all</p>
          </div>
          <Carousel
            carouselClass="currentOffersCarausal"
            showNavigationBtn={true}
            carauselOptions={carauselOptions}
          >
            {currentOfferProducts.map((product, index) => (
              <div key={index} className={"CurrentOfferSliderContent " + getCardClass(index)}>
                <div className="CurrentOffersBOX">
                  <div className="currentoffercontentbox">
                    <h4 className="duration">{product.product_name}</h4>
                    <p className="plan-type">{product.course_category} plan</p>
                    <ul className="highlights">
                      {parse(product.feature_summary)}
                    </ul>
                  </div>
                  <div className="CurrntOfferBTNBox">
                    <div className="price-section">
                      <p><strike>₹ {parseFloat(product.origFee)}</strike></p>
                      <p><span>₹ {parseFloat(product.price_with_tax)}</span></p>
                    </div>
                    <a
                      className="btn CurrntOfferBTN"
                      onClick={() =>
                        buyProduct(product.id, product.product_name)
                      }
                    >
                      Buy Now
                    </a>
                  </div>
                </div>
              </div>
            ))}
          </Carousel>
        </div>
      )}
    </>
  );
};

export default PremiumOffersSection;
