import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { authActions } from "../../store/authenticate";
import { AlertMsg } from "../common/alert";
import { ApiUrls } from "../../constants/constants";
import { get_platform, scrollToSection } from "../../helpers/common";
import { gtm_date_time, gtm_event } from "../../helpers/google_tracking";
import { Link, useNavigate } from "react-router-dom";

const Footer = () => {
  const [leadDetails, setLeadDetails] = useState({
    name: "",
    mobile: "",
    email: "",
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const submitLeadDetails = () => {
    if(leadDetails.name.trim()=="" || leadDetails.mobile.trim()=="" || leadDetails.email.trim()==""){
      dispatch(
        authActions.showErrorMsg({
          msg: "Please fill all fields.",
        })
      );
      return false;
    }
    if (!isValidName()) {
      dispatch(
        authActions.showErrorMsg({
          msg: "Please enter valid name (Only A-Z,a-z and space allowed).",
        })
      );
      return false;
    }

    if (!isValidMobile()) {
      dispatch(
        authActions.showErrorMsg({ msg: "Please enter valid mobile number." })
      );
      return false;
    }

    if (!isValidEmail()) {
      dispatch(authActions.showErrorMsg({ msg: "Please enter valid email." }));
      return false;
    }

    gtm_event({
      event: "tap_callback_business",
      platform: get_platform(),
      ...gtm_date_time(),
      ...leadDetails,
    });

    axios
      .post(ApiUrls.TalkToExpert, { ...leadDetails, source: 4 }, { withCredentials: true })
      .then((res) => {
        setLeadDetails({ name: "", mobile: "", email: "" });
        dispatch(
          authActions.showSuccessMsg({
            msg: "We've received your request and will call you during our business hours shortly.",
          })
        );
      })
      .catch((err) => {
        dispatch(
          authActions.showErrorMsg({
            msg: "OOPS!! Something went wrong. Please connect with us directly at pgpluscustomercare@aesl.in or 9289680055",
          })
        );
      });
  };

  const isValidEmail = () => {
    return String(leadDetails.email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const isValidMobile = () => {
    return /^[6-9]\d{9}$/.test(leadDetails.mobile);
  };

  const isValidName = () => {
    return /^[A-Za-z ]+$/.test(leadDetails.name);
  };

  useEffect(() => { }, []);

  return (
    <div className="container-fluid footerbg">
      <div className="container FooterSection">
        <div className="row mbottomnill">
          <div className="col s12 m12 l12">
            <div className="footercontentbox">
              <p>
                <strong>Talk to our expert:</strong>
              </p>
            </div>
          </div>

          <div className="col s12 m12 l12">
            <div className="row">
                  <div className="input-field col s12 m6 l3 footerinputlbl">
                    <input
                      value={leadDetails.name}
                      id="name"
                      type="text"
                      className="validate "
                      onChange={(e) => {
                        setLeadDetails((v) => {
                          return { ...v, name: e.target.value };
                        });
                      }}
                    />
                    <label htmlFor="name">Your Name</label>
                  </div>
                  <div className="input-field col s12 m6 l3 footerinputlbl">
                    <input
                      id="mobile"
                      value={leadDetails.mobile}
                      type="text"
                      className="validate "
                      onChange={(e) => {
                        setLeadDetails((v) => {
                          return { ...v, mobile: e.target.value };
                        });
                      }}
                    />
                    <label htmlFor="mobile">Your Phone No</label>
                  </div>
                  <div className="input-field col s12 m6 l3 footerinputlbl">
                    <input
                      id="email"
                      value={leadDetails.email}
                      type="email"
                      className="validate "
                      onChange={(e) => {
                        setLeadDetails((v) => {
                          return { ...v, email: e.target.value };
                        });
                      }}
                    />
                    <label htmlFor="email">Your Email Id</label>
                  </div>
                  <div className="input-field col s12 m6 l3 footerbtn">
                    <div className="footerbtnbg">
                      <a
                        className="btn footerbtnbgBTN"
                        onClick={submitLeadDetails}
                      >
                        Request A call Back
                      </a>
                    </div>
                  </div>
                </div>
                <AlertMsg />
          </div>
        </div>
          <div className="breakline"></div>

        <div className="footermenusection" id="footermenusection">
          <div className="row">
            <div className="col s12 m8">
              <div className="row HideMobile">
                <div className="col s12 m4 l4">
                  <div>
                    <div className="footermenuhd">
                      <p>
                        <strong>ABOUT US</strong>
                      </p>
                    </div>
                  </div>
                  <div className="footermenua">
                    <Link
                      to="/about-us"
                      onClick={() => {
                        scrollToSection();
                      }}
                    >
                      About Aakash
                    </Link>
                  </div>
                </div>

                <div className="col s12 m4 l4">
                  <div>
                    <div className="footermenuhd">
                      <p>
                        <strong>Packages</strong>
                      </p>
                    </div>
                  </div>
                  <div className="footermenua">
                    <a onClick={() => { navigate("/purchase?category=1"); }}>Premium</a>
                    <br />
                    <a onClick={() => { navigate("/purchase?category=2"); }}>Practice</a>
                    <br />
                    <a onClick={() => { navigate("/purchase?category=3"); }}>Test Series</a>
                    <br />
                    <a onClick={() => { navigate("/purchase?category=4"); }}>Subject Wise</a>
                    <br />
                    <a onClick={() => { navigate("/purchase?category=5"); }}>Prof-Wise</a>
                  </div>
                </div>

                <div className="col s12 m4 l4">
                  <div>
                    <div className="footermenuhd">
                      <p>
                        <strong>Others</strong>
                      </p>
                    </div>
                  </div>
                  <div className="footermenua">
                    <a href="https://www.aakash.ac.in/blog/category/neet-pg" target="_blank">
                      Blog
                    </a>
                    <br />
                    <Link
                      to="/terms"
                      onClick={() => {
                        scrollToSection();
                      }}
                    >
                      T&C
                    </Link>
                    <br />
                    <Link
                      to="/faq"
                      onClick={() => {
                        scrollToSection();
                      }}
                    >
                      FAQ
                    </Link>
                    <br />
                    <Link
                      to="/privacy-policy"
                      onClick={() => {
                        scrollToSection();
                      }}
                    >
                      Privacy Policy
                    </Link>
                  </div>
                </div>
              </div>
              <div className="row ShowMobile">
                <div className="footermenumanage">
                  <div>
                    <div className="footermenuhd">
                      <Link
                        to="/about-us"
                        onClick={() => {
                          scrollToSection();
                        }}
                      >
                        <p>
                          <strong>
                            About Us <span>|</span>{" "}
                          </strong>
                        </p>
                      </Link>
                      <a href="https://www.aakash.ac.in/blog/category/neet-pg" target="_blank">
                        <p>
                          <strong>
                            Blog <span>|</span>{" "}
                          </strong>
                        </p>
                      </a>
                      <Link
                        to="/terms"
                        onClick={() => {
                          scrollToSection();
                        }}
                      >
                        <p>
                          <strong>
                            T&C <span>|</span>{" "}
                          </strong>
                        </p>
                      </Link>
                      <Link
                        to="/faq"
                        onClick={() => {
                          scrollToSection();
                        }}
                      >
                        <p>
                          <strong>
                            FAQ <span>|</span>{" "}
                          </strong>
                        </p>
                      </Link>
                      <Link
                        to="/privacy-policy"
                        onClick={() => {
                          scrollToSection();
                        }}
                      >
                        <p>
                          <strong>
                            Privacy Policy
                          </strong>
                        </p>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col s12 m4">
              <div>
                <div className="footermenuhd">
                  <p>
                    <strong>Contact Us</strong>
                  </p>
                </div>
                <div className="footermenua">
                  <div className="footercontactcontent">
                    <img
                      className="responsive-img"
                      src={
                        process.env.REACT_APP_PUBLIC_URL +
                        "dist/img/calliconft.png"
                      }
                    />{" "}
                    <p>
                      Call: 9289680055
                      <br />
                      <span>(10 AM to 7 PM)</span>
                    </p>
                  </div>
                  <br />

                  <div className="footercontactcontent">
                    <img
                      className="responsive-img"
                      src={
                        process.env.REACT_APP_PUBLIC_URL +
                        "dist/img/emailiconft.png"
                      }
                    />{" "}
                    <p>
                      <a href="mailto:pgpluscustomercare@aesl.in">
                        Email: pgpluscustomercare@aesl.in
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="scrolbtnmain"
              onClick={() => {
                scrollToSection();
              }}
            >
              <div className="scrolbtn">
                <img
                  className="responsive-img"
                  src={
                    process.env.REACT_APP_PUBLIC_URL +
                    "dist/img/footerarrow.png"
                  }
                />
                <br />
                <a>Scroll Up</a>
              </div>
            </div>
          </div>
          <div className="footerbreakline ShowMobile"></div>
          <div className="row">
            <div className="col s4 m4">
              <div className="footerlogoimg">
                <Link to="/">
                  <img
                    className="responsive-img"
                    src={
                      process.env.REACT_APP_PUBLIC_URL +
                      "dist/img/aakash-logo-footer.png"
                    }
                  />
                </Link>
              </div>
            </div>
            <div className="col s8 m8">
              <div className="row SocialICONBox">
                <div className="col s12 m12">
                  {/* <div className="col s4 m4">
                    
                  </div> */}
                  <div className="SocialIconInner">
                  <div className="SocialHeading"><p className="footersocialtext">Follow us:</p></div>
                    <div className="footersocialicons">
                      <a
                        href="https://www.facebook.com/AakashPGPlus"
                        target="_blank"
                      >
                        <img
                          className="responsive-img"
                          src={
                            process.env.REACT_APP_PUBLIC_URL +
                            "dist/img/Facebook.png"
                          }
                          alt="facebook"
                        />
                      </a>
                      <a href="https://t.me/aakashpgplus" target="_blank">
                        <img
                          className="responsive-img"
                          src={
                            process.env.REACT_APP_PUBLIC_URL +
                            "dist/img/telegram.png"
                          }
                        />
                      </a>
                      <a
                        href="https://www.youtube.com/c/AakashPGPlus"
                        target="_blank"
                      >
                        <img
                          className="responsive-img"
                          src={
                            process.env.REACT_APP_PUBLIC_URL +
                            "dist/img/youtube.png"
                          }
                          alt="Youtube"
                        />
                      </a>
                      <a
                        href="https://www.instagram.com/neetpg_aakashofficial"
                        target="_blank"
                      >
                        <img
                          className="responsive-img"
                          src={
                            process.env.REACT_APP_PUBLIC_URL +
                            "dist/img/Instagram.png"
                          }
                          alt="instagram"
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          </div>
      </div>
      <div className="footerbreakline HideMobile"></div>
      <div className="container">
        <div className="footercopyright">
          <p>
            © Aakash Educational Services Limited {new Date().getFullYear()}
          </p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
