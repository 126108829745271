import {React} from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter as Router,Route,Routes} from 'react-router-dom';
import {Provider} from 'react-redux';
import Login from './components/login/login';
import SupportLogin from './components/login/supportLogin';
import PurchaseReceipt from './components/purchase/receipt';
import PurchaseInvoice from './components/purchase/invoice';
import Store from './store/store';
import Dashboard from './components/dashboard';
import NotFound from './components/common/notFound';
import ProtectedRoute from './components/common/protectedRoutes';
import ProfileMandatoryFields from './components/login/profileMandatoryFields';
import Home from './components/home'; 
import Purchase from './components/purchase';
import PaymentStatus from './components/purchase/paymentStatus';
import InstantPayment from './components/purchase/instantPayment/instantPayment';
import { init_gtm, int_ga } from './helpers/google_tracking';
import Logout from './components/login/logout';
import Faq from './components/static/faq';
import PrivacyPolicy from './components/static/privacyPolicy';
import AboutAakash from './components/static/aboutAakash';
import Terms from './components/static/terms';
import Ourfaculty from './components/static/ourFaculty';
import { getLocalItem, setLocalItem } from './helpers/common';
import ContactUs from './components/static/contactUs';
import GetApp from './components/static/getApp';

int_ga();
init_gtm()

/* Parameter to check if app is opened in app
  we can use this param throught app using window.IS_MOBILE_APP
*/
const urlParams = new URLSearchParams(window.location.search);
window.IS_MOBILE_APP = urlParams.get('app') || getLocalItem('is_mobile_app');
if (window.IS_MOBILE_APP) {
  setLocalItem('is_mobile_app', window.IS_MOBILE_APP)

  window.APP_LOGIN_SUCCESS_URL = urlParams.get('loginsuccesspath') || getLocalItem('loginsuccesspath');
  if (window.APP_LOGIN_SUCCESS_URL) setLocalItem('loginsuccesspath', window.APP_LOGIN_SUCCESS_URL)

  window.BUY_NOW_SUCCESS_URL = urlParams.get('buynowsuccesspath') || getLocalItem('buynowsuccesspath');
  if (window.BUY_NOW_SUCCESS_URL) setLocalItem('buynowsuccesspath', window.BUY_NOW_SUCCESS_URL)

  window.APP_LOGOUT_URL = urlParams.get('logoutpath') || getLocalItem('logoutpath');
  if (window.APP_LOGOUT_URL) setLocalItem('logoutpath', window.APP_LOGOUT_URL)

  window.APP_ORIGIN = urlParams.get('origin') || getLocalItem('origin');
  if (window.APP_ORIGIN) setLocalItem('origin', window.APP_ORIGIN)

  window.APP_HIDE_HEADER = urlParams.get('hideheader') || getLocalItem('hideheader');
  if (window.APP_HIDE_HEADER) setLocalItem('hideheader', window.APP_HIDE_HEADER)

  /* const app_params = {
    loginsuccesspath: "APP_LOGIN_SUCCESS_URL",
    buynowsuccesspath: "BUY_NOW_SUCCESS_URL",
    logoutpath: "APP_LOGOUT_URL",
    origin: "APP_ORIGIN",
    hideheader: "APP_HIDE_HEADER"
  }

  const keys = Object.keys(app_params)
  for (let i = 0; i < keys.length; i++) {
    window[app_params[keys[i]]] = urlParams.get(keys[i]) || getLocalItem(keys[i]);
    if (window[app_params[keys[i]]])
      setLocalItem(keys[i], window[app_params[keys[i]]])
  } */
}

ReactDOM.render(
  <Provider store={Store}> 
  <Router basename={'/pgplus'}>
    <Routes> 
        <Route path='*' element={<NotFound />} />
        <Route path='/products/receipt/:receiptId' element={<PurchaseReceipt />} />
        <Route path='/products/invoice/:invoiceId' element={<PurchaseInvoice />} />
        <Route path="/" element={<Home />} />
        <Route path="/pgplus" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/logout" element={<Logout />} />
        <Route path="/contact-us" element={<ContactUs />} />        
        <Route path="/faq" element={<Faq />} />        
        <Route path="/terms" element={<Terms />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/about-us" element={<AboutAakash />} />
        <Route path="/our-faculty" element={<Ourfaculty />} />
        <Route path="/purchase" element={<Purchase />} />
        <Route path="/instant-payment/:paymentKey" element={<InstantPayment />} />
        <Route path="/purchase/paymentstatus/:paymentResp" element={<PaymentStatus />} />
        {/* <Route path="/purchase/paymentstatus/:paymentResp/:auto_login_url" element={<PaymentStatus />} /> */}
        <Route path="/support-login" element={<SupportLogin />} />
        <Route path="/getapp" element={<GetApp />} />
        <Route path="/" element={<ProtectedRoute/>}>
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/profile" element={<ProfileMandatoryFields />} />
        </Route> 
    </Routes>
  </Router>
  </Provider>,
  document.getElementById('root')
);


