import { useEffect, useRef, useState } from "react";
import { Modal } from "../common/common";
import { gtm_event, gtm_date_time } from "../../helpers/google_tracking";
import { getMobileOperatingSystem, get_platform } from "../../helpers/common";
import axios from "axios";
import M from 'materialize-css';

const DownloadAppDashboard = (props) => {
  const [mobile, setMobileNum] = useState("")
  const [ua, setUa] = useState(getMobileOperatingSystem());
  const [loading, setLoading] = useState(false);
  const modalOpenRef = useRef(null);

  const getAppLink = () => {
    M.Toast.dismissAll();
    if (!mobile || !isValidMobile()) {
      M.toast({ html: '<div><span>Please enter valid mobile number.</span>' });
      return false;
    }

    // Call sms API
    setLoading(true)
    axios.post(`${process.env.REACT_APP_SERVER_URL}verifyapi/v1/verify/_send_app_link`,
      { mobile: mobile }, { withCredentials: true }).then((res) => {
        setLoading(false)
        setMobileNum("")
        if (!res.data.status) {
          M.toast({ html: `<div><span>${res.data.message}</span>` });
          return false;
        }

        modalOpenRef.current.click()
      }).catch((err) => {
        M.toast({ html: '<div><span>Some error occured in sending app link to your mobile.' })
        setLoading(false)
        console.log(err);
      })
  }

  const isValidMobile = () => {
    return /^[6-9]\d{9}$/.test(mobile);
  };

  return (
    <>
      <div className="DownloadAakashSection">
        <div className="container DownloadPageContainer ">
          <div className="">
            <div className="col s12 m6 l6 offset-m6 DownloadContainerDiv">
              <div className="downloadbox">
                <div className="left-section">
                  <h4>Download the App Now</h4>
                  <p className="free-trial">
                    {
                      props.userType == 'freeTrial' ? "Enjoy your 10 day free trial" :
                        (props.userType == 'paid' ? "To access your premium content." :
                          (props.userType == "paidAboutToExpire" ? "Renew your premium plan today!"
                            : "Get a premium plan today!"))
                    }
                  </p>
                  <p className="get-access">Get access to 1000+ hrs of Concept Video, Q Banks, Test Series & Smart Notes only on <span> Aakash NEET PG Plus app.</span></p>
                  <div className="mobile-app-link">
                    <a
                      onClick={() => {
                        gtm_event({
                          event: "tap_downloadapp_business",
                          platform: get_platform(),
                          ...gtm_date_time(),
                        });
                      }}
                      href={
                        ua == "iOS" ? "https://itunes.apple.com/in/app/aakash-pg-plus/id1660895666"
                          : "https://play.google.com/store/apps/details?id=com.tribyte.aakashpgplus"
                      }
                      target="_blank"
                    >
                      <img src={
                        ua == "iOS" ? "/pgplus/dist/img/dashboard/app_store.png" : "/pgplus/dist/img/dashboard/G-play.png"
                      } />
                    </a>
                  </div>
                </div>
                <div className="right-section">
                  <img
                    className=""
                    src={"/pgplus/dist/img/dashboard/app-qr-code.jpg?v=1.0"}
                  />
                </div>

              </div>
              <div className="get-app-link">
                <h4>Get App link on your mobile</h4>
                <p className="label">Mobile number*</p>
                <div className="input-section">
                  <p className="country">+91</p>
                  <input className="input" type="text" value={mobile} maxLength={10} onChange={(e) => { setMobileNum(e.target.value) }} placeholder="Enter number here" />
                  <div className={"btn " + (loading ? "disabled" : "")} onClick={getAppLink}>{!loading ? "Receive Link" : "Please wait..."}</div>
                  <span className="modal-trigger" href="#getmobileAppLink" ref={modalOpenRef}></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Mobile app download for mobile */}
      {props.userType == 'paid' &&
        <div className="header-note paid-user-mobile">
          Download the App now, to access your premium content.
          <span className="icon">
            <a
              onClick={() => {
                gtm_event({
                  event: "tap_downloadapp_business",
                  platform: get_platform(),
                  ...gtm_date_time(),
                });
              }}
              href={
                ua == "iOS" ? "https://itunes.apple.com/in/app/aakash-pg-plus/id1660895666"
                  : "https://play.google.com/store/apps/details?id=com.tribyte.aakashpgplus"
              }
              target="_blank">
              <img src={
                ua == "iOS" ? "/pgplus/dist/img/dashboard/app_store.png" : "/pgplus/dist/img/dashboard/G-play.png"
              } />
            </a>
          </span>
        </div>
      }

      <Modal modalId={"getmobileAppLink"}>
        <div className="mobile-link-popup">
          <img src={"/pgplus/dist/img/dashboard/greenTick.png"} />
          <p>Link sent successfully</p>
        </div>
      </Modal>
    </>
  );
};

export default DownloadAppDashboard;
