import axios from "axios";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { ApiUrls } from "../../constants/constants";
import { get_platform, make_tribyte_user_login } from "../../helpers/common";
import { gtm_date_time, gtm_event } from "../../helpers/google_tracking";
import { authActions, isUserLoggedIn, login } from "../../store/authenticate";
import { Spinner } from "../common/common";
import HomeHeader from "../layout/homeHeader";

const PaymentStatus = () => {
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useDispatch();
  const paymentResp = params.paymentResp ? params.paymentResp : "";
  // let auto_login_url = params.auto_login_url ? params.auto_login_url : "";
  const [paymentStatus, setPaymentStatus] = useState(paymentResp == 9 ? 0 : null);
  const [paymentStatusResp, setPaymentStatusResp] = useState({});
  const authState = useSelector((state) => state.auth);

  const checkPaymentStatus = () => {
    axios
      .post(
        ApiUrls.PaymentStatus,
        { encodedOrderStatus: paymentResp },
        { withCredentials: true }
      )
      .then((res) => {
        if (res?.data?.status == "0") {
          throw new Error(
            "Oops! Some error occurred in while checking payment status!!"
          );
        }

        if (res?.data?.data?.result == "Success") {
          setPaymentStatus(1);
          setPaymentStatusResp(res.data.data);
        } else {
          throw new Error(
            "Oops! Some error occurred in while checking payment status!!"
          );
        }
      })
      .catch((err) => {
        setPaymentStatus(0);
        console.log(err);
        return false;
      });
  };

  const redirectToPage = (url = "profile", orderStatus) => {
    gtm_event({
      event: "tap_dashbaord_startlearning_payment",
      platform: get_platform(),
      uid: authState.userId || paymentStatusResp?.userData?.uid || 0,
      name: authState.name || paymentStatusResp?.userData?.name || "",
      mobile: authState.mobile || paymentStatusResp?.userData?.mobile || "",
      product_name: paymentStatusResp?.purchaseInfo?.products?.[0]?.name,
      product_id: paymentStatusResp?.purchaseInfo?.products?.[0]?.productId
    })

    // if (auto_login_url) {
    //   //bas364_encode added / in 5.4.38 so we replaced / with _ in backend
    //   auto_login_url = auto_login_url.replace(new RegExp("_", 'g'), "/");
    //   window.location.href = atob(auto_login_url) + url;
    // }
    // else navigate("/" + url);
    // make_tribyte_user_login(authState.userId, orderStatus)
    navigate("/dashboard");
  };

  useEffect(() => {
    dispatch(isUserLoggedIn());
    checkPaymentStatus();
  }, []);

  return (
    <>
      <HomeHeader />
      <div className="container-fluid">
        <div className="row PaymentSuccPageHeight">
          <div className="col s12 m12">
            {paymentStatus == 1 && (
              <div className="PaymentSuccess">
                <div className="paymentform">
                  <div className="Paymentheading">
                    <img
                      src={
                        process.env.REACT_APP_PUBLIC_URL +
                        "dist/img/payment_suc.png"
                      }
                    />
                    <h1>Payment Succesfull!</h1>
                    <h1>
                      <span>Order Id : {paymentStatusResp["orderId"]}</span>
                    </h1>
                    <div className="row">
                      <div className="col s12">
                        <div className="Paymentsubheading">
                          <p>
                            Payment Id : {paymentStatusResp["orderPaymentId"]}
                          </p>
                          <p>Amount : {paymentStatusResp["amount"]}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col s12">
                      <div className="PaymentLink">
                        <p>
                          <a
                            onClick={() => {
                              redirectToPage("profile", 1);
                            }}
                          >
                            Go to Dashboard
                          </a>
                          &nbsp;or&nbsp;
                          <a
                            onClick={() => {
                              redirectToPage("profile", 1);
                            }}
                          >
                            Start Learning
                          </a>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {paymentStatus == 0 && (
              <div className="PaymentFailed">
                <div className="paymentform">
                  <div className="PaymentFailedheading">
                    <img
                      src={
                        process.env.REACT_APP_PUBLIC_URL +
                        "dist/img/payment_failed.png"
                      }
                    />
                    <h1>Oops!</h1>
                    <h1>
                      <span>Your payment could not be processed.</span>
                    </h1>
                  </div>

                  <div className="row">
                    <form className="col s12">
                      <div className="row">
                        <div className="col s12">
                          <div className="PaymentFailedsubheading">
                            <p>
                              You may try purchasing the product again and use a
                              <br /> different payment method.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col s12">
                          <div className="PaymentFailedLink">
                            <p>
                              Need Support? : <a>pgpluscustomercare@aesl.in</a>
                            </p>
                            <p>
                              <a
                                onClick={() => {
                                  redirectToPage("profile", 0);
                                }}
                              >
                                Go to Dashboard
                              </a>
                            </p>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            )}
            {paymentStatus == null && (
              <Spinner />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default PaymentStatus;
