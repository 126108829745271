import { useEffect } from "react";

import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

const Carousel = (props) => {
  const carouselClass = props.carouselClass ? props.carouselClass : false;
  const showNavigationBtn = props.showNavigationBtn ? props.showNavigationBtn : false;
  const navPosition = showNavigationBtn ? props.navPosition : 'top';
  const carauselOptions = props.carauselOptions ? props.carauselOptions : {};

  const opt = {
    nav: showNavigationBtn,
    navText: [
      `<div class='nav-btn prev-slide'>
        <a class="prev btn-floating btn sliderarrowcust">
          <i class="material-icons">arrow_forward</i>
        </a>
      </div> `,
      `<div class='nav-btn next-slide'>
        <a class="next btn-floating btn sliderarrowcust">
          <i class="material-icons">arrow_back</i>
        </a>
      </div>`],
    navContainer: carauselOptions.navContainer || `.${carouselClass}-slider_nav`,
    dots: false,
    dotsEach:false,
    loop: true,
    mouseDrag: false,
    autoplay:true,
    autoplayTimeout:3000,
    autoplayHoverPause:true,
    ...carauselOptions
  }
  useEffect(() => { }, []);

  return (
    <>
      {opt.nav && opt.navContainer && navPosition != 'bottom' && <div className={carouselClass + "-slider_nav carousel_nav"}></div>}

      <OwlCarousel className={carouselClass + " owl-theme"} {...opt} >
        {props.children}
      </OwlCarousel >

      {opt.nav && opt.navContainer && navPosition == 'bottom' && <div className={carouselClass + "-slider_nav carousel_nav"}></div>}
    </>
  );
};

export default Carousel;
