import axios from "axios";
import { useEffect, useState } from "react";
import { ApiUrls, FacultyDetails } from "../../constants/constants";
import Carousel from "./carausel";

const FacultySection = () => {
  const carauselOptions = {
    items: 4,
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 3,
      },
      1000: {
        items: 4,
      },
    },
  };

  const [faculties, setFaculties] = useState([]);
  useEffect(() => {
    axios.get(ApiUrls.bannerImagesApi+"?component=faculty&flow=1")
    .then((res) => {
      if(res.data?.data?.faculty)
      setFaculties(res.data.data.faculty);
    })
    .catch((err) => {
      console.log(err);
    });
  }, [])

  return (
    (faculties && faculties.length && <div className="container ExperienceFacultySection">
      <div className="row mbottomnill">
        <div className="experiencedbox">
          <p>Learn from the</p>
          <h3>Top Medical Experts</h3>
        </div>
        <div className="row">
          <Carousel
            carouselClass="facultyCarausal"
            showNavigationBtn={true}
            carauselOptions={carauselOptions}
          >
            {faculties.map((obj,index) => (
              <div key={index} className="item">
                <div className="experiencedcontentbox">
                  <div className="experiencedimgcircle">
                    <img
                      src={obj.bannerImage}
                    />
                  </div>
                  <div className="experiencedcontentpr">
                    <p>
                      <strong>{obj.name}</strong>
                    </p>
                    <p>
                      <span className="facultyQualificationHome">{obj.qualification}</span>
                      <span className="facultyQualificationHome">{obj.qualification2}</span>
                      {/* <span className="facultyQualificationHome">{obj.Qualification3}</span> */}
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </Carousel>
        </div>
      </div>
    </div>)
  );
};

export default FacultySection;
