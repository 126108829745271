import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { removeLocalStorageData } from "../helpers/common";
import {
  getLoginUserInfoFromCookie,
  getUserSession,
  setUserSession,
} from "../helpers/loginFunctions";

export const supportLogin = createAsyncThunk(
  "supportLogin",
  async (args, { dispatch, getState, rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await axios
        .post(
          `${process.env.REACT_APP_SERVER_URL}userapi/v3/login`,
          {
            username: args.username,
            password: args.password,
          },
          {
            withCredentials: true,
          }
        )
        .catch((err) => {
          throw new Error(err);
        });

      if (
        !response.data ||
        !response.data.status ||
        response.data.status == 0
      ) {
        console.log({ response, Reject: 1 });
        return rejectWithValue({ msg: response.data.error.message });
      }

      let loginResponse = response.data.data;
      console.log("support login response");
      console.log(loginResponse);
      //Hack - returns 2 different responses for existing user and new user
      let userId = loginResponse.uid ? loginResponse.uid : loginResponse.userId;

      const profileResponse = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}userapi/v3/users/${userId}?type=profile`,
        {
          withCredentials: true,
        }
      );

      //if there is any error in profile api
      if (!profileResponse.status || profileResponse.status != "200") {
        console.log("Error in profile Api!!");
        return rejectWithValue({ msg: profileResponse.data.message });
      }

      //if status success but no data (api return success with any userId)
      if (!profileResponse.data?.data?.profile?.uid) {
        console.log("User profile not found!!");
        return rejectWithValue({ msg: "User profile data not found!!" });
      }

      return profileResponse.data.data.profile;
    } catch (err) {
      // Error handling here
      return rejectWithValue({
        msg: "Oops!! Some error occured in login process!!",
      });
    }
  }
);

export const sentOtp = createAsyncThunk(
  "sentOtp",
  async (args, { dispatch, getState, rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_SERVER_URL}verifyapi/v1/verify/_send_otp`,
        {
          mobile: args.userMobile,
        },
        {
          withCredentials: true,
        }
      );

      if (response.data.status && response.data.status == 1) {
        return response.data.data;
      } else {
        return rejectWithValue({ msg: response.data.message });
      }
    } catch (err) {
      // Error handling here
      return rejectWithValue({
        msg: "Oops!! Some error occured while sending OTP!!",
      });
    }
  }
);

export const login = createAsyncThunk(
  "login",
  async (args, { dispatch, getState, rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await axios
        .post(
          `${process.env.REACT_APP_SERVER_URL}userapi/v3/register`,
          {
            isd: "+91",
            mobile: args.userMobile,
            password: args.userMobile,
            confirmpassword: args.userMobile,
            //gradeId:8,
            curriculumId: 45,
            verificationType: 2,
            otp: args.otp,
          },
          {
            withCredentials: true,
          }
        )
        .catch((err) => {
          throw new Error(err);
        });

      if (
        !response.data ||
        !response.data.status ||
        response.data.status == 0
      ) {
        return rejectWithValue({ msg: response.data.message });
      }

      let registerResponse = response.data.data;
      console.log("registerResponse response");
      console.log(registerResponse);
      //Hack - returns 2 different responses for existing user and new user
      let registerationUId = registerResponse.uid
        ? registerResponse.uid
        : registerResponse.userId;

      const profileResponse = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}userapi/v3/users/${registerationUId}?type=profile`,
        {
          withCredentials: true,
        }
      );

      console.log("profile response");
      console.log(profileResponse);

      //if there is any error in profile api
      if (!profileResponse.status || profileResponse.status != "200") {
        console.log("Error in profile Api!!");
        return rejectWithValue({ msg: profileResponse.data.message });
      }

      //if status success but no data (api return success with any userId)
      if (!profileResponse.data?.data?.profile?.uid) {
        console.log("User profile not found!!");
        return rejectWithValue({ msg: "User profile data not found!!" });
      }

      return profileResponse.data.data.profile;
    } catch (err) {
      // Error handling here
      return rejectWithValue({
        msg: "Oops!! Some error occured in login process!!",
      });
    }
  }
);

export const logout = createAsyncThunk(
  "logout",
  async (args, { rejectWithValue }) => {
    const response = await axios
      .get(`${process.env.REACT_APP_SERVER_URL}userapi/v3/users/_logout`, {
        withCredentials: true,
      })

      if (!response.status || response.status != "200") {
        console.log("Logout::Error in logout Api!!");
        return rejectWithValue({ msg: response?.data?.message });
      }

      return response.data.data;
  }
);

export const isUserLoggedIn = createAsyncThunk(
  "isUserLoggedIn",
  async (args, { rejectWithValue }) => {
    let loggedInUserId = getLoginUserInfoFromCookie("userId");
    if (!loggedInUserId && loggedInUserId <= "1") {
      console.log("isUserLoggedIn::Invalid user cookie");
      return rejectWithValue({ msg: "user not logged in" });
    }else if(window.IS_MOBILE_APP && loggedInUserId){ // bypass user login check for app autologin
      setUserSession(loggedInUserId)
    }

    const authUser = getUserSession();
    if (!authUser && authUser != loggedInUserId) {
      console.log("isUserLoggedIn::Invalid user localstorage", authUser, loggedInUserId);
      return rejectWithValue({ msg: "user not logged in" });
    }
    const profileResponse = await axios.get(
      `${process.env.REACT_APP_SERVER_URL}userapi/v3/users/${loggedInUserId}?type=profile`,
      {
        withCredentials: true,
      }
    );

    //if there is any error in profile api
    if (!profileResponse.status || profileResponse.status != "200") {
      console.log("isUserLoggedIn::Error in profile Api!!");
      return rejectWithValue({ msg: profileResponse.data.message });
    }

    //if status success but no data (api return success with any userId)
    if (!profileResponse.data?.data?.profile?.uid) {
      console.log("isUserLoggedIn::User profile not found!!");
      return rejectWithValue({ msg: "User profile data not found!!" });
    }

    return profileResponse.data.data.profile;
  }
);

//initial state
const authState = {
  otpSent: false,
  otpVerified: false,
  isLoggedIn: null,
  userId: null,
  username: null,
  name: null,
  email: null,
  mobile: null,
  mobile10: null,
  curriculum: null,
  grade: null,
  profileImg: null,
  fullname: null,
  academicYear: null,
  stateId: null,
  colgId: null,
  profileData: null,
  successMsg: "",
  errorMsg: "",
};

export const authSlice = createSlice({
  name: "auth",
  initialState: authState,
  reducers: {
    logoutState(state) {
      state.otpSent = false;
      state.isLoggedIn = 0;
      state.userId = null;
      state.username = null;
      state.name = null;
      state.email = null;
      state.mobile = null;
      state.mobile10 = null;
      state.curriculum = null;
      state.grade = null;
      state.profileImg = null;
      state.fullname = null;
      state.academicYear = null;
      state.stateId = null;
      state.colgId = null;
      state.profileData = null;
      state.errorMsg = "";
      state.successMsg = "";
      removeLocalStorageData();
    },
    showSuccessMsg(state, action) {
      // window.scrollTo({top: 0, behavior: 'smooth'});
      state.successMsg = action.payload.msg;
    },
    showErrorMsg(state, action) {
      // window.scrollTo({top: 0, behavior: 'smooth'});
      state.errorMsg = action.payload.msg;
    },
    hideSuccessMsg(state) {
      state.successMsg = "";
    },
    hideErrorMsg(state) {
      state.errorMsg = "";
    },
  },
  extraReducers: {
    [sentOtp.fulfilled]: (state, action) => {
      state.otpSent = true; 
      state.errorMsg = "";
      state.successMsg = "";
    },
    [sentOtp.rejected]: (state, action) => {
      state.otpSent = false;
      state.isLoggedIn = 0;
      state.userId = null;
      state.errorMsg = action.payload.msg;
      console.log(action.payload.msg)
      state.successMsg = "";
    },
    [login.fulfilled]: (state, action) => {
      state.otpVerified = true;
      state.isLoggedIn = 1;
      state.userId = action.payload.uid;
      state.username = action.payload.username;
      state.name = action.payload.name;
      state.email = action.payload.email;
      state.mobile = action.payload.mobile;
      state.mobile10 = action.payload.mobile10;
      state.curriculum = action.payload.curriculum_id;
      state.grade = action.payload.grade_id;
      state.profileImg = action.payload.picture;
      let fullname = null;
      if(action.payload.first_name || action.payload.last_name){
        fullname = action.payload.first_name ? action.payload.first_name : "";
        fullname += action.payload.last_name ? action.payload.last_name : "";
      }
      state.fullname = fullname;
      state.academicYear =
        action.payload.grade_id == "10" ? null : action.payload.grade_id;
      state.stateId = action.payload.state_id;
      state.colgId = action.payload.school_id;
      state.profileData = action.payload;
      state.errorMsg = "";
      state.successMsg = "";
      setUserSession(state.userId);
    },
    [supportLogin.rejected]: (state, action) => {
      state.isLoggedIn = 0;
      state.userId = null;
      state.errorMsg = action.payload.msg;
      state.successMsg = "";
    },
    [supportLogin.fulfilled]: (state, action) => {
      state.otpVerified = true;
      state.isLoggedIn = 1;
      state.userId = action.payload.uid;
      state.username = action.payload.username;
      state.name = action.payload.name;
      state.email = action.payload.email;
      state.mobile = action.payload.mobile;
      state.mobile10 = action.payload.mobile10;
      state.curriculum = action.payload.curriculum_id;
      state.grade = action.payload.grade_id;
      state.profileImg = action.payload.picture;
      let fullname = null;
      if(action.payload.first_name || action.payload.last_name){
        fullname = action.payload.first_name ? action.payload.first_name : "";
        fullname += action.payload.last_name ? action.payload.last_name : "";
      }
      state.fullname = fullname;
      state.academicYear =
        action.payload.grade_id == "10" ? null : action.payload.grade_id;
      state.stateId = action.payload.state_id;
      state.colgId = action.payload.school_id;
      state.profileData = action.payload;
      state.errorMsg = "";
      state.successMsg = "";
      setUserSession(state.userId);
    },
    [login.rejected]: (state, action) => {
      state.otpVerified = false;
      state.isLoggedIn = 0;
      state.userId = null;
      state.username = null;
      state.name = null;
      state.email = null;
      state.mobile = null;
      state.mobile10 = null;
      state.curriculum = null;
      state.grade = null;
      state.profileImg = null;
      state.fullname = null;
      state.academicYear = null;
      state.stateId = null;
      state.colgId = null;
      state.profileData = null;
      state.errorMsg = action.payload.msg;
      state.successMsg = "";
      removeLocalStorageData(true);
    },
    [logout.fulfilled]: (state, action) => {
      state.otpSent = false;
      state.otpVerified = false;
      state.isLoggedIn = 0;
      state.userId = null;
      state.username = null;
      state.name = null;
      state.email = null;
      state.mobile = null;
      state.mobile10 = null;
      state.curriculum = null;
      state.grade = null;
      state.profileImg = null;
      state.fullname = null;
      state.academicYear = null;
      state.stateId = null;
      state.colgId = null;
      state.profileData = null;
      state.errorMsg = "";
      state.successMsg = "";
      removeLocalStorageData();
    },
    [logout.rejected]: (state, action) => {
      state.otpSent = false;
      state.otpVerified = false;
      state.isLoggedIn = 0;
      state.userId = null;
      state.username = null;
      state.name = null;
      state.email = null;
      state.mobile = null;
      state.mobile10 = null;
      state.curriculum = null;
      state.grade = null;
      state.profileImg = null;
      state.fullname = null;
      state.academicYear = null;
      state.stateId = null;
      state.colgId = null;
      state.profileData = null;
      state.errorMsg = "";
      state.successMsg = "";
      removeLocalStorageData(true);
    },
    [isUserLoggedIn.fulfilled]: (state, action) => {
      state.otpSent = true;
      state.otpVerified = true;
      state.isLoggedIn = 1;
      state.userId = action.payload.uid;
      state.username = action.payload.username;
      state.name = action.payload.name;
      state.email = action.payload.email;
      state.mobile = action.payload.mobile;
      state.mobile10 = action.payload.mobile10;
      state.curriculum = action.payload.curriculum_id;
      state.grade = action.payload.grade_id;
      state.profileImg = action.payload.picture;
      let fullname = null;
      if(action.payload.first_name || action.payload.last_name){
        fullname = action.payload.first_name ? action.payload.first_name : "";
        fullname += action.payload.last_name ? action.payload.last_name : "";
      }
      state.fullname = fullname;
      state.academicYear =
        action.payload.grade_id == "10" ? null : action.payload.grade_id;
      state.stateId = action.payload.state_id;
      state.colgId = action.payload.school_id;
      state.profileData = action.payload;
      //do not update error msg here (redirecting from another page with msg should work)
      //state.errorMsg = ""; 
      //state.successMsg = "";
      setUserSession(state.userId);
    },
    [isUserLoggedIn.rejected]: (state, action) => {
      state.otpSent = false;
      state.otpVerified = false;
      state.isLoggedIn = 0;
      state.userId = null;
      state.username = null;
      state.name = null;
      state.email = null;
      state.mobile = null;
      state.mobile10 = null;
      state.curriculum = null;
      state.grade = null;
      state.profileImg = null;
      state.fullname = null;
      state.academicYear = null;
      state.stateId = null;
      state.colgId = null;
      state.profileData = null;
      state.errorMsg = "";
      state.successMsg = "";
      removeLocalStorageData(true);
    },
  },
});

export const authActions = authSlice.actions;
