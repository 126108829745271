import parse from "html-react-parser";
const GST_RATE = process.env.REACT_APP_GST_RATE;

function ProductDetail(props) {
	const productList = props.productList || [];
	const productCategories = props.productCategories || [];
	const addRemoveAddOn = props.addRemoveAddOn;
	const buyProduct = props.buyProduct;
	const cId = props.cId || [];

	return (
		<>
			{productList && Object.keys(productList).length >= 1 && Object.keys(productList).map((pId) => {
				return (productList[pId]["category_id"] == productCategories[cId]["category_id"] && (
					<div
						className="row"
						key={"list" + pId}
					>
						<div className="item" key={"item" + pId}>
							<div className="productlistmainbox">
								{/* {productList[pId]['tags'] && productList[pId]['tags'].indexOf("BEST_SELLER") > -1 &&
                      <div className="bestsellerBox">
                        <p>Bestseller</p>
                      </div>
                    } */}

								<div className="row mbottomnill">
									<div className="col s12 nmp">
										<div className="productmonth">
											<p>
												<strong>
													{productList[pId]["product_name"]}
												</strong>
											</p>
											<div className="">
												<p>{(['1', '2', '3'].includes(productCategories[cId]["category_id"])) ? productCategories[cId]["course_category"] + " plan" : productList[pId]["product_title"]}</p>
												<br />
											</div>
										</div>
									</div>
								</div>
								<div className="row mbottomnill">
									<div className="col s12 nmp">
										<ul className={`imgcontent category_feature_${productList[pId]["category_id"]}`}>
											<div className="col s12 nmp">
												{parse(productList[pId]["feature_summary"] ? productList[pId]["feature_summary"] : "")}
											</div>
										</ul>
									</div>
								</div>
								<div className="row">
									<div className="hrborder"></div>
									<div className="productcontentafterborder">
										<table>
											<tbody>
												<tr>

													<td>
														<div className="">
															<table >
																<tbody>
																	{productList[pId]['has_addon'] && productList[pId]['addons'].length > 0 && (
																		<tr>
																			<td>
																				<div className="materialText">
																					<div className="ProductTabsCheckbox">
																						<label>
																							<input type="checkbox" className="filled-in" defaultChecked={true} onChange={(e) => { addRemoveAddOn(e, productList[pId]) }} />
																							<span></span>
																						</label>
																					</div>

																					<p>Add Study Material</p>
																					<span className="tooltip">
																						<i className="material-icons dp48">info_outline</i>
																						<span className="tooltiptext">
																							<div className="productTabTooltipTable">
																								<table>
																									<thead>
																										<tr>
																											<th>
																												<p>Components</p>
																											</th>
																											<th>
																												<p>Total</p>
																											</th>
																										</tr>
																									</thead>
																									<tbody>
																										<tr>
																											<td>
																												<p>
																													{productList[pId]['addons'][0]['product_title']}
																												</p>
																											</td>
																											<td>
																												<p>
																													₹{Math.round(productList[pId]['addons'][0]['price_with_tax'] / ((100 + parseFloat(GST_RATE)) / 100))}
																												</p>
																											</td>
																										</tr>
																										<tr>
																											<td>
																												<p>
																													Net Payable amount:
																												</p>
																											</td>
																											<td>
																												<p>
																													₹{Math.round(productList[pId]['addons'][0]['price_with_tax']).toFixed(0) + " "}
																												</p>
																											</td>
																										</tr>
																									</tbody>
																								</table>
																							</div>
																						</span>
																					</span>

																				</div>
																			</td>
																			<td>
																				<p className="materialPrice">
																					<del>
																						<span>₹{productList[pId]['addons'][0]["original_price"]}</span>
																					</del> <span className="ProductTabRupee">₹</span>
																					{Math.round(productList[pId]['addons'][0]['price_with_tax']).toFixed(0) + " "}
																				</p>
																			</td>
																		</tr>
																	)}
																	<tr>
																		<td>
																			<div className="materialTotalFee">
																				<p>Total Fees</p>
																				<span className="tooltip">
																					<i className="material-icons dp48">info_outline</i>
																					<span className="tooltiptext">
																						<div className="productTabTooltipTable">
																							<table>
																								<thead>
																									<tr>
																										<th>
																											<p>Components</p>
																										</th>
																										<th>
																											<p>Total</p>
																										</th>
																									</tr>
																								</thead>
																								<tbody>
																									<tr>
																										<td>
																											<p>
																												Total course fees:
																											</p>
																										</td>
																										<td>
																											<p>
																												₹{Math.round(parseFloat(productList[pId]["price_with_tax"]) / ((100 + parseFloat(GST_RATE)) / 100)).toFixed(0)}
																											</p>
																										</td>
																									</tr>
																									<tr>
																										<td>
																											<p>
																												GST:
																											</p>
																										</td>
																										<td>
																											<p>
																												₹{Math.round(parseFloat(productList[pId]["price_with_tax"]) - (parseFloat(productList[pId]["price_with_tax"]) / ((100 + parseFloat(GST_RATE)) / 100))).toFixed(0)}
																											</p>
																										</td>
																									</tr>
																									{/* <tr>
                                                        <td>
                                                          <p>
                                                            Discount availed:
                                                          </p>
                                                        </td>
                                                        <td>
                                                          <p>
                                                            ₹ 00
                                                          </p>
                                                        </td>
                                                      </tr> */}
																									{productList[pId]['has_addon'] && productList[pId]['addons'].length > 0 && (
																										<tr>
																											<td>
																												<p>
																													Study material:
																												</p>
																											</td>
																											<td>
																												<p>
																													₹{Math.round(productList[pId]['addons'][0]['price_with_tax']).toFixed(0) + " "}
																												</p>
																											</td>
																										</tr>
																									)}
																									<tr>
																										<td>
																											<p>
																												Net Payable amount:
																											</p>
																										</td>
																										<td>
																											<p>
																												₹{Math.round(parseFloat(productList[pId]["price_with_tax"]) + ((productList[pId]['has_addon'] && productList[pId]['addons'].length > 0) ? parseFloat(productList[pId]['addons'][0]['price_with_tax']) : 0)).toFixed(0)}
																											</p>
																										</td>
																									</tr>
																								</tbody>
																							</table>
																						</div>
																					</span>
																				</span>
																			</div>
																		</td>
																		<td>
																			<div className="productprice">
																				<p className="materialPrice">
																					<del>
																						<span><span className="ProductTabRupee">₹</span>{" "}{Math.round(parseFloat(productList[pId]["origFee"]) + ((productList[pId]['has_addon'] && productList[pId]['addons'].length > 0) ? parseFloat(productList[pId]['addons'][0]['original_price']) : 0)).toFixed(0)}</span>
																					</del>

																					<strong> <span className="ProductTabRupee">₹</span>{Math.round(parseFloat(productList[pId]["price_with_tax"]) + ((productList[pId]['has_addon'] && productList[pId]['addons'].length > 0) ? parseFloat(productList[pId]['addons'][0]['price_with_tax']) : 0)).toFixed(0)}
																					</strong>
																				</p>
																			</div>
																		</td>
																	</tr>
																</tbody>
															</table>
														</div>
													</td>
												</tr>
											</tbody>
										</table>

									</div>
									<div className="prodcutlistBtn">
										<a
											className="btn PurchaseBTNActive"
											onClick={() =>
												buyProduct(productList[pId]["id"], productList[pId]["product_name"], pId)
											}
										>
											Buy Now
										</a>
									</div>
								</div>
							</div>
						</div>
					</div>
				))
			})}
		</>
	)
}

export default ProductDetail