import { useEffect } from "react";
import M from "materialize-css";
import { AlertMsg } from "./alert";

/* Loader start */
export function Loader(props) {
  let loaderClass = props.loaderClass
    ? "btn " + props.loaderClass
    : "btn LoginPageBTN";
  return (
    <div className={loaderClass}>
      <div className="buttonLoader">
        <span className="dot"></span>
        <div className="dots">
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
    </div>
  );
}

/* spin loader */
export function Spinner() {
  return (
    <div className="preloader-wrapper big active spinLoader">
      <div className="spinner-layer spinner-blue-only">
        <div className="circle-clipper left">
          <div className="circle"></div>
        </div>
        <div className="gap-patch">
          <div className="circle"></div>
        </div>
        <div className="circle-clipper right">
          <div className="circle"></div>
        </div>
      </div>
    </div>
  );
}

/* Modal - to open any html content in modal 
trigger model - 
<a className="modal-trigger" href="#modalAddress">click</a>
<Modal modalId={"modalAddress"}>
<div className="row">hello</div>
<button className="modal-close waves-effect waves-green btn-flat">Submit</button>
</Modal>
**/

export function Modal(props) {
  const showCloseBtn = props.showCloseBtn ? props.showCloseBtn : false;

  useEffect(() => {
    var elems = document.querySelectorAll(".modal");
    var instances = M.Modal.init(elems);
  }, []);

  return (
    <div id={props.modalId} className="modal">
      <div className="modal-content">
        <AlertMsg />
        {props.children}
      </div>
      <div className="modal-footer">
        <a
          // href="#!"
          style={showCloseBtn ? {} : { display: "none" }}
          ref={props.closeModalRef}
          className="modal-close waves-effect waves-green btn-flat"
        >
          Close
        </a>
      </div>
    </div>
  );
}
