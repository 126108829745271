import { useEffect } from "react";
import { get_platform } from "../../helpers/common";
import { gtm_date_time, gtm_event } from "../../helpers/google_tracking";
import { Loader } from "../common/common";

const BeforeOtp = (props) => {

  // when component is unmounted clean states (useeffect is called here only once [] so no reloading state)
  const unmountComponent = () => {
    props.setLoading(false);
  };
  // to check if user is already logged in
  useEffect(() => {
    return () => {
      unmountComponent();
    };
  }, []);

  return (
    <>
      <div className="loginheading">
        <h1>
          {props.loginSignUpToggle == "" || props.loginSignUpToggle == "1"
            ? "LOGIN"
            : "SIGNUP"}
          <br /> <span>to start learning</span>{" "}
        </h1>
      </div>
      <div className="row RemoveMargin">
        <form
          className="col s12 loginformcontrol"
          onSubmit={props.handleMobileSubmit}
        >
          <div className="row">
            <div className="col s3 m3">
              <div className="countrycodediv">
                <img
                  className="responsive-img"
                  src={
                    process.env.REACT_APP_PUBLIC_URL + "dist/img/india-flag.png"
                  }
                />
                <p>+91</p>
              </div>
            </div>
            <div className="col s9 m9">
              <div className="countrymobile">
                <div className="input-field col s12">
                  <input
                    id="mobile"
                    type="text"
                    value={props.userMobile}
                    onChange={(e) => {
                      props.setUserMobile(e.target.value);
                    }}
                    className="validate"
                  />
                  <label htmlFor="mobile">Enter Your Mobile Number</label>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="LoginPageBTNBox">
              { !props.loading ? <div
                className="btn LoginPageBTN"
                onClick={(e) => {
                  props.handleMobileSubmit(e);
                }}
              >
                {props.loginSignUpToggle == "" || props.loginSignUpToggle == "1"
                  ? "Login"
                  : "SIGNUP"}
              </div>: ""}
              {props.loading && <Loader />}
            </div>
          </div>
          <div>
            <div className="LoginPageCreateBTNBox">
              <a
                className="btn LoginPageCreateBTN"
                onClick={(e) => {
                  props.setLoginSignupToggle(
                    props.loginSignUpToggle == "1" ||
                      props.loginSignUpToggle == ""
                      ? 2
                      : 1
                  );

                  gtm_event({
                    event: props.loginSignUpToggle == "" || props.loginSignUpToggle == "1" ? "tap_createnewaccount" :"tap_login_business",
                    ...gtm_date_time(),
                    platform: get_platform()
                  })
                }}
              >
                {props.loginSignUpToggle == "" || props.loginSignUpToggle == "1"
                  ? "CREATE A NEW ACCOUNT"
                  : "LOGIN"}
              </a>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default BeforeOtp;
