//get logged in user from cookie
export const getLoginUserInfoFromCookie = (name) => {
  var loginUserId = 0;
  var cookieVal = getCookie("AAKASH");
  if (cookieVal && cookieVal.indexOf("|") === -1) {
    cookieVal = decodeURIComponent(cookieVal);
  }
  if (cookieVal && cookieVal.indexOf("|") !== -1) {
    cookieVal = cookieVal.split("|");
    if (cookieVal.length) {
      loginUserId = parseInt(cookieVal[cookieVal.length - 1]);
    }
  }
  if (name === "userId") {
    return loginUserId;
  }
  if (name === "curriculumId" || name === "gradeId") {
    if (loginUserId === 0) {
      return 0; //If user not logged in then no curriculumId/gradeId for user
    }
    var cookieVal = getCookie("CakeCookie[defaultPageCurrGrade]");
    if (cookieVal && cookieVal.indexOf("-") !== -1) {
      cookieVal = cookieVal.split("-");
      if (cookieVal.length) {
        if (name === "curriculumId" && cookieVal[0]) {
          var curriculumId = parseInt(cookieVal[0]);
          if (curriculumId > 0) {
            return curriculumId;
          }
        } else if (name === "gradeId" && cookieVal[1]) {
          var gradeId = parseInt(cookieVal[1]);
          if (gradeId > 0) {
            return gradeId;
          }
        }
      }
    }
    return 0;
  }
  return false;
};

//function to get specific cookie from all cookies (PG copy)
export const getCookie = (name) => {
  var cookieVal = document.cookie.split(";");
  var tempCookie;
  for (var i in cookieVal) {
    tempCookie = cookieVal[i].trim().split("=");
    if (tempCookie[0] == name) {
      return tempCookie[1];
    }
  }
  return null;
};

//function to set cookie (PG copy)
export const setCookie = (c_name, value, exdays) => {
  var domain = document.domain;
  domain = domain.replace("www.", "");
  var exdate = new Date();
  exdate.setDate(exdate.getDate() + exdays);
  var c_value =
    escape(value) +
    (exdays == null ? "" : "; expires=" + exdate.toUTCString()) +
    ";domain=." +
    domain +
    ";path=/";
  document.cookie = c_name + "=" + c_value;
};

  // get user from local storage
  export const getUserSession = () => {
    const userStr = localStorage.getItem("csLoginUser");
    try {
      if (userStr) return userStr;
    } catch(e) {
      console.log("Error in getting userSession from LS", e)
      return null;
    }
  };

  // remove user from local storage
  export const removeUserSession = () => {
    localStorage.removeItem("csLoginUser");
  };
  
  // set user in local storage
  export const setUserSession = (user) => {
    localStorage.setItem("csLoginUser", user);
  };
