import { React, useEffect, useState, useRef } from "react";
import {useParams } from "react-router-dom";
import parse from "html-react-parser";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import axios from "axios";

function setItemHeight(ItemCount, uomDivHeightRef, descDivHeightRef) {
  for (var i = 1; i <= ItemCount; i++) {
    console.log({
      i,
      current: descDivHeightRef.current[1],
      div: descDivHeightRef.current[i],
      descDivHeightRef,
      uomDivHeightRef,
    });
    var divHeight = descDivHeightRef.current[i].clientHeight + 5;
    var uomDivHeight = uomDivHeightRef.current[i].clientHeight + 5;

    if (uomDivHeight > divHeight) {
      divHeight = uomDivHeight;
    }

    //     var row = document.getElementsByClassName("row" + i);
    //     for (var j = 1; j <= row.length; j++) {
    //       row[j - 1].setAttribute("style", "height:" + divHeight);
    //     }
    //   }
  }
}

function empty(string) {
  if (
    string == undefined ||
    string.length == 0 ||
    string == "0" ||
    string == 0
  ) {
    return true;
  }
}

function number_format(number, decimal) {
  if (empty(number)) {
    return 0;
  }
  if (
    number == 0 ||
    number == "0.0" ||
    number == "0.00" ||
    number == "00.0" ||
    number == "00.00"
  ) {
    return "0.00";
  }
  number = Number(number).toFixed(2);

  let numArray = number.split(".");
  var resultantNum = Number(numArray[0]).toLocaleString() + "." + numArray[1];
  return resultantNum;
}
const getInvoiceQRCode = async (invoiceId) => {
  return axios
    .get(
      `${process.env.REACT_APP_SERVER_URL}purchaseapi/v2/qrcodegeneration?organisationId=1&invoiceId=${invoiceId}`,
      {
        withCredentials: true,
      }
    )
    
};
const getInvoiceDetails = (arrProductObj) => {
  var count = 0;
  var _total = 0;
  var sNo = [];
  var description = [];
  var hsnCode = [];
  var qty = [];
  var uom = [];
  var rate = [];
  var total = [];
  var discount = [];
  var creditAmount = [];
  var taxableValue = [];
  var cgstRate = [];
  var cgstAmount = [];
  var sgstRate = [];
  var sgstAmount = [];
  var igstRate = [];
  var igstAmount = [];
  var rowTotal = [];

  var grandCGST = 0;
  var grandSGST = 0;
  var grandIGST = 0;
  var grandTaxableValue = 0;
  var lastProductVal;
  for (const [key, value] of Object.entries(arrProductObj)) {
    count++;
    value["rate"] = value["rate"] * 1.18;
    sNo.push(count);
    description.push(value["descriptionOfGoods"]);
    hsnCode.push(value["hsnCode"]);
    qty.push(value["qty"]);
    uom.push(value["uom"]);
    rate.push(number_format(value["rate"], 2));
    total.push(number_format(value["total"], 2));
    discount.push(number_format(value["discountAmount"] * -1, 2));
    creditAmount.push(number_format(value["creditAmount"] * -1, 2));
    taxableValue.push(number_format(value["taxableValue"], 2));
    cgstRate.push(number_format(value["CGST"]["rate"], 2));
    cgstAmount.push(number_format(value["CGST"]["amount"], 2));
    sgstRate.push(number_format(value["SGST"]["rate"], 2));
    sgstAmount.push(number_format(value["SGST"]["amount"], 2));
    igstRate.push(number_format(value["IGST"]["rate"], 2));
    igstAmount.push(number_format(value["IGST"]["amount"], 2));

    grandTaxableValue += value["taxableValue"];
    grandCGST += empty(value["CGST"]["amount"]) ? 0 : value["CGST"]["amount"];
    grandSGST += empty(value["SGST"]["amount"]) ? 0 : value["SGST"]["amount"];
    grandIGST += empty(value["IGST"]["amount"]) ? 0 : value["IGST"]["amount"];
    let rowTotalAmount = parseFloat(value["taxableValue"]);
    rowTotalAmount += empty(value["CGST"]["amount"])
      ? 0
      : parseFloat(value["CGST"]["amount"]);
    rowTotalAmount += empty(value["SGST"]["amount"])
      ? 0
      : parseFloat(value["SGST"]["amount"]);
    rowTotalAmount += empty(value["IGST"]["amount"])
      ? 0
      : parseFloat(value["IGST"]["amount"]);

    _total = grandTaxableValue + grandCGST + grandSGST + grandIGST - _total;

    rowTotal.push(number_format(rowTotalAmount, 2));
    lastProductVal = value;
  }
  console.log({ igstAmount });
  return {
    count,
    _total,
    sNo,
    description,
    hsnCode,
    qty,
    uom,
    rate,
    total,
    discount,
    creditAmount,
    taxableValue,
    cgstRate,
    cgstAmount,
    sgstRate,
    sgstAmount,
    igstRate,
    igstAmount,
    grandCGST,
    grandSGST,
    grandIGST,
    rowTotal,
    grandTaxableValue,
    lastProductVal,
  };
};

function PurchaseInvoice() {
  const [prod, setProd] = useState({});
  //const [height, setHeight] = useState(0)
  const [invoiceData, setInvoiceData] = useState({});
  const [invoiceIRNDetail, setInvoiceIRNDetail] = useState({});
  const [paymentInvoiceData, setPaymentInvoiceData] = useState({});
  const [invoiceQRCodeUrl, setInvoiceQRCodeUrl] = useState("");
  const [loading, setLoading] = useState(true);
  const uomDivHeightRef = useRef([]);
  const descDivHeightRef = useRef([]);
  const [objInvoiceDetail, setInvoiceDetail] = useState({
    count: [],
    _total: [],
    sNo: [],
    description: [],
    hsnCode: [],
    qty: [],
    uom: [],
    rate: [],
    total: [],
    discount: [],
    creditAmount: [],
    taxableValue: [],
    cgstRate: [],
    cgstAmount: [],
    sgstRate: [],
    sgstAmount: [],
    igstRate: [],
    igstAmount: [],
    grandCGST: [],
    grandSGST: [],
    grandIGST: [],
    rowTotal: [],
    grandTaxableValue: [],
    lastProductVal: [],
  });

  const { height, width } = useWindowDimensions();
  const {invoiceId}  = useParams()

  const fetchInvoiceDetails = () => {
    axios
      .get(
        `${process.env.REACT_APP_SERVER_URL}purchaseapi/v2/invoicedetails?id=${invoiceId}`,
        {
          withCredentials: true,
        }
      )
      .then((responseData) => {
        console.log({ responseData });
        var pobj = responseData.data.data.invoice.json.products;
        let tmpProd = pobj[Object.keys(pobj)[0]];
        // console.log({ prod: tmpProd });
        setProd(tmpProd);
        setInvoiceData(responseData.data.data.invoice);
        setPaymentInvoiceData(responseData.data.data.PaymentInvoice);
        setInvoiceIRNDetail(responseData.data.data.EcInvoiceIRNDetail)

        let invoiceDtls = getInvoiceDetails(pobj);
        console.log({ invoiceDtls });
        setInvoiceDetail(invoiceDtls);

        // set qrcode url
        getInvoiceQRCode(responseData.data.data.invoice.id).then((qrCodeResponse) => {
           setInvoiceQRCodeUrl(qrCodeResponse.data.data.img_url)
        }).catch((error) => {
          console.log(error);
        });
        
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // to check if user is already logged in
  useEffect(() => {
    window.resizeTo(width, height);
    fetchInvoiceDetails();
    setItemHeight(objInvoiceDetail?.count, uomDivHeightRef, descDivHeightRef);
    // need to refresh login state bcz login component is out of protected route
    console.log("refreshing support-login");
  }, []);

  return (
    <>
      <table id="receipt" align="center">
        <tbody>
          <tr>
            <td align="center" colSpan="2">
              <img src="./dist/img/pg_plus1.png" />
            </td>
            {invoiceQRCodeUrl && (<td>
              <div id="qr-code">
                
                  <img
                    src={`data:image/png;base64,${invoiceQRCodeUrl}`}
                    alt="Payment QR code"
                  />
                
              </div>{" "}
            </td>)}
            <td colSpan={invoiceQRCodeUrl?"11":"12"}>
              <center>
                <div style={{"fontSize":"16px", "fontWeight":"bold", "marginBottom":"20px", "lineHeight": "22px"}}>
                  <div id="invoiceType">
                    {invoiceData?.invoiceTypeId == 5
                      ? "CREDIT NOTE"
                      : "TAX INVOICE"}
                  </div>
                  <div>Aakash EduTech Private Limited.</div>
                </div>
              </center>
              <div style={{"float":"left"}}>
                Regd Office: Aakash Tower, Plot No 8,Pusa Road,
                <br />New Delhi-110005,Ph:011-47623456
                <br />
                <div>Corp Office: Aakash Live A1/22 Janakpuri</div>
                <div>Delhi Delhi-110058</div>
                <div>{process.env.REACT_APP_PUBLIC_URL.endsWith('/') ? process.env.REACT_APP_PUBLIC_URL.slice(0, -1) : process.env.REACT_APP_PUBLIC_URL}</div>
              </div>
              <div style={{"float":"right"}}>
                GSTIN: 07AADCA3289M1ZB
                <br />
                Company CIN: U99999DL2001PTC110324 <br />
              </div>
            </td>
          </tr>
          <tr>
            <td colSpan="7">
              <div>
                GSTIN Number:{" "}
                <b id="gstinNumber">
                  {!empty(invoiceData?.json?.gstinNumber)
                    ? invoiceData?.json?.gstinNumber
                    : ""}
                </b>
              </div>
              <div>
                Tax Is Payable On Reverse Charge: <b>No</b>
              </div>
              <div>
                Order Id: <b id="orderId">{invoiceData?.json?.orderId}</b>
              </div>
              {(invoiceIRNDetail?.irn)&&(
                <div>IRN: <b>{invoiceIRNDetail?.irn}</b></div>
              )}
               

              <div>
                <span id="invoiceSerialNoLabel">
                  {invoiceData.invoiceTypeID == 5
                    ? "Credit Note No"
                    : "Reference No"}
                </span>
                :{" "}
                <b id="invoiceSerialNo">{invoiceData?.json?.invoiceSerialNo}</b>
              </div>
              <div>
                invoice No :{" "}
                <b id="invoiceNo">{invoiceData?.json?.invoiceNoVal}</b>
              </div>
              <div>
                <span id="invoiceDateLabel">
                  {invoiceData.invoiceTypeID == 5
                    ? "Credit Note Date"
                    : "invoice Date"}
                </span>
                :<b id="invoiceDate">{invoiceData?.json?.invoiceDateVal}</b>
              </div>
              {invoiceData?.invoiceTypeID == 5 &&
                paymentInvoiceData?.invoiceNo && (
                  <div id="originalInvoiceNoDiv">
                    Original invoice No
                    <b id="originalInvoiceNo">{paymentInvoiceData.invoiceNo}</b>
                  </div>
                )}

              {invoiceData?.invoiceTypeID == 5 &&
                paymentInvoiceData?.invoiceDate && (
                  <div id="originalInvoiceDateDiv">
                    Original invoice Date
                    <b id="originalInvoiceDate">
                      {paymentInvoiceData?.invoiceDate}
                    </b>
                  </div>
                )}
            </td>
            <td colSpan="7">
              <div>Transportation Mode</div>
              <div>Veh.No:</div>
              <div>Date & Time of Supply:</div>
              <div>Place Of Supply:</div>
            </td>
          </tr>
          <tr>
            <td colSpan="7">Details of Receiver (Billed to)</td>
            <td colSpan="7">Details of Consignee (Shipped to)</td>
          </tr>
          <tr>
            <td colSpan="7">
              <div>
                Name:
                <b id="billingAddressName">
                  {invoiceData?.json?.billingAddress.name}
                </b>
              </div>
              <div>
                Address:
                <b id="billingAddress">
                  {invoiceData?.json?.billingAddress?.address}
                </b>
              </div>
              <div>
                State:
                <b id="billingAddressState">
                  {invoiceData?.json?.billingAddress?.state}
                </b>
              </div>
              <div>
                State Code:
                <b id="billingAddressStateId">
                  {invoiceData?.json?.billingAddress?.stateId}
                </b>
              </div>
              <div>
                GSTIN Number:
                <b id="billingAddressGstInNumber">
                  {invoiceData?.json?.billingAddress?.gstinNumber}
                </b>
              </div>
            </td>
            <td colSpan="7">
              <div>
                Name:
                <b id="shippingAddressName">
                  {invoiceData?.json?.shippingAddress?.name}
                </b>
              </div>
              <div>
                Address:
                <b id="shippingAddress">
                  {invoiceData?.json?.shippingAddress?.address}
                </b>
              </div>
              <div>
                State:
                <b id="shippingAddressState">
                  {invoiceData?.json?.shippingAddress?.state}
                </b>
              </div>
              <div>
                State Code:
                <b id="shippingAddressStateId">
                  {invoiceData?.json?.shippingAddress?.stateId}
                </b>
              </div>
              <div>
                GSTIN Number:
                <b id="shippingAddressGstInNumber">
                  {invoiceData?.json?.shippingAddress?.gstinNumber}
                </b>
              </div>
            </td>
          </tr>
          <tr>
            <td colSpan="14">Amount in Rupees</td>
          </tr>
          <tr id="header">
            <td>S.No</td>
            <td >Description of Goods</td>
            <td width="200 px">HSN/SAC Code</td>
            <td>Qty</td>
            <td >UOM</td>
            <td >Product Price</td>
            <td>Total Amount</td>
            <td>Discount</td>
            <td>Credit Amount</td>
            <td>Taxable value</td>
            <td align="center">CGST ({prod?.CGST?.rate}%)</td>
            <td align="center">SGST ({prod?.SGST?.rate}%)</td>
            <td align="center">IGST ({prod?.IGST?.rate}%)</td>
            <td width="80">Total</td>
          </tr>
          <tr
            id="invoiceDetails"
            height="300"
            className="bold"
            style={{ verticalAlign: "top" }}
          >
            <td>
              {objInvoiceDetail?.sNo?.map((count, key) => (
                <div key={key} className={"row" + count}>
                  {" "}
                  {count}{" "}
                </div>
              ))}
            </td>
            <td >
              {objInvoiceDetail?.description?.map((description, key) => (
                <div
                  ref={(el) => (descDivHeightRef.current[key + 1] = el)}
                  key={key}
                  className={"row" + (key + 1)}
                  id={"desc" + (key + 1)}
                >
                  {" "}
                  {parse(description.replace(/\r\n|\n|\r/gm, '<br />'))}{" "}
                </div>
              ))}
            </td>
            <td width="200 px">
              {objInvoiceDetail?.hsnCode?.map((hsnCode, key) => (
                <div key={key} className={"row" + (key + 1)}>
                  {" "}
                  {hsnCode}{" "}
                </div>
              ))}
            </td>
            <td align="center">
              {objInvoiceDetail?.qty?.map((qty, key) => (
                <div key={key} className={"row" + (key + 1)}>
                  {" "}
                  {qty}{" "}
                </div>
              ))}
            </td>
            <td align="center" >
              {objInvoiceDetail?.uom?.map((uom, key) => (
                <div
                  ref={(el) => (uomDivHeightRef.current[key + 1] = el)}
                  key={key}
                  className={"row" + (key + 1)}
                  id={"uom" + (key + 1)}
                >
                  {" "}
                  {parse(uom.replace("To", "<br/>To</br>"))}{" "}
                </div>
              ))}
            </td>
            <td align="right" >
              {objInvoiceDetail?.rate?.map((rate, key) => (
                <div key={key} className={"row" + (key + 1)}>
                  {" "}
                  {rate}{" "}
                </div>
              ))}
            </td>
            <td align="right" >
              {objInvoiceDetail?.total?.map((total, key) => (
                <div key={key} className={"row" + (key + 1)}>
                  {" "}
                  {total}{" "}
                </div>
              ))}
            </td>

            <td align="right" >
              {objInvoiceDetail?.discount?.map((discount, key) => (
                <div key={key} className={"row" + (key + 1)}>
                  {" "}
                  {discount}{" "}
                </div>
              ))}
            </td>

            <td align="right" >
              {objInvoiceDetail?.creditAmount?.map((creditAmount, key) => (
                <div key={key} className={"row" + (key + 1)}>
                  {" "}
                  {creditAmount}{" "}
                </div>
              ))}
            </td>

            <td align="right" >
              {objInvoiceDetail?.taxableValue?.map((taxableValue, key) => (
                <div key={key} className={"row" + (key + 1)}>
                  {" "}
                  {taxableValue}{" "}
                </div>
              ))}
            </td>

            <td align="right">
              {objInvoiceDetail?.cgstAmount?.map((cgstAmount, key) => (
                <div key={key} className={"row" + (key + 1)}>
                  {" "}
                  {cgstAmount}{" "}
                </div>
              ))}
            </td>
            <td align="right">
              {objInvoiceDetail?.sgstAmount?.map((sgstAmount, key) => (
                <div key={key} className={"row" + (key + 1)}>
                  {" "}
                  {sgstAmount}{" "}
                </div>
              ))}
            </td>
            <td align="right">
              {objInvoiceDetail?.igstAmount?.map((igstAmount, key) => (
                <div key={key} className={"row" + (key + 1)}>
                  {" "}
                  {igstAmount}{" "}
                </div>
              ))}
            </td>
            <td align="right" >
              {objInvoiceDetail?.rowTotal?.map((rowTotal, key) => (
                <div key={key} className={"row" + (key + 1)}>
                  {" "}
                  {rowTotal}{" "}
                </div>
              ))}
            </td>
          </tr>
          <tr>
            <td align="left" colSpan="8">
              <b>Amount Chargeable (in words): </b>
              <span id="amountInWords">{invoiceData?.json?.totalInWords}</span>
            </td>
            <td align="right" colSpan="5">
              Total
            </td>
            <td align="right" colSpan="" id="total">
              {invoiceData?.json?.total}
            </td>
          </tr>
          <tr id="dispatchOtherChanges">
            <td colSpan="8" rowSpan="3" valign="top">
              <div style={{ width: "500px", fontWeight: "bold" }}>
                {invoiceData?.json?.dispatchDetails && (
                  <div>{invoiceData?.json?.dispatchDetails}</div>
                )}

                {invoiceData?.json?.tabPrice && (
                  <div>{invoiceData?.json?.tabPrice}</div>
                )}
              </div>
            </td>
            <td align="right" colSpan="5">
              Other Charges
            </td>
            <td align="right">
              {number_format(invoiceData?.json?.otherCharges)}
            </td>
          </tr>
          <tr>
            <td align="right" colSpan="5">
              Roundoff Amount
            </td>
            <td align="right" id="adjustedAmount">
              {number_format(invoiceData?.adjustedAmount)}
            </td>
          </tr>
          <tr>
            <td align="right" colSpan="5">
              Total invoice
            </td>
            <td align="right">
              <b id="invoiceTotal">
                {number_format(invoiceData?.json?.invoiceTotal)}
              </b>
            </td>
          </tr>
          <tr>
            <td colSpan="8">
              Certified that the particulars given above are true and correct
            </td>
            <td colSpan="6">
              Electronic Reference Number:{" "}
              <b id="electronicRefNo">{invoiceData?.json?.electronicRefNo}</b>
            </td>
          </tr>
          <tr>
            <td colSpan="14">
              This is a system generated document and does not require signature
              and stamp.
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );
}

export default PurchaseInvoice;
