import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

function NotFound() {

  const navigate = useNavigate();
  const authState = useSelector((state) => state.auth); 
  useEffect(()=>{ //hide 404 temporariy
    if (authState.isLoggedIn) { 
      navigate("/login");
    }
    else{
      navigate("/");
    }
  },[authState.isLoggedIn])

  return (
    <>          
    <div className="row mt404">
        <div className="col s6 offset-s3" style={{marginLeft: '35%',marginTop: '10%'}}>
          <div className="card radius404">
            <div className="card-content ">
              <div className="page404">
                    <h4 className="title404">
                      4<span>0</span>4
                    </h4>
                    <p className="caption404 mb-0">
                    We could not find the page you were looking for. You may return to<Link to="/dashboard"> dashboard</Link> and try some other links.
            </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <Footer/> */}
    </>
  );
}

export default NotFound;
