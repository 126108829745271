import axios from "axios";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ApiUrls } from "../../constants/constants";
import { get_platform, setLocalItem } from "../../helpers/common";
import { getDatethMonthYearFormat } from "../../helpers/dateFunctions";
import { gtm_date_time, gtm_event } from "../../helpers/google_tracking";
import Carousel from "./carausel";

const CurrentOffersSection = () => {
  const [currentOfferProducts, setCurrentOfferProducts] = useState([]);
  const authState = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const carauselOptions = {
    items: 4,
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 3,
      },
      1000: {
        items: 4,
      },
    }
  };

  useEffect(() => {
    axios
      .get(ApiUrls.CurrentOfferProducts)
      .then((res) => {
        setCurrentOfferProducts(res.data.data.products);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const buyProduct = (productId, name) => {
    gtm_event({
      event: "tap_BuyNow",
      platform: get_platform(),
      ...gtm_date_time(),
      uid: authState.userId || 0,
      name: authState.name || "",
      mobile: authState.mobile || "",
      product_name: name,
      product_id: productId,
      is_study_material: false
    });
    setLocalItem("productPgPlus", productId);
    navigate("/purchase", { state: { step: 2 } });
  };

  return (
    <>
      {currentOfferProducts.length > "0" && (
        <div className="container CurrentOfferSection">
          <div className="">
            <div className="heading">
              <h1>Current Offers</h1>
            </div>
          </div>
          <div className="row">
            <Carousel
              carouselClass="currentOffersCarausal CurrentOfferSlider"
              showNavigationBtn={false}
              carauselOptions={carauselOptions}
            >
              {currentOfferProducts.map((data, index) => (
                <div key={index} className="CurrentOfferSliderContent">
                  <div className="CurrentOffersBOX">
                    <div className="currentofferinnerbox">
                      <img
                        className="responsive-img"
                        src={
                          process.env.REACT_APP_PUBLIC_URL +
                          `dist/img/current-offer-img/current-offer-img${++index}.png`
                        }
                      />
                      {/* <p><strong>{data["product_title"]}</strong></p>
                     */} 
                      
                    </div>
                    <div className="currentoffercontentbox">
                    <p> 
                        {/* Course for : <br /> */}
                        {data["course_category"]} {data["product_title"]}
                      </p>
                    </div>
                    <div className="CurrntOfferBTNBox">
                      <a
                        className="btn CurrntOfferBTN"
                        onClick={() =>
                          buyProduct(data["id"], data["product_name"])
                        }
                      >
                        Buy Now
                      </a>
                    </div>
                  </div>
                </div>
              ))}
            </Carousel>
          </div>
        </div>
      )}
    </>
  );
};

export default CurrentOffersSection;
