import React, { useEffect, useState } from "react";
import ModalVideo from 'react-modal-video'
import { get_platform } from "../../helpers/common";
import { gtm_date_time, gtm_event } from "../../helpers/google_tracking";
import Carousel from "./carausel";

const VideosSection = () => {
  const [isOpen, setOpen] = useState(false)
  const [videoUrl, setVideoUrl] = useState("")
  const [startPosition, setStartPosition] = useState(0)
  const carauselOptions = { items: 3,
    responsive:{
      0:{
          items:1
      },
      600:{
          items:2
      },
      1000:{
          items:3
      }
  },
  startPosition:startPosition
  };
  
  const hideBodyScroll = () => {
    document.getElementsByTagName("body")[0].style.overflow="hidden"
  }

  const showBodyScroll = () => {
    document.getElementsByTagName("body")[0].style.overflow="scroll"
  }

  const video_gtm_event = () => {
    gtm_event({
      event: "tap_demovideo_business",
      platform: get_platform(),
      ...gtm_date_time(),
      session_start_time: new Date().toLocaleString(), // new Date().toISOString()
      session_end_time: null,
      subject_name: "",
      subject_id: "",
      topic_name: "",
      topic_id: "",
    })
  }

  useEffect(() => { }, [videoUrl])

  useEffect(()=>{
    window.jQuery(document).on('click', '.cloned .first-element', function(){
      video_gtm_event(); 
      setVideoUrl("https://www.youtube.com/embed/zbmNSvN2FQ0?&autoplay=1");
      setStartPosition(7);
      setOpen(true);
      hideBodyScroll();
    })

    window.jQuery(document).on('click', '.cloned .second-element', function(){
      video_gtm_event(); 
      setVideoUrl("https://www.youtube.com/embed/VPvz5JYgGV0?&autoplay=1");
      setStartPosition(7);
      setOpen(true);
      hideBodyScroll();
    })
    
  }, [])

  return (
    <>
      <React.Fragment>
        <ModalVideo channel='custom' autoplay isOpen={isOpen} url={videoUrl} onClose={() => {setOpen(false); showBodyScroll()}} />
      </React.Fragment>

      <div className="container ConceptVideosSection">
        <div className="row mbottomnill" id="videoCarouselDiv">
          <div className="conceptvideosbox">
            <h3>Concept Videos</h3>
            <p>Crisp videos for assured mastery</p>
          </div>
        </div>
        <div className="row">
          <Carousel carouselClass="videoCarausal" showNavigationBtn={true} carauselOptions={carauselOptions} >
            <div className="carousel-item">
              <div className="videosectionimgcontrol conceptvideodivbox1 first-element" onClick={() => {video_gtm_event(); setVideoUrl("https://www.youtube.com/embed/zbmNSvN2FQ0?&autoplay=1"); setStartPosition(0);setOpen(true);hideBodyScroll(); }}>
                <img
                  className="responsive-img"
                  src={process.env.REACT_APP_PUBLIC_URL + "dist/img/youtube_thumbnail/Dr. Ayesha Nikhat.png"}
                />
              </div>
            </div>

            <div className="carousel-item ">
              <div className="videosectionimgcontrol conceptvideodivbox2 second-element" onClick={() => {video_gtm_event(); setVideoUrl("https://www.youtube.com/embed/VPvz5JYgGV0?&autoplay=1"); setStartPosition(0); setOpen(true);hideBodyScroll(); }}>
                <img
                  className="responsive-img"
                  src={process.env.REACT_APP_PUBLIC_URL + "dist/img/youtube_thumbnail/Dr. Garima Anand.png"}
                />
              </div>
            </div>

            <div className="carousel-item ">
              <div className="videosectionimgcontrol conceptvideodivbox3" onClick={() => {video_gtm_event(); setVideoUrl("https://www.youtube.com/embed/aRaprxl74T8?&autoplay=1"); setStartPosition(1);setOpen(true);hideBodyScroll(); }}>
                <img
                  className="responsive-img"
                  src={process.env.REACT_APP_PUBLIC_URL + "dist/img/youtube_thumbnail/Dr. Prachi Jain.png"}
                />
              </div>
            </div>

            <div className="carousel-item ">
              <div className="videosectionimgcontrol conceptvideodivbox1" onClick={() => {video_gtm_event(); setVideoUrl("https://www.youtube.com/embed/RgI7L-cZ9H0?&autoplay=1"); setStartPosition(2);setOpen(true); hideBodyScroll(); }}>
                <img
                  className="responsive-img"
                  src={process.env.REACT_APP_PUBLIC_URL + "dist/img/youtube_thumbnail/Dr. Rahul Chaudhary.png"}
                />
              </div>
            </div>

            <div className="carousel-item ">
              <div className="videosectionimgcontrol conceptvideodivbox2" onClick={() => {video_gtm_event(); setVideoUrl("https://www.youtube.com/embed/AgXzhgEefeg?&autoplay=1"); setStartPosition(3); setOpen(true); hideBodyScroll(); }}>
                <img
                  className="responsive-img"
                  src={process.env.REACT_APP_PUBLIC_URL + "dist/img/youtube_thumbnail/Dr. Rakesh Mittal.png"}
                />
              </div>
            </div>

            <div className="carousel-item ">
              <div className="videosectionimgcontrol conceptvideodivbox2" onClick={() => {video_gtm_event(); setVideoUrl("https://www.youtube.com/embed/TmvUsmogCFE?&autoplay=1"); setStartPosition(4); setOpen(true); hideBodyScroll(); }}>
                <img
                  className="responsive-img"
                  src={process.env.REACT_APP_PUBLIC_URL + "dist/img/youtube_thumbnail/Dr. Aseem.png"}
                />
              </div>
            </div>

            <div className="carousel-item ">
              <div className="videosectionimgcontrol conceptvideodivbox2" onClick={() => {video_gtm_event(); setVideoUrl("https://www.youtube.com/embed/tWOnEaLWDDM?&autoplay=1"); setStartPosition(5); setOpen(true); hideBodyScroll(); }}>
                <img
                  className="responsive-img"
                  src={process.env.REACT_APP_PUBLIC_URL + "dist/img/youtube_thumbnail/Dr. Pratik.png"}
                />
              </div>
            </div>

            <div className="carousel-item ">
              <div className="videosectionimgcontrol conceptvideodivbox2" onClick={() => {video_gtm_event(); setVideoUrl("https://www.youtube.com/embed/Me6RDxKOsKc?&autoplay=1"); setStartPosition(6);setOpen(true); hideBodyScroll(); }}>
                <img
                  className="responsive-img"
                  src={process.env.REACT_APP_PUBLIC_URL + "dist/img/youtube_thumbnail/Dr. Shefali.png"}
                />
              </div>
            </div>

            {/* <div className="carousel-item ">
              <div className="videosectionimgcontrol conceptvideodivbox3" onClick={() => {video_gtm_event(); setVideoUrl("https://www.youtube.com/embed/WYEn4nubJOc?&autoplay=1"); setOpen(true); hideBodyScroll(); }}>
                <img
                  className="responsive-img"
                  src={process.env.REACT_APP_PUBLIC_URL + "dist/img/Group-32.png"}
                />
              </div>
            </div> */}
          </Carousel>
        </div>
      </div>
    </>
  );
};

export default VideosSection;
