import { React, useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import parse from "html-react-parser";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import axios from "axios";

function setItemHeight(ItemCount, uomDivHeightRef,descDivHeightRef) {
  for (var i = 1; i <= ItemCount; i++) {

      console.log({i,current: descDivHeightRef.current[1], div:descDivHeightRef.current[i], descDivHeightRef, uomDivHeightRef})
      var divHeight = descDivHeightRef.current[i].clientHeight + 5;
      var uomDivHeight = uomDivHeightRef.current[i].clientHeight + 5;

    if (uomDivHeight > divHeight) {
      divHeight = uomDivHeight;
    }

//     var row = document.getElementsByClassName("row" + i);
//     for (var j = 1; j <= row.length; j++) {
//       row[j - 1].setAttribute("style", "height:" + divHeight);
//     }
//   }
  }
}

function empty(string) {
  if (
    string == undefined ||
    string.length == 0 ||
    string == "0" ||
    string == 0
  ) {
    return true;
  }
}

function number_format(number, decimal) {
  if (empty(number)) {
    return 0;
  }
  if (
    number == 0 ||
    number == "0.0" ||
    number == "0.00" ||
    number == "00.0" ||
    number == "00.00"
  ) {
    return "0.00";
  }
  number = Number(number).toFixed(2);

  let numArray = number.split(".");
  var resultantNum = Number(numArray[0]).toLocaleString() + "." + numArray[1];
  return resultantNum;
}

const getReceiptDetails = (arrProductObj) => {
  var count = 0;
  var _total = 0;
  var sNo = [];
  var description=[];
 var hsnCode=[];
 var qty=[];
 var uom=[];
 var rate=[];
 var total=[];
 var discount=[];
 var creditAmount=[];
 var taxableValue=[];
 var cgstRate=[];
 var cgstAmount=[];
 var sgstRate=[];
 var sgstAmount=[];
 var igstRate=[];
 var igstAmount=[];
 var rowTotal = [];

  var grandCGST = 0;
  var grandSGST = 0;
  var grandIGST = 0;
  var grandTaxableValue = 0;
  var lastProductVal;
  for (const [key, value] of Object.entries(arrProductObj)) {
    count++;
    value["rate"] = value["rate"] * 1.18;
    sNo.push(count);
    description.push(value["descriptionOfGoods"]);
    hsnCode.push(value["hsnCode"]);
    qty.push(value["qty"]);
    uom.push(value["uom"]);
    rate.push(number_format(value["rate"], 2));
    total.push(number_format(value["total"], 2));
    discount.push(number_format(value["discountAmount"] * -1, 2));
    creditAmount.push(number_format(value["creditAmount"] * -1, 2));
    taxableValue.push(number_format(value["taxableValue"], 2));
    cgstRate.push(number_format(value["CGST"]["rate"], 2));
    cgstAmount.push(number_format(value["CGST"]["amount"], 2));
    sgstRate.push(number_format(value["SGST"]["rate"], 2));
    sgstAmount.push(number_format(value["SGST"]["amount"], 2));
    igstRate.push(number_format(value["IGST"]["rate"], 2));
    igstAmount.push(number_format(value["IGST"]["amount"], 2));

    grandTaxableValue += value["taxableValue"];
    grandCGST += empty(value["CGST"]["amount"]) ? 0 : value["CGST"]["amount"];
    grandSGST += empty(value["SGST"]["amount"]) ? 0 : value["SGST"]["amount"];
    grandIGST += empty(value["IGST"]["amount"]) ? 0 : value["IGST"]["amount"];
    let rowTotalAmount = parseFloat(value["taxableValue"]);
    rowTotalAmount += empty(value["CGST"]["amount"])
      ? 0
      : parseFloat(value["CGST"]["amount"]);
    rowTotalAmount += empty(value["SGST"]["amount"])
      ? 0
      : parseFloat(value["SGST"]["amount"]);
    rowTotalAmount += empty(value["IGST"]["amount"])
      ? 0
      : parseFloat(value["IGST"]["amount"]);

    _total = grandTaxableValue + grandCGST + grandSGST + grandIGST - _total;

    rowTotal.push(number_format(rowTotalAmount, 2));
    lastProductVal = value;
  }
  console.log({igstAmount})
  return {
    count,
    _total,
    sNo,
    description,
    hsnCode,
    qty,
    uom,
    rate,
    total,
    discount,
    creditAmount,
    taxableValue,
    cgstRate,
    cgstAmount,
    sgstRate,
    sgstAmount,
    igstRate,
    igstAmount,
    grandCGST,
    grandSGST,
    grandIGST,
    rowTotal,
    grandTaxableValue,
    lastProductVal,
  };
};

function PurchaseReceipt() {
  const [prod, setProd] = useState({});
  //const [height, setHeight] = useState(0)
  const [receiptData, setReceiptData] = useState({});
  const [paymentReceiptData, setPaymentReceiptData] = useState({});
  const [loading, setLoading] = useState(true)
  const uomDivHeightRef = useRef([]);
  const descDivHeightRef = useRef([]);
  const [objReceiptDetail, setReceiptDetail] = useState({
    count: [],
    _total: [],
    sNo: [],
    description: [],
    hsnCode: [],
    qty: [],
    uom: [],
    rate: [],
    total: [],
    discount: [],
    creditAmount: [],
    taxableValue: [],
    cgstRate: [],
    cgstAmount: [],
    sgstRate: [],
    sgstAmount: [],
    igstRate: [],
    igstAmount: [],
    grandCGST: [],
    grandSGST: [],
    grandIGST: [],
    rowTotal: [],
    grandTaxableValue: [],
    lastProductVal: [],
  });

  const { receiptId } = useParams();
  const { height, width } = useWindowDimensions();
  console.log({receiptId})

  const fetchReceiptDetails = () => {
    axios
      .get(
        `${process.env.REACT_APP_SERVER_URL}purchaseapi/v2/receiptdetails?id=${receiptId}`,
        {
          withCredentials: true,
        }
      )
      .then((responseData) => {
        console.log({ responseData });
        var pobj = responseData.data.data.receipt.json.products;
        let tmpProd = pobj[Object.keys(pobj)[0]];
        // console.log({ prod: tmpProd });
        setProd(tmpProd);
        setReceiptData(responseData.data.data.receipt);
        setPaymentReceiptData(responseData.data.data.PaymentReceipt);

        let receiptDtls = getReceiptDetails(pobj);
        console.log({ receiptDtls });
        setReceiptDetail(receiptDtls);
        setLoading(false)
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // to check if user is already logged in
  useEffect(() => {
    window.resizeTo(width, height);
    fetchReceiptDetails();
    setItemHeight(objReceiptDetail?.count, uomDivHeightRef,descDivHeightRef );
    // need to refresh login state bcz login component is out of protected route
    console.log("refreshing support-login");
  }, [loading]);

  return (
    <>
      <table id="receipt" align="center">
        <tbody>
          <tr>
            <td align="center" colSpan="2">
              <img src="./dist/img/pg_plus1.png" />
            </td>
            <td colSpan="8">
              <center>
                <div
                  style={{
                    fontSize: "16px",
                    fontWeight: "bold",
                    marginBottom: "20px",
                    lineHeight: "22px",
                  }}
                >
                  <div id="receiptType">
                   
                  </div> {receiptData?.receiptTypeId == 5
                      ? "CREDIT NOTE"
                      : "RECEIPT"}
                  <div>Aakash EduTech Private Limited.</div>
                </div>
              </center>
              <div style={{ float: "left" }}>
              Regd Office: Aakash Tower, Plot No 8,Pusa Road, 
              <br />New Delhi-110005, Ph:011-47623456
                <br />
                <div>Corporate Branch-Aakash Live A1/22 Janakpuri</div>
                <div>Delhi Delhi-110058</div>
                <div>{process.env.REACT_APP_PUBLIC_URL.endsWith('/') ? process.env.REACT_APP_PUBLIC_URL.slice(0, -1) : process.env.REACT_APP_PUBLIC_URL}</div>
              </div>
              <div style={{ float: "right" }}>
                GSTIN: 07AADCA3289M1ZB
                <br />
                Company CIN: U99999DL2001PTC110324 <br />
              </div>
            </td>
          </tr>
          <tr>
            <td colSpan="10">
              <div>
                GSTIN Number:{" "}
                <b id="gstinNumber">
                  {!empty(receiptData?.json?.gstinNumber)
                    ? receiptData?.json?.gstinNumber
                    : ""}
                </b>
              </div>
              <div>
                Tax Is Payable On Reverse Charge: <b>No</b>
              </div>
              <div>
                Order Id: <b id="orderId">{receiptData?.json?.orderId}</b>
              </div>
              <div>
                <span id="receiptSerialNoLabel">
                  {receiptData.receiptTypeID == 5
                    ? "Credit Note No"
                    : "Reference No"}
                </span>
                :{" "}
                <b id="receiptSerialNo">{receiptData?.json?.receiptSerialNo}</b>
              </div>
              <div>
                Receipt No :{" "}
                <b id="receiptNo">{receiptData?.json?.receiptNoVal}</b>
              </div>
              <div>
                <span id="receiptDateLabel">
                  {receiptData.receiptTypeID == 5
                    ? "Credit Note Date"
                    : "Receipt Date"}
                </span>
                :<b id="receiptDate">{receiptData?.json?.receiptDateVal}</b>
              </div>
              {receiptData?.receiptTypeID == 5 &&
                paymentReceiptData?.receiptNo && (
                  <div id="originalReceiptNoDiv">
                    Original Receipt No
                    <b id="originalReceiptNo">{paymentReceiptData.receiptNo}</b>
                  </div>
                )}

              {receiptData?.receiptTypeID == 5 &&
                paymentReceiptData?.receiptDate && (
                  <div id="originalReceiptDateDiv">
                    Original Receipt Date
                    <b id="originalReceiptDate">
                      {paymentReceiptData?.receiptDate}
                    </b>
                  </div>
                )}
            </td>
          </tr>
          <tr>
            <td colSpan="10">Details of Receiver (Billed to)</td>
          </tr>
          <tr>
            <td colSpan="10">
              <div>
                Name:
                <b id="billingAddressName">
                  {receiptData?.json?.billingAddress.name}
                </b>
              </div>
              <div>
                Address:
                <b id="billingAddress">
                  {receiptData?.json?.billingAddress?.address}
                </b>
              </div>
              <div>
                State:
                <b id="billingAddressState">
                  {receiptData?.json?.billingAddress?.state}
                </b>
              </div>
              <div>
                State Code:
                <b id="billingAddressStateId">
                  {receiptData?.json?.billingAddress?.stateId}
                </b>
              </div>
              <div>
                GSTIN Number:
                <b id="billingAddressGstInNumber">
                  {receiptData?.json?.billingAddress?.gstinNumber}
                </b>
              </div>
            </td>
          </tr>
          <tr>
            <td colSpan="10">Amount in Rupees</td>
          </tr>
          <tr id="header">
            <td>S.No</td>
            <td width="200">Description of Goods</td>
            <td>HSN/SAC Code</td>
            <td>Qty</td>
            <td width="200">UOM</td>
            <td width="200">Product Price</td>
            <td align="center">CGST ({prod?.CGST?.rate}%)</td>
            <td align="center">SGST ({prod?.SGST?.rate}%)</td>
            <td align="center">IGST ({prod?.IGST?.rate}%)</td>
            <td width="200">Total</td>
          </tr>
          <tr
            id="receiptDetails"
            height="300"
            className="bold"
            style={{ verticalAlign: "top" }}
          >
            <td>
              {objReceiptDetail?.sNo?.map((count, key)=>(
                <div key ={key} className={"row"+count}>
                  {" "}
                  {count}{" "}
                </div>
              ))}
            </td>
            <td width="200">
              {objReceiptDetail?.description?.map((description, key)=>(
                <div ref={el => descDivHeightRef.current[key+1] = el} key ={key} className={"row" + (key + 1)} id={"desc" + (key + 1)}>
                  {" "}
                  {description}{" "}
                </div>
              ))}
            </td>
            <td>
              {objReceiptDetail?.hsnCode?.map(( hsnCode, key)=>(
                <div key ={key} className={"row" + (key + 1)} >
                  {" "}
                  {hsnCode}{" "}
                </div>
              ))}
            </td>
            <td align="center">
              {objReceiptDetail?.qty?.map((qty, key )=>(
                <div key ={key} className={"row" + (key + 1)} >
                  {" "}
                  {qty}{" "}
                </div>
              ))}
            </td>
            <td align="center" width="200">
              {objReceiptDetail?.uom?.map((uom, key)=>(
                <div  ref={el => uomDivHeightRef.current[key+1] = el}  key ={key} className={"row" + (key + 1)} id={"uom" + (key + 1)} >
                  {" "}
                  {parse(uom.replace("To","<br/>To</br>"))}{" "}
                </div>
              ))}
            </td>
            <td align="right" width="200">
              {objReceiptDetail?.rate?.map((rate, key )=>(
                <div key ={key} className={"row" + (key + 1)} >
                  {" "}
                  {rate}{" "}
                </div>
              ))}
            </td>
            <td align="right">
              {objReceiptDetail?.cgstAmount?.map((cgstAmount, key )=>(
                <div key ={key} className={"row" + (key + 1)} >
                  {" "}
                  {cgstAmount}{" "}
                </div>
              ))}
            </td>
            <td align="right">
              {objReceiptDetail?.sgstAmount?.map((sgstAmount, key)=>(
                <div key ={key} className={"row" + (key + 1)} >
                  {" "}
                  {sgstAmount}{" "}
                </div>
              ))}
            </td>
            <td align="right">
              {objReceiptDetail?.igstAmount?.map((igstAmount, key)=>(
                <div  key ={key} className={"row" + (key + 1)} >
                  {" "}
                  {igstAmount}{" "}
                </div>
              ))}
            </td>
            <td align="right" width="200">
              {objReceiptDetail?.rowTotal?.map((rowTotal , key)=>(
                <div key ={key} className={"row" + (key + 1)} >
                  {" "}
                  {rowTotal}{" "}
                </div>
              ))}
            </td>
          </tr>
          <tr>
            <td align="left" colSpan="5">
              <b>Amount Chargeable (in words): </b>
              <span id="amountInWords">{receiptData?.json?.totalInWords}</span>
            </td>
            <td align="right" colSpan="2">
              Total
            </td>
            <td align="right" colSpan="3" id="total">
              {receiptData?.json?.total}
            </td>
          </tr>
          <tr id="dispatchOtherChanges">
            <td colSpan="5" rowSpan="3" valign="top">
              <div style={{ width: "500px", fontWeight: "bold" }}>
                {receiptData?.json?.dispatchDetails && (
                  <div>{receiptData?.json?.dispatchDetails}</div>
                )}

                {receiptData?.json?.tabPrice && (
                  <div>{receiptData?.json?.tabPrice}</div>
                )}
              </div>
            </td>
            <td align="right" colSpan="2">
              Other Charges
            </td>
            <td colSpan="3" align="right">
              {number_format(receiptData?.json?.otherCharges)}
            </td>
          </tr>
          <tr>
            <td align="right" colSpan="2">
              Roundoff Amount
            </td>
            <td align="right" id="adjustedAmount" colSpan="3">
              {number_format(receiptData?.adjustedAmount)}
            </td>
          </tr>
          <tr>
            <td align="right" colSpan="2">
              Total Receipt
            </td>
            <td align="right" colSpan="3">
              <b id="receiptTotal">
                {number_format(receiptData?.json?.receiptTotal)}
              </b>
            </td>
          </tr>
          <tr>
            <td colSpan="5">
              Certified that the particulars given above are true and correct
            </td>
            <td colSpan="5">
              Electronic Reference Number:{" "}
              <b id="electronicRefNo">{receiptData?.json?.electronicRefNo}</b>
            </td>
          </tr>
          <tr>
            <td colSpan="10">
              This is a system generated document and does not require signature
              and stamp.
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );
}

export default PurchaseReceipt;
