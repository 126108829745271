import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ApiUrls } from "../../constants/constants";
import { get_platform, isMobileDevice } from "../../helpers/common";
import { gtm_date_time, gtm_event } from "../../helpers/google_tracking";
import Carousel from "./carausel";

const BannerSection = () => {
  const options = { dots: false, dotsEach: true, items: 1, autoplay: true }
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate()
  const [bannerImages, setBannerImages] = useState({ "desktop_banner": [], "mobile_banner": [] });

  useEffect(() => {
    axios.get(ApiUrls.bannerImagesApi + "?component=desktop_banner,mobile_banner&flow=1")
      .then((res) => {
        if (res.data?.data?.desktop_banner)
          setBannerImages(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });

    gtm_event({
      event: "tap_landing_DM",
      platform: get_platform(),
      ...gtm_date_time(),
      session_start_time: new Date().toLocaleString(), // new Date().toISOString()
      session_end_time: null,
      social_media_name: searchParams.get("utm_source") || "",
      campaign_name: searchParams.get("utm_campaign") || "", // utm_campaign
      campaign_id: searchParams.get("utm_campaignid") || "" // utm_campaign_id
    })
  }, [])

  const getStarted = (name, i) => {
    gtm_event({
      event: "tap_getstarted",
      platform: get_platform(),
      ...gtm_date_time(),
      bannerName: name,
      bannerId: name.split(" ")[1] || ""
    })

    checkBannerClick(i)
  }

  const checkBannerClick = (i) => {
    let obj = isMobileDevice() ? bannerImages?.mobile_banner[i] : bannerImages?.desktop_banner[i]
    if (!obj || !obj.cta) return

    if (obj.cta.indexOf("http") == 0) {
      window.open(obj.cta)
    } else
      return navigate(obj.cta)

    return ""
  }

  return (
    <>
      {bannerImages?.desktop_banner.length && <Carousel carouselClass="bannerCarausal" showNavigationBtn={false} carauselOptions={options}>
        {bannerImages?.desktop_banner.map((obj, i) => (
          <div className="item" key={i} onClick={() => { getStarted("banner " + (i + 1), i) }}>
            <div className="bannerCarausalBox">
            </div>
            <img className="responsive-img DesktopBannerContent" src={obj.value} />
            <img className="responsive-img MobileBannerContent" src={bannerImages?.mobile_banner && bannerImages.mobile_banner[i]?.value} />
          </div>
        ))}
      </Carousel>}
    </>
  );
};

export default BannerSection;
