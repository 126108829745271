import React, { useEffect, useState } from "react";
import ModalVideo from 'react-modal-video'
import { get_platform } from "../../helpers/common";
import { gtm_date_time, gtm_event } from "../../helpers/google_tracking";
import Carousel from "../home/carausel";

const ConceptVideos = () => {
  const [isOpen, setOpen] = useState(false)
  const [videoUrl, setVideoUrl] = useState("")
  const [startPosition, setStartPosition] = useState(0)
  const videoDetails = [
    {
      url: "https://www.youtube.com/embed/TmvUsmogCFE?&autoplay=1",
      name: "CVA-Brainstem Disorders",
      category: "Medicine"
    },
    {
      url: "https://www.youtube.com/embed/tWOnEaLWDDM?&autoplay=1",
      name: "Immunobullous Disorders of Skin",
      category: "Dermatology"
    },
    {
      url: "https://www.youtube.com/embed/Me6RDxKOsKc?&autoplay=1",
      name: "Placenta & its Formation",
      category: "Obs Gyn"
    },
    {
      url: "https://www.youtube.com/embed/zbmNSvN2FQ0?&autoplay=1",
      name: "Cardiac Cycle & JVP",
      category: "Physiology"
    },
    {
      url: "https://www.youtube.com/embed/AgXzhgEefeg?&autoplay=1",
      name: "Gastrointestinal Surgery",
      category: "Surgery"
    },
    {
      url: "https://www.youtube.com/embed/VPvz5JYgGV0?&autoplay=1",
      name: "Images in Kidney",
      category: "Pathology"
    },
    {
      url: "https://www.youtube.com/embed/aRaprxl74T8?&autoplay=1",
      name: "Granulomatous Disorders of Nose & Facial Trauma",
      category: "ENT"
    },
    {
      url: "https://www.youtube.com/embed/RgI7L-cZ9H0?&autoplay=1",
      name: "Hypertension & Ischemic Heart Diseases",
      category: "Medicine"
    },
  ];
  const carauselOptions = {
    items: 3,
    responsive: {
      0: {
        items: 2
      },
      600: {
        items: 3
      },
      991: {
        items: 3
      }
    },
    autoplay: true,
    dots: true,
    startPosition: startPosition,
    nav: true,
    navText: [
      `<div class='nav-btn prev-slide'>
        <a class="prev btn-floating btn sliderarrowcust">
          <i class="material-icons">chevron_right</i>
        </a>
      </div> `,
      `<div class='nav-btn next-slide'>
        <a class="next btn-floating btn sliderarrowcust">
          <i class="material-icons">chevron_left</i>
        </a>
      </div>`],
  };

  const hideBodyScroll = () => {
    document.getElementsByTagName("body")[0].style.overflow = "hidden"
  }

  const showBodyScroll = () => {
    document.getElementsByTagName("body")[0].style.overflow = "scroll"
  }

  const video_gtm_event = () => {
    gtm_event({
      event: "tap_demovideo_business",
      platform: get_platform(),
      ...gtm_date_time(),
      session_start_time: new Date().toLocaleString(), // new Date().toISOString()
      session_end_time: null,
      subject_name: "",
      subject_id: "",
      topic_name: "",
      topic_id: "",
    })
  }

  useEffect(() => { }, [videoUrl])

  useEffect(() => {
    window.jQuery(document).on('click', '.cloned .first-element', function () {
      video_gtm_event();
      setVideoUrl(videoDetails[0].url);
      setStartPosition(7);
      setOpen(true);
      hideBodyScroll();
    })

    window.jQuery(document).on('click', '.cloned .second-element', function () {
      video_gtm_event();
      setVideoUrl(videoDetails[1].url);
      setStartPosition(7);
      setOpen(true);
      hideBodyScroll();
    })

  }, [])

  return (
    <>
      <React.Fragment>
        <ModalVideo channel='custom' autoplay isOpen={isOpen} url={videoUrl} onClose={() => { setOpen(false); showBodyScroll() }} />
      </React.Fragment>

      <div className="container">
        <div className="row mbottomnill" id="videoCarouselDiv">
          <div className="conceptvideosbox">
            <p>Top concept videos for you </p>
          </div>
        </div>
        <div className="row">
          <Carousel carouselClass="videoCarausal" showNavigationBtn={true} carauselOptions={carauselOptions} >
            {videoDetails.map((v, i) => (
              <div className="carousel-item" key={i}>
                <div className="videosectionimgcontrol conceptvideodivbox1 first-element" onClick={() => { video_gtm_event(); setVideoUrl(v.url); setStartPosition(i <= 1 ? 0 : i - 1); setOpen(true); hideBodyScroll(); }}>
                  <div className="img-block">
                    <img
                      className="responsive-img"
                      src={`/pgplus/dist/img/dashboard/video_thumbnail/video-${i+1}.jpg`}
                    />
                  </div>
                  <div className="content">
                    <div className="category">{v.category}</div>
                    <div className="name">{v.name}</div>
                  </div>
                </div>
              </div>
            ))}
          </Carousel>
        </div>
      </div>
    </>
  );
};

export default ConceptVideos;
