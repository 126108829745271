import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ApiUrls } from "../../constants/constants";
import { get_platform } from "../../helpers/common";
import { calculateValidityInMonths } from "../../helpers/dateFunctions";
import { gtm_date_time, gtm_event } from "../../helpers/google_tracking";

const ProductListSection = () => {
  const [productList, setProductList] = useState([]);
  const [productCategories, setProductCategories] = useState({});
  const navigate = useNavigate();
  let categoryCounter = 1;

  const offer_gtm_event = (opt = {}) => {
    gtm_event({
      event: "tap_kmowmore_business",
      platform: get_platform(),
      ...gtm_date_time(),
      ...opt
    })
  }

  useEffect(() => {
    axios
      .get(ApiUrls.HomePageOfferProducts)
      .then((res) => {
        let productCategories = {};
        setProductList(res.data.data.products);
        if (
          res.data.data.products &&
          typeof res.data.data.products === "object"
        ) {
          let productData = res.data.data.products;
          Object.keys(productData).map((index) => {
            if (
              productCategories[productData[index]["category_id"]] === undefined
            ) {
              let newCat = {};
              newCat["category_id"] = productData[index]["category_id"];
              newCat["course_category"] = productData[index]["course_category"];
              productCategories[productData[index]["category_id"]] = newCat;
            }
          });
          setProductCategories(productCategories);
        }
        setProductCategories(productCategories);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <div className="container AllPackageSection">
      <div className="row mbottomnill">
        <div className="AllPackageHeading">
          <h3>Select your all-in-one package</h3>
        </div>
      </div>
      
      <div className="AllPackageBOX">
        
        {productCategories &&
          Object.keys(productCategories).length > "0" &&
          Object.keys(productCategories).map((categoryIndex) => (
            <div key={categoryIndex} className="AllPackageContentBox">
            <div className="AllPackagesInnerBox">
              <div  className={"allpackagebox"}>
                <div
                  className={
                    categoryCounter > 4
                      ? "allpackagetable1"
                      : "allpackagetable" + categoryCounter++
                  }
                >
                  <h3>{productCategories[categoryIndex]["course_category"]}</h3>
                </div>
                <div className="allpackageboxinner2">
                  <div>
                    <table className="striped">
                      <tbody>
                        {productList &&
                          Object.keys(productList).length >= 1 &&
                          Object.keys(productList).map((productListIndex) => {
                            return (
                              productList[productListIndex]["category_id"] ==
                                productCategories[categoryIndex][
                                  "category_id"
                                ] && (
                                <tr key={productListIndex}>
                                  <td>
                                    <p>
                                      <label>
                                        {/* <input type="checkbox" /> */}
                                        <span>
                                          {
                                            productList[productListIndex][
                                              "product_name"
                                            ]
                                          }
                                          {
                                            " ("+productList[productListIndex][
                                              "product_title"
                                            ]+")"
                                          }
                                        </span>
                                      </label>
                                    </p>
                                  </td>
                                  <td className="price-width">
                                    <p>
                                      <strong>₹</strong>{" "}
                                      {productList[productListIndex][
                                        "is_dispatchable"
                                      ] == "0"
                                        ? productList[productListIndex][
                                            "validity"
                                          ] > 0
                                          ? Math.round(
                                              productList[productListIndex][
                                                "price_with_tax"
                                              ] /
                                                productList[productListIndex][
                                                  "validity"
                                                ]
                                            ).toFixed(0) + " /m"
                                          : "expired"
                                        : Math.round(
                                            productList[productListIndex][
                                              "price_with_tax"
                                            ]
                                          )}
                                      <br />
                                      {productList[productListIndex][
                                                "is_dispatchable"
                                              ] == "0" ?
                                      <span>
                                        Fee ₹
                                        {productList[productListIndex]["price_with_tax"]}
                                      </span>
                                        :""}
                                    </p>
                                  </td>
                                </tr>
                              )
                            );
                          })}
                      </tbody>
                    </table>
                    <div className="AllPackageBTNBox">
                      <a
                        className="btn AllPackageBTN"
                        onClick={() => {
                          offer_gtm_event({
                            offer_name: productCategories[categoryIndex]["course_category"],
                            offer_id: productCategories[categoryIndex]["category_id"],
                            product_id: "",
                            product_name: ""
                          })
                          navigate(`/purchase?category=${productCategories[categoryIndex]["category_id"]}`)
                        }}
                      >
                        Know More
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              </div>
            </div>
          ))}
      </div>
      
    </div>
  );
};
export default ProductListSection;
