import { ApiUrls, FacultyDetails } from "../../constants/constants";
import Footer from "../layout/footer";
import HomeHeader from "../layout/homeHeader";
import StaticPagesLayout from "./staticPagesLayout";
import parse from "html-react-parser";
import { useEffect, useState } from "react";
import axios from "axios";

function Ourfaculty() {

  const [faculties, setFaculties] = useState([]);
  useEffect(() => {
    axios.get(ApiUrls.bannerImagesApi+"?component=faculty&flow=1")
    .then((res) => {
      if(res.data?.data?.faculty)
      setFaculties(res.data.data.faculty);
    })
    .catch((err) => {
      console.log(err);
    });
  }, [])

  return (
    <>
      <HomeHeader />
      <div className="container-fluid bgwhite">
        <div className="container">
          <div className="row">
            <div className="FaqHeading">
              <h1>Experienced Faculty</h1>
            </div>
          </div>
        </div>
        {faculties.map((obj,index) => (
          <div key={index} className={index%2==0?"FacultyBgWhite":"FacultyBgGray"}>
            <div className="container">
              <div className="row mbottomnill">
                <div className="col s12 m12">
                  <div className="FacultyContentBox">
                    <div className="FacultyContentIMG">
                      <img
                        className="responsive-img"
                        src={obj.profileImage}
                      />
                    </div>
                    <div className="FacultyContent">
                      <p>
                        <strong>{obj.name}</strong>
                        <span className="facultyQualification">
                          {obj.qualification}
                        </span>
                        <span className="facultyQualification">
                          {obj.qualification2}
                        </span>
                        {/* <span className="facultyQualification">
                          {obj.Qualification3}
                        </span> */}
                      </p>
                      <p>{parse(obj.details)}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
      {!window.IS_MOBILE_APP && <Footer />}
    </>
  );
}

export default Ourfaculty;
