import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import ConceptVideos from "./dashboard/conceptVideos";
import DownloadAppDashboard from "./dashboard/downloadAppDashboard";
import WeOfferSection from "./dashboard/weOfferSection";
import PremiumOffersSection from "./dashboard/premiumOffersSection";
import OurFaculty from "./dashboard/ourFaculty";
import DashboardHeader from "./layout/dashboardHeader";

import { Spinner } from "./common/common";
import axios from "axios";

function Dashboard() {
  const authState = useSelector((state) => state.auth);
  const navigate = useNavigate()
  const [headerNote, setHeaderNote] = useState("");
  const [userType, setUserType] = useState("")

  useEffect(() => {
    if (authState.isLoggedIn == 0) {
      navigate("/login")
    } else if (authState.isLoggedIn) {
      // Change body background color for dashboard pages
      document.body.style.backgroundColor = "#fff";

      const apiUrl = `purchaseapi/v1/orderSearch?filters=user_id:${authState.userId}|product_active:-1`
      axios.get(`${process.env.REACT_APP_SERVER_URL}${apiUrl}`, { withCredentials: true }).then((response) => {
        if (response.data.status && response.data.status == 1 && Array.isArray(response.data.data.orders) && response.data.data.orders.length) {
          let subsExpireIn = 0
          for (let i = 0; i < response.data.data.orders.length; i++) {
            const row = response.data.data.orders[i]
            const orderExpire = (new Date(row.product_end_date).getTime() - new Date().getTime()) / (86400000)
            subsExpireIn = orderExpire > subsExpireIn ? orderExpire : subsExpireIn
          }

          if (subsExpireIn <= 10 && subsExpireIn > 0) {
            setUserType("paidAboutToExpire")
            setHeaderNote(`Ohh! Premium plan expiring in ${parseInt(subsExpireIn)} day(s). Hurry up! renew your plan today.`)
          } else if (subsExpireIn <= 0) { // Need to check this case // need to change it to error
            setUserType("paidExpired")
            setHeaderNote(`Hurry Up ! Renew you plan to continue watching premium content on app.`)
          } else {
            setUserType("paid")
          }
        } else {
          const daysAfterSignUp = (new Date().getTime() - new Date(authState.profileData.created).getTime()) / (86400000)
          if (daysAfterSignUp <= 10) {
            setUserType("freeTrial")
            setHeaderNote("Free trial expiring soon. Get a premium plan. Hurry Up !")
          } else {
            setUserType("freeTrialExpired")
            setHeaderNote("Get a plan to continue watching premium content on the Aakash Neet pg plus app.")
          }
        }
      }).catch((err) => {
        setHeaderNote("Free trial expiring soon. Get a premium plan. Hurry Up !")
        setUserType("freeTrial")
        console.log("error in getting user orders", err)
      });
    }

  }, [authState.isLoggedIn])

  return (
    <>
      {authState.isLoggedIn == 1 && (
        <div className="dashboard">
          <DashboardHeader />

          {headerNote &&
            <div className={"header-note " + (userType == "freeTrial" ? "success" : "warning")}>
              <div className="header-note-text" style={{ display: "inherit", cursor: "pointer" }} onClick={() => { navigate("/purchase"); }}>{headerNote}
                <span className="arrow">
                  <i className="material-icons">chevron_left</i>
                </span>
              </div>
            </div>
          }

          {userType == 'paid' ?
            <div className="download-aakash-section-top paid-user">
              <DownloadAppDashboard key={"paid-user"} userType={userType} />
            </div>
            : ""
          }

          <div className="container">
            <div className="welcome-section">
              <p>Welcome,</p> <h4>{authState.name}</h4>
            </div>
          </div>

          <ConceptVideos />
          <WeOfferSection />
          <DownloadAppDashboard key={"all-user"} userType={userType} />

          {userType != 'paid' && <PremiumOffersSection />}

          <OurFaculty />

          {userType != 'paid' &&
            <div className="download-aakash-section-bottom">
              <DownloadAppDashboard key={"not-paid-bottom"} userType={userType} />
            </div>
          }
        </div >
      )}

      {authState.isLoggedIn == 0 && <Spinner />}
    </>
  );
}

export default Dashboard;
