import axios from "axios";
import { useEffect, useState } from "react";
import { ApiUrls, FacultyDetails } from "../../constants/constants";
import Carousel from "../home/carausel";

const OurFaculty = () => {
  const carauselOptions = {
    items: 4,
    responsive: {
      0: {
        items: 2,
      },
      600: {
        items: 3,
      },
      1000: {
        items: 4,
      },
    },
    nav: true,
    autoplay: true,
    dots: true,
    navText: [
      `<div class='nav-btn prev-slide'>
      <a class="prev btn-floating btn sliderarrowcust">
        <i class="material-icons">chevron_right</i>
      </a>
    </div> `,
      `<div class='nav-btn next-slide'>
      <a class="next btn-floating btn sliderarrowcust">
        <i class="material-icons">chevron_left</i>
      </a>
    </div>`],
    loop: false,
  };

  useEffect(() => {
    // axios.get(ApiUrls.bannerImagesApi+"?component=faculty&flow=1")
    // .then((res) => {
    //   if(res.data?.data?.faculty)
    //   setFaculties(res.data.data.faculty);
    // })
    // .catch((err) => {
    //   console.log(err);
    // });
  }, [])

  return (
    (FacultyDetails && FacultyDetails.length && <div className="container ExperienceFacultySection">
      <div className="row mbottomnill">
        <div className="experiencedbox">
          <p>Meet our top faculty</p>
        </div>
        <Carousel
          carouselClass="facultyCarausal"
          showNavigationBtn={true}
          carauselOptions={carauselOptions}
        >
          {FacultyDetails.map((obj, index) => (
            <div key={index} className="item">
              <div className="experiencedcontentbox">
                <div className="experiencedimgcircle">
                  <img
                    className="responsive-img"
                    // src={"/pgplus/dist/img/dashboard/faculty/faculty.jpg"}
                    src={`/pgplus/dist/img/dashboard/faculty/${obj.Image}`}
                  />
                </div>
                <div className="experiencedcontentpr">
                  <p>
                    <strong>{obj.Name}</strong>
                  </p>
                  <p>
                    <span className="facultyQualificationHome">{obj.Qualification}</span>
                    <span className="facultyQualificationHome">{obj.Qualification2}</span>
                  </p>
                </div>
              </div>
            </div>
          ))}
        </Carousel>
      </div>
    </div>)
  );
};

export default OurFaculty;
