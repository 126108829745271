import StaticPagesLayout from "./staticPagesLayout";

function AboutAakash() {
  return (
    <StaticPagesLayout>
      <div className="row mbottomnill">
        <div className="AboutAakashHeading">
          <h1>About Aakash</h1>
        </div>
      </div>
      <div className="row">
        <div className="AboutAakashMainDiv">
          <p>
            Aakash - an established name in JEE and NEET preparation, has been
            guiding students to success since 1988. With a legacy of over 32
            years, 200+ centers across India, 2200+ expert faculty and 2.5 lakh+
            happy students, Aakash is well known for its comprehensive,
            result-oriented JEE and NEET preparation programs.
          </p>
        </div>
      </div>
      <div className="row HideMobile">
        <div className="col s12 m12 center-align">
          <img
            className="responsive-img"
            src={
              process.env.REACT_APP_PUBLIC_URL + "dist/img/about-aakash-img.png"
            }
          />
        </div>
      </div>
      <div className="row ShowMobile">
        <div className="col s12 m12 center-align">
          <img
            className="responsive-img"
            src={
              process.env.REACT_APP_PUBLIC_URL +
              "dist/img/about-aakash-imgmob.png"
            }
          />
        </div>
      </div>
      <div className="row">
        <div className="AboutAakashImgHeading center-align">
          <img
            className="responsive-img"
            src={
              process.env.REACT_APP_PUBLIC_URL +
              "dist/img/about-aakash-heading.png"
            }
          />
        </div>
      </div>
      <div className="row">
        <div className="">
          <div className="col s12 m12 AboutAakashOverviewDiv">
            <div className="col s12 m7">
              <div className="AboutOverviewContent">
                <p>
                  <strong>BYJU’S -</strong>India’s largest ed-tech company and a world leader in technology based learning solutions, now joins hands with Aakash, to give you an unmatched learning experience with an unbeatable edge.
                </p>
                <p>
                  With BYJU’S sharing our values and vision, we are excited to continue our efforts in improving our existing offering to students, by leveraging our strengths and delivering increased value and working in the direction of launching innovative products and courses. Our core objective continues to be to help you succeed and to bring you a learning experience that helps you achieve your academic goals.
                </p>
              </div>
            </div>
            <div className="col s12 m5">
              <div className="AboutOverviewImg">
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="AboutAakashHeadingLeg">
          <h2>
            Continuing a legacy of 33+ years in producing
            <br /> world class doctors all across the nation
          </h2>
        </div>
        <div className="AboutAakashSubHeadingLeg">
          <p>After excelling in Neet UG, Aakash Byju's has brought to you the most awaited Neet PG preparation plans, AAKASH PG PLUS - Your Gateway to Medical PG Prep.</p>
          <p>A team of top qualified medical scholars from AIIMS, MAMC, UCMS, PGI Safdarjung etc. to help you enhance your Neet PG/INICET & other medical exam preparations with flying colors.</p>
        </div>
      </div>

      <div className="row">
        <div className="col s12 m12">
          <div className="col s6 m3">
            <div className="OverviewImgMainBox1">
              <div className="OverviewImgInnerBox1">
                <img
                  className="responsive-img"
                  src={
                    process.env.REACT_APP_PUBLIC_URL + "dist/img/about-us/Pic 10.png"
                  }
                />
              </div>
            </div>
          </div>

          <div className="col s6 m3">
            <div className="OverviewImgMainBox2">
              <div className="OverviewImgInnerBox2">
                <img
                  className="responsive-img"
                  src={
                    process.env.REACT_APP_PUBLIC_URL + "dist/img/about-us/Pic 11.png"
                  }
                />
              </div>
            </div>
          </div>

          <div className="col s6 m3">
            <div className="OverviewImgMainBox1">
              <div className="OverviewImgInnerBox1">
                <img
                  className="responsive-img"
                  src={
                    process.env.REACT_APP_PUBLIC_URL + "dist/img/about-us/Pic 14.png"
                  }
                />
              </div>
            </div>
          </div>

          <div className="col s6 m3">
            <div className="OverviewImgMainBox2">
              <div className="OverviewImgInnerBox2">
                <img
                  className="responsive-img"
                  src={
                    process.env.REACT_APP_PUBLIC_URL + "dist/img/about-us/Pic 15.png"
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </StaticPagesLayout>
  );
}

export default AboutAakash;
