import { useEffect } from "react";
import { useSelector } from "react-redux";
import { ApiUrls } from "../../../constants/constants";

const InstantPayuCheckout = (props) => {
  const orderResponse = props.orderResponse;
  const authState = useSelector((state) => state.auth);

  useEffect(() => {
    setTimeout("document.productcheckout.submit();", 100);
  }, []);

  return (
    <>
      <form
        name="productcheckout"
        action={orderResponse.redirecturl}
        method="post"
      >
        <input type="hidden" name="key" value={orderResponse.merchantid} />
        <input type="hidden" name="hash" value={orderResponse.checksum} />
        <input type="hidden" name="txnid" value={orderResponse.orderid} />
        <input type="hidden" name="amount" value={orderResponse.amount} />
        <input type="hidden" name="firstname" value={orderResponse.fName} />
        <input type="hidden" name="phone" value={orderResponse.mobile10} />
        <input type="hidden" name="email" value={orderResponse.email} />
        <input type="hidden" name="productinfo" value={"online study course"} />
        <input type="hidden" name="surl" value={ApiUrls.PGResponse} />
        <input type="hidden" name="furl" value={ApiUrls.PGResponse} />
        <input type="hidden" name="curl" value={ApiUrls.PGResponse} />
        {/* <input type="hidden" name="bankcode" value="" /> */}
        <input type="hidden" name="pg" value="" />
      </form>

      <div className="container-fluid">
        <div className="row PaymentSuccPageHeight">
          <div className="col s12 m12">
            <div className="PaymentSuccess">
              <div className="Paymentprocform">
                <div className="PaymentProcessing">
                  <div className="preloader-wrapper big active">
                    <div className="spinner-layer spinner-blue-only">
                      <div className="circle-clipper left">
                        <div className="circle"></div>
                      </div>
                      <div className="gap-patch">
                        <div className="circle"></div>
                      </div>
                      <div className="circle-clipper right">
                        <div className="circle"></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <form className="col s12">
                    <div className="row">
                      <div className="col s12">
                        <div className="PaymentProsubheading">
                          <p>
                            Processing...! You are being redirected to payment
                            gateway.
                          </p>
                          <p>
                            <span>
                              [ Please do not close/refresh this window ]
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default InstantPayuCheckout;
