import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { webEngageUserLogout } from "../../helpers/google_tracking";
import { logout } from "../../store/authenticate";

const Logout = () => {

  const navigate = useNavigate()
  const dispatch = useDispatch()
  const authState = useSelector((state) => state.auth);

  useEffect(() => {
    if (authState.isLoggedIn == 0) {
      navigate("/login");
    } else {
      dispatch(logout());
      webEngageUserLogout()
    }
  }, [authState.isLoggedIn])

  return ""
}

export default Logout