import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { authActions, isUserLoggedIn, logout } from "../../store/authenticate";
import M from "materialize-css";
import { useEffect, useState } from "react";
import { get_platform, scrollToSection } from "../../helpers/common";
import { gtm_date_time, gtm_event, webEngageEvent, webEngageUserLogout } from "../../helpers/google_tracking";

function HomeHeader(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const authState = useSelector((state) => state.auth);
  const [showLoginBtn, setShowLoginBtn] = useState(true);
  const location = useLocation();

  //to logout user call logout api
  const logOut = () => {
    dispatch(logout());
    webEngageEvent({
      event: "TAP_LOGOUT",
      platform: get_platform(),
      ...gtm_date_time(),
      uid: authState.userId || 0,
      mobile: authState.mobile || "",
    });

    webEngageUserLogout()
    if (window.IS_MOBILE_APP) {
      if (window.APP_LOGOUT_URL) window.location.href = decodeURIComponent(window.APP_LOGOUT_URL)
      else {
        if (typeof window.WebView != "undefined") window.WebView.setPreference("logout", "true");

        navigate("/login");
      }
    } else
      window.location.href = `${process.env.REACT_APP_TRIBYTE_DOMAIN}/logout?ts=${new Date().getTime()}`;
  };

  const goBack = () => {
    if (window.APP_LOGIN_SUCCESS_URL)
      window.location.href = window.APP_LOGIN_SUCCESS_URL + "?origin=" + (window.APP_ORIGIN || "")
  }

  const initProfileSubMenu = () => {
    if(window.IS_MOBILE_APP) return

    var elems = document.querySelectorAll(".sidenav");
    M.Sidenav.init(elems);
    let elem = document.querySelector(".dropdown-trigger");
    M.Dropdown.init(elem);
  };

  useEffect(() => {
    dispatch(isUserLoggedIn());
  }, [])

  useEffect(() => {
    setShowLoginBtn(!(location.pathname.indexOf("/login") == 0));
    initProfileSubMenu();
  });

  return (
    <>
      <div className="container-fluid bgwhite headersticky">
        <div className="MobileContainer">
          <nav>
            <div className="nav-wrapper">
              <div className="FlexHeaderContainer">
                {window.IS_MOBILE_APP && <div onClick={goBack} className="FlexHeaderContainerLeft go-back-header">
                  <i className="material-icons ">arrow_back</i>
                </div>}

                {/* Hamburger icon */}
                {!window.IS_MOBILE_APP && <div href="#" data-target="mobile-demo"
                  className="sidenav-trigger ShowMobile MobileMenuIcon DisplayIBMobile"
                ></div>}

                <div className="FlexHeaderContainerLeft">
                  <div onClick={() => navigate(window.IS_MOBILE_APP && !authState.isLoggedIn ? "/login" : "/")}
                    className="brand-logo"
                  ></div>
                </div>

                {!window.IS_MOBILE_APP && <div className="FlexHeaderContainerMiddle HideMobile">
                  <ul className="textaligncntr">
                    <li>
                      <Link
                        onClick={() => {
                          scrollToSection();
                          gtm_event({
                            event: "tap_about_us",
                            platform: get_platform(),
                            ...gtm_date_time(),
                          });
                        }}
                        to="/about-us"
                      >
                        About Us
                      </Link>
                    </li>
                    <li>
                      {" "}
                      <Link
                        onClick={() => {
                          scrollToSection();
                          gtm_event({
                            event: "tap_our_faculty",
                            platform: get_platform(),
                            ...gtm_date_time(),
                          });
                        }}
                        to="/our-faculty"
                      >
                        Faculty
                      </Link>
                    </li>
                    <li>
                      {/*2 conditions - 
                        when user is in same url and click on plans(reset state)
                        when user is in different url and click on plans(reload component using href)
                        */}
                      {props.hasOwnProperty("setStep") === true && (
                        <a onClick={() => props.setStep(1)}>Plans</a>
                      )}
                      {props.hasOwnProperty("setStep") === false && (
                        <a
                          onClick={() => {
                            scrollToSection();
                            gtm_event({
                              event: "tap_plans_business",
                              platform: get_platform(),
                              ...gtm_date_time(),
                            });
                            webEngageEvent({
                              event: "PLANS_LAND",
                              platform: get_platform(),
                              ...gtm_date_time(),
                              uid: authState.userId || 0
                            });
                            navigate("/purchase");
                          }}
                        >
                          Plans
                        </a>
                      )}
                    </li>
                  </ul>
                </div>}

                <div className="FlexHeaderContainerRight">
                  <ul className="right RightUL">
                    {authState.isLoggedIn == 0 && !window.IS_MOBILE_APP && (
                      <li className="HideMobile">
                        <a
                          onClick={() => {
                            gtm_event({
                              event: "tap_downloadapp_business",
                              platform: get_platform(),
                              ...gtm_date_time(),
                            });
                          }}
                          href={
                            "https://play.google.com/store/apps/details?id=com.tribyte.aakashpgplus"
                          }
                          target="_blank"
                          className="btn bgchangewhite"
                        >
                          <img
                            className="responsive-img"
                            src={
                              process.env.REACT_APP_PUBLIC_URL +
                              "dist/img/google-play-btn.png"
                            }
                            alt="PG Plus"
                            title="Google Play"
                          />
                        </a>
                      </li>
                    )}
                    <li className="HideMobile">
                    <a
                          onClick={() => {
                            gtm_event({
                              event: "tap_downloadapp_business",
                              platform: get_platform(),
                              ...gtm_date_time(),
                            });
                          }}
                          href={
                            "https://itunes.apple.com/in/app/aakash-pg-plus/id1660895666"
                          }
                          target="_blank"
                          className="btn bgchangewhite"
                        >
                          <img
                            className="responsive-img"
                            src={
                              process.env.REACT_APP_PUBLIC_URL +
                              "dist/img/app-store.png"
                            }
                            alt="PG Plus"
                            title="App Store"
                          />
                        </a>
                    </li>
                    <li className="center-align">
                      {/*2 conditions - 
                        when user is in same url and click on login(reset state)
                        when user is in different url and click on login(reload component using href)
                        */}
                      {authState.isLoggedIn == 0 &&
                        props.hasOwnProperty("setUserMobile") === true &&
                        showLoginBtn && (
                          <Link
                            to={"/login"}
                            onClick={() => {
                              gtm_event({
                                event: "tap_login_business",
                                ...gtm_date_time(),
                                platform: get_platform()
                              })
                              props.setUserMobile("");
                              dispatch(authActions.logoutState());
                            }}
                            className="btn navtextcolo"
                          >
                            Login / Signup
                          </Link>
                        )}
                      {authState.isLoggedIn == 0 &&
                        props.hasOwnProperty("setUserMobile") === false &&
                        showLoginBtn && (
                        <Link to={"/login"}
                          onClick={() => {
                            gtm_event({
                              event: "tap_login_business",
                              ...gtm_date_time(),
                              platform: get_platform()
                            })
                          }}
                          className="btn navtextcolo">
                          Login / Signup
                        </Link>
                        )}
                      {authState.isLoggedIn == "1" && (
                        <>
                          <a
                            className="dropdown-trigger btn LoginLogout"
                            
                            data-target="dropdown1"
                          >
                            {/* <img className="responsive-img1" src={process.env.REACT_APP_PUBLIC_URL + "dist/img/user-profile.png"} alt="User Profile" title="User Profile"/> */}
                            <i className="material-icons">person</i>
                          </a>

                          {!window.IS_MOBILE_APP && <ul id="dropdown1" className="dropdown-content">
                            <li>
                              <a
                                onClick={() => {
                                  logOut();
                                }}
                                href="#!"
                              >
                                Logout
                              </a>
                            </li>
                          </ul>}
                        </>
                      )}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </nav>

          {/* Mobile hanburger menu start */}
          <ul className="sidenav show-on-small" id="mobile-demo">
            <li>
              <a className="sidenav-close SidenavClose" href="#!">
                <img
                  className="responsive-img"
                  src={
                    process.env.REACT_APP_PUBLIC_URL +
                    "dist/img/mobilemenuclose.png"
                  }
                  alt="Close"
                  title="Close"
                />
              </a>
            </li>
            <li>
              <Link to="/about-us">About Us</Link>
            </li>
            <li>
              <Link to="/our-faculty">Faculty</Link>
            </li>
            <li>
              {/*2 conditions - 
                        when user is in same url and click on plans(reset state)
                        when user is in different url and click on plans(reload component using href)
                        */}
              {props.hasOwnProperty("setStep") === true && (
                <a onClick={() => props.setStep(1)}>Plans</a>
              )}
              {props.hasOwnProperty("setStep") === false && (
                <a
                  onClick={() => {
                    gtm_event({
                      event: "tap_plans_business",
                      platform: get_platform(),
                      ...gtm_date_time(),
                    });
                    webEngageEvent({
                      event: "PLANS_LAND",
                      platform: get_platform(),
                      ...gtm_date_time(),
                      uid: authState.userId || 0
                    });
                    navigate("/purchase");
                  }}
                >
                  Plans
                </a>
              )}
            </li>
            <div className="SidenavDownloadDiv">
              <p>
                Download <span>Aakash NEET PG Plus</span> App
              </p>
              <div className="SidenavDownloadIcons">
                <a
                  onClick={() => {
                    gtm_event({
                      event: "tap_downloadapp_business",
                      platform: get_platform(),
                      ...gtm_date_time(),
                    });
                  }}
                  href={
                    "https://play.google.com/store/apps/details?id=com.tribyte.aakashpgplus"
                  }
                  target="_blank"
                  className=" btn bgchangewhite "
                >
                  <img
                    className="responsive-img"
                    src={
                      process.env.REACT_APP_PUBLIC_URL +
                      "dist/img/google-play-btn.png"
                    }
                    alt="PG Plus"
                    title="Google Play"
                  />
                </a>
              </div>
              <div className="SidenavDownloadIcons">
                <a
                  onClick={() => {
                    gtm_event({
                      event: "tap_downloadapp_business",
                      platform: get_platform(),
                      ...gtm_date_time(),
                    });
                  }}
                  href={
                    "https://itunes.apple.com/in/app/aakash-pg-plus/id1660895666"
                  }
                  target="_blank"
                  className=" btn bgchangewhite "
                >
                  <img
                    className="responsive-img"
                    src={
                      process.env.REACT_APP_PUBLIC_URL +
                      "dist/img/app-store.png"
                    }
                    alt="PG Plus"
                    title="App Store"
                  />
                </a>
              </div>
            </div>
          </ul>
          {/* Mobile hamburger menu end */}
        </div>
      </div>
    </>
  );
}

export default HomeHeader;
