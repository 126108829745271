import Footer from "../layout/footer";
import BannerSection from "../home/bannerSection";
import CurrentOffersSection from "../home/currentOffersSection";
import DownloadAppSection from "../home/downloadAppSection";
import FacultySection from "../home/facultySection";
import GatewayMedicalSection from "../home/gatewayMedicalSection";
import HomeHeader from "../layout/homeHeader";
import OurOfferingsSection from "../home/ourOfferingsSection";
import ProductListSection from "../home/productListSection";
import VideosSection from "../home/videosSection";
import SocialWidget from "../home/socialWidget";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { isUserLoggedIn } from "../../store/authenticate";
import WhyAakash from "../home/whyAakash";
import { useNavigate } from "react-router-dom";
import { Spinner } from "../common/common";

function HomeLayout(props) {
  const authState = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    // need to refresh login state bcz login component is out of protected route
    console.log("refreshing login state home layout comp");
    dispatch(isUserLoggedIn());
    // return () => {
    //   unmountComponent();
    // };
  }, []);

  useEffect(() => {
    if (authState.isLoggedIn) { //check if login
      if (authState.fullname && authState.stateId && authState.academicYear && authState.colgId) { 
        //if all fields are filled redirect to dashboard
        navigate("/dashboard");
      } else { //if mandatory field is missing redirect to profile
        navigate("/profile");
      }
    }
  }, [authState.isLoggedIn]);

  return (
    <>
      {authState.isLoggedIn == 0 && (
        <>
          <HomeHeader />
          <SocialWidget />
          <BannerSection />
          {/* <CurrentOffersSection /> */}
          <GatewayMedicalSection />
          <OurOfferingsSection />
          <WhyAakash />
          <ProductListSection />
          <VideosSection />
          <FacultySection />
          <DownloadAppSection />
          <Footer />
        </>
      )}
      {authState.isLoggedIn == 1 && <Spinner />}
    </>
  );
}

export default HomeLayout;
