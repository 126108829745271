import { getMobileOperatingSystem } from "../../helpers/common";
import StaticPagesLayout from "./staticPagesLayout";

function Terms() {
  const isIOS = false;

  return (
    <StaticPagesLayout>
      <div className="row">
        <div className="TermConditionsHeading">
          <h1>Term & Conditions </h1>
        </div>
      </div>
      <div className="row">
        <div className="TermConditionsMainDiv">
          {!isIOS && <ul>
            <li>
              <p>
                <strong>1. Scope</strong>
              </p>
              <p className="TermListP">
                Use of the Aakash EduTech Private Limited (hereinafter referred
                to as "AEPL") website at{" "}
                <a href={process.env.REACT_APP_PUBLIC_URL}>
                  {process.env.REACT_APP_PUBLIC_URL}
                </a>{" "}
                (hereinafter referred to as "Website") is subject to these terms
                and conditions of use. Users who do not accept these terms &
                conditions are not authorised to use or continue using this
                website. We may amend these terms & conditions at any time and
                any revised version will be effective immediately that it is
                displayed on this website. This website uses cookies. By using
                this website and agreeing to these terms and conditions, you
                consent to AEPL's use of cookies in accordance with the terms of
                AEPL's privacy policy. In the event of any comments or questions
                concerning these terms & conditions, please contact us by
                e-mailing at{" "}
                <a href="mailto:pgpluscustomercare@aesl.in">
                  pgpluscustomercare@aesl.in
                </a>{" "}
                or by writing to us at Aakash EduTech Private Limited, Aakash
                Tower, 8, Pusa Road, New Delhi - 110005, India.
              </p>
            </li>
            <li>
              <p>
                <strong>2. Aakash PG Plus product/services</strong>
              </p>
              <ul>
                <li>
                  <p className="TermListP">
                    <span className="listnumber">2.1.</span>
                    Aakash PG Plus product/services (Aakash PG Plus
                    product/services shall mean & include video lectures,
                    QBank/Test Series & Notes are subscription based prepaid
                    services offered by AEPL for the student/users preparing for
                    the Medical PG Entrance exams. The complete gamut of
                    services offered by AEPL are listed in detail on the Website
                    of AEPL at{" "}
                    <a href={process.env.REACT_APP_PUBLIC_URL}>
                      {process.env.REACT_APP_PUBLIC_URL}
                    </a>
                  </p>
                </li>
                <li>
                  <p className="TermListP">
                    <span className="listnumber">2.2.</span>
                    Aakash PG Plus product/services are available only to the
                    registered users (“Users") on a single user basis, for a
                    particular period of time on making the stipulated payment
                    and abiding by the applicable terms and conditions. Aakash
                    PG Plus product/services are personal in nature and cannot
                    be assigned or transferred to or shared with any other
                    person other than the registered users.
                  </p>
                </li>
                <li>
                  <p className="TermListP">
                    <span className="listnumber">2.3.</span>
                    In order to use Aakash PG Plus product/services, the User
                    must obtain access to the World Wide Web, either directly or
                    through devices that access web-based content, and pay any
                    service fees associated with such access to your service
                    provider. In addition, User will need to have access to all
                    equipment necessary to make such a connection to the World
                    Wide Web, including a computer and modem or other access
                    devices. The Website is best viewed in following
                    systems/configurations:
                  </p>
                  <ul>
                    <li>
                      <p className="TermListP">
                        <span className="listnumber">2.3.1.</span> Bandwidth: 1
                        mbps or higher
                      </p>
                    </li>
                    <li>
                      <p className="TermListP">
                        <span className="listnumber">2.3.2.</span> OS: Windows 7
                        or later version of Windows
                      </p>
                    </li>
                    <li>
                      <p className="TermListP">
                        <span className="listnumber">2.3.3.</span> Browsers:
                        Internet Explorer 10 or later or Google Chrome 34 or
                        later
                      </p>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
            <li>
              <p>
                <strong>
                  3. Access to the Aakash PG Plus product/services
                </strong>{" "}
                is subject to these terms and conditions.
              </p>
              <ul>
                <li>
                  <p className="TermListP">
                    <span className="listnumber">3.1.</span> Registration, OTP:
                    <br />
                    <span className="TermListContent">
                      For registration the User shall give accurate information
                      and, where such information changes over time, update such
                      information (to the extent possible: online) without undue
                      delay. The User shall ensure that his/her mobile number,
                      as provided to{" "}
                      <a href="mailto:pgpluscustomercare@aesl.in">
                        pgpluscustomercare@aesl.in
                      </a>{" "}
                      (User is redirected to these links via the Website based
                      on the Aakash PG Plus the User is interested in), is
                      updated at all times and an address at which the User can
                      be contacted.
                    </span>
                    <span className="TermListContent">
                      Any change of address or phone numbers should be notified
                      to{" "}
                      <a href="mailto:pgpluscustomercare@aesl.in">
                        pgpluscustomercare@aesl.in
                      </a>{" "}
                      through e-mail without any delay. If you are directly
                      subscribing as a User of this website, you represent and
                      warrant that you are at least 18 years of age and that you
                      possess the legal right and capacity to use Aakash PG Plus
                      product/services in accordance with the stated terms and
                      usage policies. In cases, where a minor below the age of
                      18 years of age, wants to use this website, such a user
                      shall duly register himself through his parent/guardian
                      and such a parent/guardian hereby agrees to accordingly
                      register and supervise usage by, and be responsible for
                      the action of any such minors who use the computer and/or
                      OTP to access the site. The parent/guardian shall enter
                      into this agreement on behalf of such minor and bind
                      himself/herself in accordance with all terms and
                      conditions herein.
                    </span>
                    <span className="TermListContent">
                      By registering yourself as a User and subscribing to avail
                      the Aakash PG Plus product/services on this website, it
                      will be deemed that you have read, understood and agreed
                      to fully abide by all the terms and conditions of the
                      Website as contained herein.
                    </span>
                    <span className="TermListContent">
                      Some pages of the Website may be locked. In the interest
                      of safety and security of the data and Website, only
                      registered or paid users may access said pages. AEPL
                      reserves the right, in its sole discretion, to terminate
                      or refuse all or part of its Aakash PG Plus
                      product/services, for any reason without notice to you.
                      AEPL particularly reserves the right to determine certain
                      web pages, which were previously freely accessible,
                      subject to registration. AEPL is entitled, at any time and
                      without obligation to give reasons, to deny the user the
                      right to access the locked area by blocking its User Data
                      (as defined below), in particular, if the User uses false
                      data for the purpose of registration; violates the Terms &
                      conditions or neglects its duty of care with regard to
                      Data; violates any applicable laws in the access to or use
                      of the AEPL Website or did not use the AEPL Website for a
                      longer period.
                    </span>
                    <span className="TermListContent">
                      By registering on the Website, you agree to:
                    </span>
                    <ul>
                      <li>
                        <p className="TermListP">
                          <span className="listnumber">3.1.1.</span> Make your
                          contact details available to AEPL so that you may be
                          contacted by AEPL for education information through
                          email, telephone and SMS.
                        </p>
                      </li>
                      <li>
                        <p className="TermListP">
                          <span className="listnumber">3.1.2.</span>{" "}
                          <span>
                            Receive promotional mails/special offers from the
                            Website or any of its partner websites, if any,
                            and/or be contacted by AEPL in accordance with the
                            privacy settings set by you.
                          </span>
                        </p>
                        <p className="TermListP">
                          Upon registration, the User will be provided with an
                          access through phone number & OTP or code, comprising
                          a User ID and OTP (“User Data”). The User Data allows
                          the User to view or change its data or, as applicable,
                          to withdraw its consent to data processing.
                        </p>
                        <p className="TermListP">
                          The User shall ensure that User Data is not accessible
                          by third parties and is liable for all transactions
                          and other activities carried out under its User Data.
                          At the end of each online session, the User shall
                          log-off from the websites. If and to the extent the
                          User becomes aware that third parties are misusing
                          their data, the User shall notify AEPL thereof without
                          undue delay in writing, or, as the case may be, by
                          e-mail.
                        </p>
                        <p className="TermListP">
                          After receipt of the notice as mentioned above, AEPL
                          will deny access to the locked area under such User
                          Data. Access by the User will only be possible again
                          upon the User’s application to the Website or upon new
                          registration. Please note that the OTP generated by
                          you for accessing the Website should not be shared by
                          you with anyone else at any time. Please also note
                          that especially with regard to the Aakash PG Plus
                          product/services purchased by you, the same have been
                          licensed to you by AEPL for your own use only and you
                          cannot share your User data or OTP with others or
                          allow others to use the paid Aakash PG Plus
                          product/services. This will be a gross violation of
                          the license terms governing the paid Aakash PG Plus
                          product/services.
                        </p>
                      </li>
                    </ul>
                  </p>
                </li>
              </ul>
            </li>
            <li>
              <p>
                <strong>4. Fees/Charges</strong>
              </p>
              <p className="TermListP">
                The fee includes prevalent taxes & charges imposed by the
                Government authorities. If at any point of time, the Government
                further increases taxes or charges, the extra amount will be
                borne by the User from the date of enforcement of the act by the
                Government. In case the AEPL gives concession/ discount/
                scholarship to any User and Government charges taxes on such
                amount of concession/discount/scholarship, the amount in such a
                case has to be borne by the User. The quantum of concession/
                discount/ scholarship to be given to any User/student shall be
                as per the policy of AEPL from time to time.
              </p>
            </li>
            <li>
              <p>
                <strong>5. Refund Policy</strong>
              </p>
              <p className="TermListP">No refund under any circumstances.</p>
            </li>
            <li>
              <p>
                <strong>6. Replacement Policy</strong>
                <ul>
                  <li>
                    <p className="TermListP">
                      <span className="listnumber">6.1.</span> Replacement
                      Policy for Book(s) (if offered):
                      <ul>
                        <li>
                          <p className="TermListP">
                            <span className="listnumber">6.1.1.</span> Due to
                            receipt of Physically Damaged Aakash PG Plus
                            product: Request should be made within 5 days of
                            delivery/receiving of the product. Detailed images
                            to be sent by the customer to{" "}
                            <a href="mailto:pgpluscustomercare@aesl.in">
                              pgpluscustomercare@aesl.in
                            </a>{" "}
                            before initiation of the replacement. Replacement
                            order of damaged book(s) will be shipped as per
                            stock availability.
                          </p>
                        </li>
                        <li>
                          <p className="TermListP">
                            <span className="listnumber">6.1.2.</span> Due to
                            missing book(s): Request should be made within 5
                            days of delivery/receiving of the book(s). The
                            missing book(s) will be shipped as per stock
                            availability.
                          </p>
                        </li>
                      </ul>
                    </p>
                  </li>
                  <li>
                    <p className="TermListP">
                      <span className="listnumber">6.2.</span> Replacement
                      Policy for SD Cards / Tablet (If offered or sold)
                      <ul>
                        <li>
                          <p className="TermListP">
                            <span className="listnumber">6.2.1.</span> Due to
                            receipt of Physically Damaged SD Cards / Tablet:
                            Request should be made within 5 days of
                            delivery/receiving of SD Cards / Tablet. Detailed
                            images to be sent by the User before initiation of
                            the replacement. The replacement order will be
                            shipped only after receipt of the original SD Cards
                            / Tablet at the warehouse, as per stock
                            availability.
                          </p>
                        </li>
                        <li>
                          <p className="TermListP">
                            <span className="listnumber">6.2.2.</span> Due to
                            receipt of Defective (non-functional) SD Cards /
                            Tablet: Support team to first take the customer on
                            remote access, diagnose the problem, try to solve it
                            remotely, and if not solvable, then replacement SD
                            Cards / Tablet to be shipped, but only after receipt
                            of the original SD Cards / Tablet at the warehouse.
                          </p>
                        </li>
                      </ul>
                    </p>
                  </li>
                </ul>
              </p>
            </li>
            <li>
              <p>
                <strong>
                  7. Rights of Use to Information, Website, and Documentation
                </strong>
              </p>
              <ul>
                <li>
                  <p className="TermListP">
                    <span className="listnumber">7.1.</span> The right to use
                    the Aakash PG Plus product/services on the Website is on
                    revocable license/permission granted by the AEPL to the
                    User. The License is non-exclusive and non-transferable
                    which may not be sublicensed to use the information, website
                    and documentation made available to the User on or via the
                    Website to the extent agreed or in the event of no such
                    agreement to the extent of the purpose intended by AEPL in
                    making same available to the User on the terms and
                    conditions contained herein. Except the usage of the Aakash
                    PG Plus product/services during the license period, the
                    registered User shall not have any right or title over the
                    Website or any of its Content.
                  </p>
                  <p className="TermListP">
                    <span className="listnumber">7.2.</span> If you provide any
                    information that is untrue, inaccurate, not up-to-date or
                    incomplete, or if AEPL has reasonable grounds to suspect
                    that such information is untrue, inaccurate, not current or
                    incomplete, AEPL has the right to suspend or terminate your
                    account and refuse any and all current or future use of the
                    Aakash PG Plus product/services or any portion thereof.
                  </p>
                  <p className="TermListP">
                    <span className="listnumber">7.3.</span> Information, data
                    and documentation shall not be distributed by the User to
                    any third party at any time nor it shall be rented or in any
                    other way made available. User shall not modify the website
                    or documentation nor shall it disassemble, reverse engineer
                    or decompile the website or separate any part thereof.
                  </p>
                  <p className="TermListP">
                    <span className="listnumber">7.4.</span> All prices of
                    Aakash PG Plus product/services/Book(s)/ SD Cards / Tablet
                    are subject to change without notice. Every effort has been
                    made to ensure accurate pricing of the Aakash PG Plus
                    product/services/Book(s)/ SD Cards / Tablet featured on our
                    website. In the event a part or accessory is ordered and the
                    listed price has changed, you will be notified prior to the
                    processing of your order. Purchase "as is." You agree that
                    all Aakash PG Plus product/Services/Book(s)/ SD Cards /
                    Tablet purchased by you through this website are provided
                    under warranties, if any, of the manufacturer only, and not
                    by AEPL. All Aakash PG Plus product/services/Book(s)/ SD
                    Cards / Tablet are provided by AEPL on "as is" basis with no
                    representations or warranties of any kind from AEPL.
                  </p>
                  <p className="TermListP">
                    <span className="listnumber">7.5.</span> Except for Private
                    use and non-commercial use, none of the video lectures,
                    QBank/Test Series & Notes shall be copied, reproduced,
                    distributed, republished, downloaded, displayed, posted
                    electronically or mechanically, transmitted, recorded in any
                    manner, photocopied, or reproduced without prior written
                    consent of AEPL. Any other use of content on this website,
                    including any commercial use, reproduction for purposes
                    other than that noted above, modification, distribution, or
                    republication without the prior written consent of AEPL is
                    strictly prohibited and shall violate copyright or trademark
                    laws.
                  </p>
                </li>
              </ul>
            </li>

            <li>
              <p>
                <strong>8. Duties of the User</strong>
              </p>
              <ul>
                <li>
                  <p className="TermListP">
                    <span className="listnumber">8.1.</span> AEPL can ask for
                    User's photograph, roll number, admit card copy etc. AEPL
                    reserves the right to use the photograph for publicity in
                    case the User secures position / succeeds in Medical
                    Entrance Exams such as NEET PG, INICET, FMGE, NExT etc. The
                    User shall provide photograph at the time of registration or
                    can be provided later as well.
                  </p>
                  <p className="TermListP">
                    <span className="listnumber">8.2.</span> User enrolled with
                    AEPL will have to provide photocopy of Admit Card of all
                    Entrance Exams including Medical Entrance Exam as soon as
                    they receive admit cards from the exam conducting body.
                  </p>
                  <p className="TermListP">
                    <span className="listnumber">8.3.</span> AEPL reserves the
                    right to make any alteration in its Aakash PG Plus product
                    /services /Website without any prior notice to anybody. The
                    decision of the AEPL will be final and binding on the Users.
                  </p>
                  <p className="TermListP">
                    <span className="listnumber">8.4.</span> In order to use the
                    Aakash PG Plus services / product, you further agree not to:
                    <ul>
                      <li>
                        <p className="TermListP">
                          <span className="listnumber">8.4.1.</span> Use data
                          and material for commercial gain (including, but not
                          limited to teaching, group usage etc.). The use of
                          data and material for commercial gain is not permitted
                          and is violation of the copyrights of the AEPL.
                        </p>
                      </li>
                      <li>
                        <p className="TermListP">
                          <span className="listnumber">8.4.2.</span> Create a
                          false identity or impersonate another person or entity
                          in any way.
                        </p>
                      </li>
                      <li>
                        <p className="TermListP">
                          <span className="listnumber">8.4.3.</span> Restrict,
                          discourage or inhibit any person from using the Aakash
                          PG Plus services / product, disclose personal
                          information about a third person on or through Aakash
                          PG Plus services / product / website or obtained from
                          Aakash PG Plus services / product / website without
                          the consent of such person or collect information
                          about Users of the AEPL's website.
                        </p>
                      </li>
                      <li>
                        <p className="TermListP">
                          <span className="listnumber">8.4.4.</span> Undertake,
                          cause, permit or authorize the modification, creation
                          of derivative works, translation, reverse engineering,
                          decompiling, disassembling or hacking of any aspect of
                          the Aakash PG Plus services / product / website or any
                          part thereof, or attempt to do any of the foregoing,
                          except and solely to the extent permitted by these
                          Terms, the authorized features of the Aakash PG Plus
                          services / product / website, or by law, or otherwise
                          attempt to use or access any portion of the Aakash PG
                          Plus services/product / website other than as intended
                          by AEPL.
                        </p>
                      </li>
                      <li>
                        <p className="TermListP">
                          <span className="listnumber">8.4.5.</span> Gain
                          unauthorized access to the Aakash PG Plus
                          services/product, accessible to other Users' accounts,
                          names or personally identifiable information, or to
                          other computers or websites connected or linked to the
                          Aakash PG Plus product/services.
                        </p>
                      </li>
                      <li>
                        <p className="TermListP">
                          <span className="listnumber">8.4.6.</span> Remove,
                          disable, damage, circumvent or otherwise interfere
                          with any security-related features of the website,
                          features that prevent or restrict the use or copy of
                          any part of the website or any content accessible on
                          or through website, or features that enforce
                          limitations on the use of the website or any content
                          accessible on or through website.
                        </p>
                      </li>
                      <li>
                        <p className="TermListP">
                          <span className="listnumber">8.4.7.</span> Violate any
                          applicable central law or state law or local laws or
                          regulations or these Terms.
                        </p>
                      </li>
                      <li>
                        <p className="TermListP">
                          <span className="listnumber">8.4.8.</span> Assist or
                          permit any persons in engaging in any of the
                          activities described above. Any unauthorized or
                          prohibited use of the Aakash PG Plus services /
                          product / website or AEPL Content may subject the
                          offender to civil liability and criminal prosecution
                          under applicable central law or state law or local
                          laws.
                        </p>
                      </li>
                    </ul>
                  </p>
                  <p className="TermListP">
                    <span className="listnumber">8.5.</span> You represent,
                    warrant, covenant and undertake that you shall not host,
                    display, upload, modify, publish, transmit, update or share
                    any information on the Website, that:
                    <ul>
                      <li>
                        <p className="TermListP">
                          <span className="listnumber">8.5.1.</span> Belongs to
                          another person and to which you do not have any right;
                        </p>
                      </li>
                      <li>
                        <p className="TermListP">
                          <span className="listnumber">8.5.2.</span> Is grossly
                          harmful, harassing, blasphemous, defamatory, obscene,
                          pornographic, paedophilic, libellous, invasive of
                          another's privacy, hateful, or racially, ethnically
                          objectionable, disparaging, relating or encouraging
                          money laundering or gambling, or otherwise unlawful in
                          any manner whatever;
                        </p>
                      </li>
                      <li>
                        <p className="TermListP">
                          <span className="listnumber">8.5.3.</span> Harm minors
                          in any way,
                        </p>
                      </li>
                      <li>
                        <p className="TermListP">
                          <span className="listnumber">8.5.4.</span> Infringes
                          any patent, trademark, copyright or other proprietary
                          rights;
                        </p>
                      </li>
                      <li>
                        <p className="TermListP">
                          <span className="listnumber">8.5.5.</span> Violates
                          any law for the time being in force;
                        </p>
                      </li>
                      <li>
                        <p className="TermListP">
                          <span className="listnumber">8.5.6.</span> Deceives or
                          misleads the addressee about the origin of such
                          messages or communicates any information which is
                          grossly offensive or menacing in nature;
                        </p>
                      </li>
                      <li>
                        <p className="TermListP">
                          <span className="listnumber">8.5.7.</span> Impersonate
                          another person;
                        </p>
                      </li>
                      <li>
                        <p className="TermListP">
                          <span className="listnumber">8.5.8.</span> Contains
                          software viruses or any other computer code, files or
                          programs designed to interrupt, destroy or limit the
                          functionality of any computer resource;
                        </p>
                      </li>
                      <li>
                        <p className="TermListP">
                          <span className="listnumber">8.5.9.</span> Threatens
                          the unity, integrity, defence, security or sovereignty
                          of India, friendly relations with foreign states, or
                          public order or causes incitement to the commission of
                          any cognizable offence or prevents investigation of
                          any offence or is insulting any other nation.
                        </p>
                      </li>
                    </ul>
                  </p>
                  <p className="TermListP">
                    <span className="listnumber">8.6.</span> You further
                    represent, warrant, covenant and undertake that your use of
                    the Website shall not:
                    <ul>
                      <li>
                        <p className="TermListP">
                          <span className="listnumber">8.6.1.</span> Violate any
                          applicable local, provincial, state, national or
                          international law, statute, ordinance, rule or
                          regulation;
                        </p>
                      </li>
                      <li>
                        <p className="TermListP">
                          <span className="listnumber">8.6.2.</span> Interfere
                          with or disrupt computer networks connected to the
                          website;
                        </p>
                      </li>
                      <li>
                        <p className="TermListP">
                          <span className="listnumber">8.6.3.</span> Impersonate
                          any other person or entity, or make any
                          misrepresentation as to your employment by or
                          affiliation with any other person or entity,
                        </p>
                      </li>
                      <li>
                        <p className="TermListP">
                          <span className="listnumber">8.6.4.</span> Forge
                          headers or in any manner manipulate identifiers in
                          order to disguise the origin of any user information;
                        </p>
                      </li>
                      <li>
                        <p className="TermListP">
                          <span className="listnumber">8.6.5.</span> Upload,
                          post, transmit, publish, or distribute any material or
                          information for which you do not have all necessary
                          rights and licenses;
                        </p>
                      </li>
                      <li>
                        <p className="TermListP">
                          <span className="listnumber">8.6.6.</span> Upload,
                          post, transmit, publish or distribute any material
                          which infringes, violates, breaches or otherwise
                          contravenes the rights of any third party, including
                          any copyright, trademark, patent, rights of privacy or
                          publicity or any other proprietary right;
                        </p>
                      </li>
                      <li>
                        <p className="TermListP">
                          <span className="listnumber">8.6.7.</span> Interfere
                          with or disrupt the use of the Website by any other
                          user, nor "stalk", threaten, or in any manner harass
                          another user,
                        </p>
                      </li>
                      <li>
                        <p className="TermListP">
                          <span className="listnumber">8.6.8.</span> Upload,
                          post, transmit, publish, or distribute any material or
                          information which contains a computer virus, or other
                          code, files or programs intending in any manner to
                          disrupt or interfere with the functioning of the
                          Website, or that of other computer systems,
                        </p>
                      </li>
                      <li>
                        <p className="TermListP">
                          <span className="listnumber">8.6.9.</span> Use the
                          website in such a manner as to gain unauthorized entry
                          or access to the computer systems of others;
                        </p>
                      </li>
                      <li>
                        <p className="TermListP">
                          <span className="listnumber">8.6.10.</span> Upload,
                          post, transmit, publish or distribute any material or
                          information which constitutes or encourages conduct
                          that would constitute a criminal offence, give rise to
                          other liability, or otherwise violate applicable law,
                        </p>
                      </li>
                      <li>
                        <p className="TermListP">
                          <span className="listnumber">8.6.11.</span> Upload,
                          post, transmit, publish, or distribute any material or
                          information that is unlawful, or which may potentially
                          be perceived as being harmful, threatening, abusive,
                          harassing, defamatory, libellous, vulgar, obscene, or
                          racially, ethnically, or otherwise objectionable;
                        </p>
                      </li>
                      <li>
                        <p className="TermListP">
                          <span className="listnumber">8.6.12.</span> Reproduce,
                          copy, modify, sell, store, distribute or otherwise
                          exploit for any commercial purposes the Website, or
                          any component thereof (including, but not limited to
                          any materials or information accessible through the
                          Website);
                        </p>
                      </li>
                      <li>
                        <p className="TermListP">
                          <span className="listnumber">8.6.13.</span> Use any
                          device, software or routine to interfere or attempt to
                          interfere with the proper working of the Website;
                        </p>
                      </li>
                      <li>
                        <p className="TermListP">
                          <span className="listnumber">8.6.14.</span> Take any
                          action that imposes an unreasonable or
                          disproportionately large load on the Website
                          infrastructure;
                        </p>
                      </li>
                    </ul>
                  </p>
                  <p className="TermListP">
                    <span className="listnumber">8.7.</span> Any interpretation
                    of test results or study material other than as a tool of
                    learning and enhancing knowledge; or
                    <br />
                    The tests and exercises are created for you to test your
                    understanding of concepts and are not to be interpreted as a
                    sample question paper for any examinations.
                  </p>
                  <p className="TermListP">
                    <span className="listnumber">8.8.</span> Any content
                    uploaded by you on the Website shall be subject to relevant
                    laws and may be disabled and/or may be subject to
                    investigation under appropriate laws. Furthermore, if you
                    are found to be in non-compliance with the laws and
                    regulations, these terms, or the privacy policy of the
                    Website, AEPL may terminate your account/block your access
                    to the Website and AEPL reserve the right to remove any
                    non-compliant content uploaded by you on the Website.
                  </p>
                  <p className="TermListP">
                    <span className="listnumber">8.9.</span> AEPL reserves the
                    right to remove any content or links that allegedly
                    infringes any other person's copyright at any point of time
                  </p>
                </li>
              </ul>
            </li>

            <li>
              <p>
                <strong>9. Intellectual Property</strong>
              </p>
              <p className="TermListP">
                Notwithstanding any content in these Terms & conditions,
                information, brand names and other Content of the Website may
                not be changed, copied, reproduced, sold, rented, used,
                supplemented or otherwise used in any other way without the
                prior written permission of AEPL.
              </p>
              <p className="TermListP">
                Except for the rights of use and other rights expressly granted
                herein, no other rights are granted to the User nor shall any
                obligation be implied requiring the grant of further rights. Any
                and all copyright, patent rights and licenses are expressly
                excluded.
              </p>
              <p className="TermListP">
                AEPL may, without charge, use any ideas or proposals stored by a
                User on the Website for the development, improvement and sale of
                its Aakash PG Plus product/services.
              </p>
              <p className="TermListP">
                All the Content on the website are proprietary to AEPL except
                the data / queries posted / uploaded by the users.
              </p>
              <p className="TermListP">
                All material available on the Website including but not limited
                to, its design, text, graphics, screen shots, files and the
                selection and arrangement thereof is protected by copyright
                laws.
              </p>
              <p className="TermListP">
                The owners of the intellectual property, copyrights and
                trademarks are AEPL, its affiliates or third-party licensors.
                Any modification, copying, reproduction, republishing,
                uploading, posting, transmitting or distributing any material on
                this website including text, graphics, code and/or software is
                expressly prohibited.
              </p>
              <p className="TermListP">
                No recourse shall be taken against AEPL for any alleged or
                actual infringement or misappropriation of any proprietary
                rights in your communications to AEPL. At any stage, if it is
                believed that the Content appearing on this website constitute
                copyright infringement of another person's rights, the User may
                communicate the same to us.
              </p>
              <p className="TermListP">
                Notice of Copyright Infringement: We are not liable for any
                infringement of copyright arising out of materials posted on or
                transmitted through the Website, or items posted on the Website,
                by end users or any other third parties. In the event, you have
                any grievance in relation to any content uploaded on the
                Website, you may contact our Grievance Officer, or write at the
                following address: e-mailing at{" "}
                <a href="mailto:pgpluscustomercare@aesl.in">
                  pgpluscustomercare@aesl.in
                </a>{" "}
                or can write to us at Aakash EduTech Private Limited, Aakash
                Tower, 8, Pusa Road, New Delhi - 110005, India.
              </p>
              <p className="TermListP">
                We request you to please provide the following information in
                your complaint: - (a) A physical or electronic signature of a
                person authorized to act on behalf of the copyright owner for
                the purposes of the complaint. (b) Identification of the
                copyrighted work claimed to have been infringed. (c)
                Identification of the material on our website that is claimed to
                be infringing or to be the subject of infringing activity. (d)
                The address, telephone number or e-mail address of the
                complaining party. (e) A statement that the complaining party
                has a good-faith that use of the material in the manner
                complained of is not authorized by the copyright owner, its
                agent or the law. (f) A statement, under penalty of perjury,
                that the information in the notice of copyright infringement is
                accurate, and that the complaining party is authorized to act on
                behalf of the owner of the right that is allegedly infringed.
              </p>
            </li>

            <li>
              <p>
                <strong>10. Hyperlinks/ Ask an Expert' Section</strong> (if
                applicable)
              </p>
              <p className="TermListP">
                The Website may contain hyperlinks to the web pages of third
                parties. AEPL shall have no liability for the Content of such
                web pages and does not make representations about or endorse
                such web pages or their Content as its own, as AEPL does not
                control the information on such web pages and is not responsible
                for the Content and information given thereon. The use of such
                web pages shall be at the sole risk of the User.
              </p>
              <p className="TermListP">
                Some Content on the site may belong to third parties. Such
                Content have been reproduced after taking prior permission from
                the said party and the copyright of such Content would remain
                exclusively with the said third party. AEPL shall not be
                responsible for any mistakes which might appear in such Content.
              </p>
              <p className="TermListP">
                Please note that the answers under the section "Ask an Expert"
                (if available) or the tests/notes uploaded in the 'Upload Tests
                & Notes' sub-section (if available) may be uploaded by User
                directly. Therefore, AEPL will not be liable for the content of
                the same.
              </p>
              <p className="TermListP">
                Further, AEPL may certify an answer posted by a User to be
                correct or not. This however does not mean that AEPL has checked
                whether the answer has or has not been copied by such User from
                any other source without authorisation. AEPL shall surely look
                into any concern of this nature that is brought to its
                attention.
              </p>
            </li>
            <li>
              <p>
                <strong>11. Data Privacy Protection</strong>
              </p>
              <p className="TermListP">
                For collection, use and processing of personally identifiable
                data of the User of the Website, AEPL shall comply with
                applicable laws on data privacy protection and the Website Data
                Protection Privacy Policy, which is available as per hyperlink
                on the Website
              </p>
            </li>
            <li>
              <p>
                <strong>12. No Liability:</strong>
              </p>
              <p className="TermListP">
                It is for the User to see whether he/she is eligible for a
                certain Medical Entrance examination or not. The AEPL does not
                hold itself responsible if a User's admission form cannot be
                forwarded or is rejected by the examination body on any ground
                whatsoever. Such a User cannot claim a refund of the whole or
                any part of the fee he/she has paid to the AEPL.
              </p>
              <p className="TermListP">
                The responsibility of getting admission form forwarded to the
                examining body is that of the User himself/herself.
              </p>
              <p className="TermListP">
                It is the endeavour of AEPL to ensure that the information
                provided on the Website is accurate but does not guarantee or
                warrant its accuracy, adequacy, correctness, validity,
                completeness or suitability for any purpose, and accepts no
                responsibility with respect to the information on the Website.
                Use of this website, by implication, means that you have gone
                through and agreed to abide by the Terms and Conditions and the
                Disclaimers of this website. AEPL does not claim that the
                information downloaded is up to date, correct and error free or
                that the servers that make AEPL Website available, are free of
                viruses or harmful components. Any reliance on the service or
                database available through this service is at the User's own
                risk. Though due care has been taken to make the database
                completely reliable and error-free, AEPL claims exemption from
                any liability arising out of any such error in the data base.
              </p>
              <p className="TermListP">
                AEPL disclaims all responsibility for any loss, injury,
                liability or damage of any kind resulting from and arising out
                of, or any way related to:
                <ul>
                  <li>
                    <p className="TermListP">
                      <span className="listnumber">12.1.</span> Any errors in or
                      omissions from the Website and its content, including but
                      not limited to technical inaccuracies and typographical
                      errors.
                    </p>
                  </li>
                  <li>
                    <p className="TermListP">
                      <span className="listnumber">12.2.</span> Any third party
                      websites or content therein directly or indirectly
                      accessed through links in the Website, including but not
                      limited to any errors in or omissions there from
                    </p>
                  </li>
                  <li>
                    <p className="TermListP">
                      <span className="listnumber">12.3.</span> The
                      unavailability of this website or any portion thereof.
                    </p>
                  </li>
                  <li>
                    <p className="TermListP">
                      <span className="listnumber">12.4.</span> The use of any
                      equipment or software in connection with the Website by
                      the User.
                    </p>
                  </li>
                </ul>
              </p>
              <p className="TermListP">
                Aakash EduTech Private Limited may also contact the User through
                SMS, WhatsApp, email, and call to give notifications on various
                important updates. Therefore, User holds Aakash EduTech Private
                Limited non-liable for any liabilities including financial
                penalties, damages, expenses, in case the User's mobile number
                is registered with Do Not Call (DNC) database.
              </p>
              <p className="TermListP">
                <span className="TermTextUnderLine">
                  Liability for defects of title or quality
                </span>
              </p>
              <p className="TermListP">
                Insofar as any information or documentation is made available at
                no cost, any liability for defects as to quality or title of the
                information and documentation especially in relation to the
                correctness or absence of defects or the absence of claims or
                third-party rights or in relation to completeness and/or fitness
                for purpose are excluded except for cases involving wilful
                misconduct or fraud.
              </p>
              <p className="TermListP">
                The information on the Website may contain specifications or
                general descriptions related to the technical possibilities of
                individual Aakash PG Plus product/services which may not be
                available in certain cases (e.g. due to Aakash PG Plus
                product/services' changes). The required performance of the
                Aakash PG Plus product/services shall therefore be mutually
                agreed in each case at the time of purchase
              </p>
              <p className="TermListP">
                <span className="TermTextUnderLine">
                  Other Liability, Viruses
                </span>
              </p>
              <p className="TermListP">
                The liability of the Website for defects in relation to quality
                and title shall be determined in accordance with the provisions
                of these Terms & conditions. Any further liability of AEPL is
                excluded unless required by law, e.g. under the Act on Product
                Liability or in cases of wilful misconduct, gross negligence,
                personal injury or death, failure to meet guaranteed
                characteristics, fraudulent concealment of a defect or in case
                of breach of fundamental contractual obligations. The damage in
                case of breach of fundamental contractual obligations is limited
                to the contract-typical, foreseeable damage if there is no
                wilful misconduct or gross negligence.{" "}
              </p>
              <p className="TermListP">
                Although AEPL makes every endeavour to keep the Website free
                from viruses, AEPL cannot make any guarantee that it is
                virus-free. The User shall, for its own protection, take the
                necessary steps to ensure appropriate security measures and
                shall utilize a virus scanner before downloading any
                information, software or documentation.
              </p>
              <p className="TermListP">
                However, this does not intend nor imply any changes to the
                burden of proof to the User's disadvantage.
              </p>
              <p className="TermListP">
                <span className="TermTextUnderLine">
                  Limitation of Liability:
                </span>
              </p>
              <p className="TermListP">
                Under no circumstances shall AEPL be liable to any User for:
                <ul>
                  <li>
                    <p className="TermListP">
                      Loss, injury, claim, liability or damages of any kind
                      resulting from the use of or the inability to use the
                      Aakash PG Plus product/services / data in the Website,
                      even if AEPL had been advised of the possibility of such
                      damages and regardless of the form of action, whether in
                      contract, stated or otherwise.
                    </p>
                  </li>
                  <li>
                    <p className="TermListP">
                      Special, direct, incidental, punitive, exemplary or
                      consequential damages of any kind whatsoever in any way
                      due, resulting from or arising in connection with the use
                      of or the inability to use the website or its
                      content/data.
                    </p>
                  </li>
                  <li>
                    <p className="TermListP">
                      Claim attributable to errors, omission or inaccuracies in
                      or destructive properties of any information.
                    </p>
                  </li>
                </ul>
              </p>
              <p className="TermListP">
                Any material downloaded and used shall be at the risk of the
                user and no Aakash PG Plus product/services utilised by this
                Website shall create any warranty. AEPL shall not be
                responsible, if any information/page is downloaded from AEPL and
                after downloading complete/partial, text/information is
                altered/removed/obscured contained therein.
              </p>
              <p className="TermListP">
                AEPL and/or its respective suppliers make no representations
                about the suitability, reliability, availability, timelines, of
                the Aakash PG Plus product / services contained on the Website
                for any purpose. All Aakash PG Plus product/ services are
                provided "as is" without warranty of any kind, and/or its
                respective suppliers hereby disclaim all warranties and
                conditions with regard to this information, Aakash PG Plus
                product/ services including all implied warranties and
                conditions of merchantability, fitness for a particular purpose,
                title and non-infringement.
              </p>
              <p className="TermListP">
                In no event shall AEPL and/or its suppliers be liable for any
                direct, indirect, punitive, incidental, special, consequential
                damages or any damages whatsoever including, without limitation,
                damages for loss of use, data or profits, arising out of or in
                any way connected with the use or performance of the AEPL
                Website, with the delay or inability to use the AEPL Website or
                related Aakash PG Plus product/services, the provision of or
                failure to provide Aakash PG Plus product/services, or for any
                information, software, Aakash PG Plus product / services
                obtained through the AEPL Website, or otherwise arising out of
                the use of AEPL Website, whether based on contract, negligence,
                strict liability or otherwise, even if AEPL or any of its
                suppliers has been advised of the possibility of damages.
              </p>
              <p className="TermListP">
                <span className="TermTextUnderLine">Indemnification:</span>
              </p>
              <p className="TermListP">
                As a User of this Website, you agree to protect and fully
                compensate AEPL and its domain's associates, such as, service
                providers and technology partners, from any and all third party
                claims, liabilities, damages, expenses and costs, including, but
                not limited to, all legal expenses, arising from your use of
                Aakash PG Plus product/services on this domain, your violation
                of the terms or your infringement by any other use of your
                account of any intellectual property or other right of anyone.
              </p>
            </li>
            <li>
              <p>
                <strong>13. Third Party Advertising</strong>
              </p>
              <p className="TermListP">
                There may be third party information, advertisement, and schemes
                displayed through this website. AEPL disclaims, to the fullest
                extent permissible, the correctness, viability, availability,
                merchantability or fitness of such information, advertisement
                and scheme. AEPL hereby declares that the advertisers and their
                clients etc. are neither its agent, partner nor principal and
                AEPL does not provide any guarantee or warranty or any
                representation on behalf of any of the advertisers or their
                clients.
              </p>
            </li>
            <li>
              <p>
                <strong>14. General</strong>
              </p>
              <p className="TermListP">
                AEPL tries its best, but does not guarantee that the Aakash PG
                Plus product/services will be error free, or continuously
                available, or that the service will be free of viruses or other
                harmful components.
              </p>
              <p className="TermListP">
                This Agreement does not in any manner create any relationship
                whatsoever as between User and the AEPL, either as a joint
                venture, partnership, employment, or agency relationship.
                Performance of this agreement by AEPL is subject to existing
                laws and legal process in India, and nothing contained in this
                agreement is in derogation of the rights of AEPL to comply with
                governmental, court and law enforcement requests or requirements
                relating to your use of AEPL or information provided to or
                gathered by AEPL with respect to such use.
              </p>
              <p className="TermListP">
                Unless otherwise specified herein, this Agreement constitutes
                the entire agreement between the User and AEPL with respect to
                the AEPL site and it supersedes all prior communications and
                proposals, whether electronic, oral or written, between the User
                and AEPL with respect to the AEPL site.
              </p>
              <p className="TermListP">
                AEPL can change terms of usage anytime.
              </p>
              <p className="TermListP">
                AEPL reserves the right to make any change in its data/
                material/ Aakash PG Plus product/services without any prior
                notice to anybody.
              </p>
              <p className="TermListP">
                You agree that these Terms & Conditions shall be construed in
                accordance with the laws in force in India and in case of any
                dispute or difference between you and AEPL regarding the
                interpretation of these terms and conditions, non-payment of any
                claim or any dispute arising out of or in pursuant to these
                terms and conditions, the same shall be referred to the
                arbitration within 60 days of either Party notifying the other
                Party to the Agreement of such dispute, for adjudication under
                the provisions of Arbitration & Conciliation Act, 1996. Parties
                hereby mutually agree to appoint sole arbitrator for
                adjudication of disputes between the Parties whose decision
                shall be final and binding upon the parties. The sole Arbitrator
                shall conduct the arbitration proceedings at Delhi only.
              </p>
              <p className="TermListP">
                It is also agreed between the parties that arbitration
                proceedings would be conducted in English only and in no other
                language. That in the event of such appointed sole Arbitrator is
                unable to continue with such Arbitral proceedings for any reason
                whatsoever, a new sole Arbitrator shall be appointed by mutual
                consent of the Parties who shall continue with the Arbitral
                proceedings from the stage where the proceedings were left by
                his predecessor.{" "}
              </p>
              <p className="TermListP">
                That the Courts at Delhi shall have exclusive jurisdiction
                (subject to the arbitration proceedings which are to be also
                conducted at Delhi) over any or all disputes arising out of this
                agreement and the parties hereby submit themselves to the
                jurisdiction of such Courts and/or Tribunals.
              </p>
              <p className="TermListP">
                Viewing of videos should be within reasonable limit. It is on
                discretion of AEPL to put limit on this.
              </p>
              <p className="TermListP">
                AEPL, on its discretion, block access to any User without any
                prior notice.
              </p>
              <p className="TermListP">
                AEPL is not liable for any poor result of Users.
              </p>
              <p className="TermListP">
                AEPL will try its best to prevent technical issues, portal
                downtime and mistakes in content, but it cannot be held liable
                for those.
              </p>
              <p className="TermListP">
                You cannot copy, download or circulate the content by any means
                (print screen, download, video recording etc.), it is violation
                of the copyrights of AEPL.
              </p>
              <p className="TermListP">
                Any attempt to hack or gain unauthorized access is not permitted
                and is punishable under India Cyber laws.
              </p>
              <p className="TermListP">
                The video lecture content has been delivered & recorded in
                English medium for the comfort and understanding of User(s) from
                all quarters of the country. Rest of the course content (reading
                material, quizzes etc.) is also in English. Any complaints,
                cancellations or refund applications with respect to language of
                video lecture delivery or course content will not be
                entertained. User is advised to go through sample videos on{" "}
                <a href={process.env.REACT_APP_PUBLIC_URL}>
                  {process.env.REACT_APP_PUBLIC_URL}
                </a>{" "}
                before the User go ahead with admission & fee payment
              </p>
              <p className="TermListP">
                Once the Aakash PG Plus product/service is purchased, no refund
                request will be entertained
              </p>
              <p className="TermListP">
                Supplementary Agreements, Applicable Law
              </p>
              <p className="TermListP">
                Any supplementary agreement requires the written form.
              </p>
              <p className="TermListP">
                The individual pages of the Website are operated and
                administered by AEPL and/or its affiliates. The pages comply
                with the law applicable in INDIA where AEPL has its business
                residence. AEPL makes no representation that information, data
                and/or documentation on the Website are appropriate or available
                for viewing or downloading at locations outside India. If User
                accesses the Website from outside India, they are exclusively
                responsible for compliance with all applicable local laws.
                Access to Website's information / documentation from countries
                where such content is unlawful is prohibited. In this case and
                where User seeks to do business with AEPL, the User should
                contact the AEPL, at its New Delhi office.
              </p>
              <p className="TermListP">
                These Terms & conditions shall be governed by Indian Laws and
                all disputes relating to or in connection with these Terms &
                conditions or their subject matter shall be resolved in
                accordance with - the laws of India, to the exclusion of its
                conflict of laws / rules.{" "}
              </p>
            </li>
            <li>
              <p>
                <strong>15. Publicity</strong>
              </p>
              <p className="TermListP">
                AEPL reserves the right to use the single / group photograph(s)
                and name of the User for publicity in all kinds of media, if the
                User secures position / rank or succeeds in Medical Entrance
                Exams or any other test/exam in India or at international level
                at any time. In addition to the photograph, AEPL also reserves
                the right to record video-audio testimonial of the User for the
                purpose of referencing or promotion, as required by the AEPL at
                any stage during and/or after the completion of the course for
                the purpose of publicity in all kinds of media. No separate
                permission will be taken from User for the same
              </p>
              <p className="TermListP">
                Any change of address or phone numbers should be notified to the
                AEPL without any delay. If the dispatch is returned due to
                change of address or any reason whatsoever, Rs. 500 will be
                charged for sending the dispatch again.
              </p>
              <p className="TermListP">
                End Use-The educational content provided through Website/ Aakash
                PG Plus product/services is Licensed Educational Content for
                personal, single user, non-commercial use by the registered User
                only. Without limiting the foregoing, the Licensed Educational
                Content shall not be used, distributed or otherwise exploited
                for commercial advantage or private monetary compensation under
                the End Use License. The following are types of uses that AEPL
                expressly defines as "commercial use"
              </p>
              <ul>
                <li>
                  <p className="TermListP">
                    <span className="listnumber">15.1.</span> the sale or rental
                    of (i) any part of the Licensed Educational Content, (ii)
                    any derivative works based at least in part on the Licensed
                    Educational Content, or (iii) any collective work that
                    includes any part of the Licensed Educational Content
                  </p>
                </li>
                <li>
                  <p className="TermListP">
                    <span className="listnumber">15.2.</span> providing
                    training, support, or editorial Aakash PG Plus services that
                    use or reference the Licensed Educational Content in
                    exchange for a fee or without a fee, and sharing of the
                    Aakash PG Plus product/services/Tablet/SD card with the
                    non-registered User(s), with or without a fee.
                  </p>
                </li>
              </ul>
              <p className="TermListP">
                In case any use of the Licensed Educational Content of AEPL for
                commercial use or in violation of End Use License comes to the
                knowledge of the AEPL, AEPL reserves the right to disable the
                User account, forfeit the entire fee/charges paid by that User
                till that date and to initiate the legal proceedings against
                misuse of the Licensed Educational Content and the infringement
                of the Intellectual Property Rights (IPR) of the AEPL.
              </p>
              <p className="TermListP">
                If any customer fails to receive the SD Card/Tablet (if offered
                by Aakash PG Plus) within 15 days from the date of dispatch,
                kindly inform the AEPL telephonically 9289680055 or by writing
                an email at{" "}
                <a href="mailto:pgpluscustomercare@aesl.in">
                  pgpluscustomercare@aesl.in
                </a>{" "}
              </p>
            </li>
          </ul>}

          {/* iOS TnC */}
          {isIOS && <ul>
            <li>
              <p>
                <strong>1. Scope</strong>
              </p>
              <p className="TermListP">
                Use of the Aakash EduTech Private Limited (hereinafter referred to as "AEPL") website at{" "}
                <a href={process.env.REACT_APP_PUBLIC_URL}>
                  {process.env.REACT_APP_PUBLIC_URL}
                </a>{" "}
                (hereinafter referred to as "Website") or Aakash PG Plus App (hereinafter referred to as "App")is subject to these terms and conditions of use. Users who do not accept these terms & conditions are not authorised to use or continue using this website or app. We may amend these terms & conditions at any time and any revised version will be effective immediately that it is displayed on this website. This website uses cookies. By using this website / app and agreeing to these terms and conditions, you consent to AEPL's use of cookies in accordance with the terms of AEPL's privacy policy. In the event of any comments or questions concerning these terms & conditions, please contact us by e-mailing at{" "}
                <a href="mailto:pgpluscustomercare@aesl.in">
                  pgpluscustomercare@aesl.in
                </a>{" "}
                or by writing to us at Aakash EduTech Private Limited, Aakash Tower, 8, Pusa Road, New Delhi - 110005, India.
              </p>
            </li>
            <li>
              <p>
                <strong>2. Aakash PG Plus product/services</strong>
              </p>
              <ul>
                <li>
                  <p className="TermListP">
                    <span className="listnumber">2.1.</span>
                    Aakash PG Plus product/services (Aakash PG Plus product/services shall mean & include video lectures, QBank/Test Series & Notes are services offered by AEPL for the student/users preparing for the Medical PG Entrance exams.
                  </p>
                </li>
                <li>
                  <p className="TermListP">
                    <span className="listnumber">2.2.</span>
                    Aakash PG Plus product/services are available only to the registered users (“Users") on a single user basis, and abiding by the applicable terms and conditions. Aakash PG Plus products/services are personal in nature and cannot be assigned or transferred to or shared with any other person other than the registered users.
                  </p>
                </li>
              </ul>
            </li>
            <li>
              <p>
                <strong>
                  3. Access to the Aakash PG Plus product/services
                </strong>{" "}
                is subject to these terms and conditions.
              </p>
              <ul>
                <li>
                  <p className="TermListP">
                    <span className="listnumber">3.1.</span> Registration, OTP:
                    <br />
                    <span className="TermListContent">
                      For registration the User shall give accurate information and, where such information changes over time, update such information (to the extent possible: online) without undue delay. The User shall ensure that his/her mobile number, as provided to{" "}
                      <a href="mailto:pgpluscustomercare@aesl.in">
                        pgpluscustomercare@aesl.in
                      </a>{" "}
                      (User is redirected to these links via the Website based on the Aakash PG Plus the User is interested in), is updated at all times and an address at which the User can be contacted.
                    </span>
                    <span className="TermListContent">
                      Any change of address or phone numbers should be notified to{" "}
                      <a href="mailto:pgpluscustomercare@aesl.in">
                        pgpluscustomercare@aesl.in
                      </a>{" "}
                      through e-mail without any delay. If you are directly registering as a User of this website, you represent and warrant that you are at least 18 years of age and that you possess the legal right and capacity to use Aakash PG Plus product/services in accordance with the stated terms and usage policies. In cases, where a minor below the age of 18 years of age, wants to use this website, such a user shall duly register himself through his parent/guardian and such a parent/guardian hereby agrees to accordingly register and supervise usage by, and be responsible for the action of any such minors who use the computer and/or OTP to access the site. The parent/guardian shall enter into this agreement on behalf of such minor and bind himself/herself in accordance with all terms and conditions herein.
                    </span>
                    <span className="TermListContent">
                      By registering yourself as a User to avail the Aakash PG Plus product/services on this website, it will be deemed that you have read, understood and agreed to fully abide by all the terms and conditions of the Website as contained herein.
                    </span>
                    <span className="TermListContent">
                      AEPL reserves the right, in its sole discretion, to terminate or refuse all or part of its Aakash PG Plus product/services, for any reason without notice to you. AEPL particularly reserves the right to determine certain web pages, which were previously freely accessible, subject to registration. AEPL is entitled, at any time and without obligation to give reasons, to deny the user the right to access the product / services  by blocking its User Data (as defined below), in particular, if the User uses false data for the purpose of registration; violates the Terms & conditions or neglects its duty of care with regard to Data; violates any applicable laws in the access to or use of the AEPL Website or did not use the AEPL Website for a longer period.
                    </span>
                    <span className="TermListContent">
                      By registering on the Website, you agree to:
                    </span>
                    <ul>
                      <li>
                        <p className="TermListP">
                          <span className="listnumber">3.1.1.</span>Make your contact details available to AEPL so that you may be contacted by AEPL for education information through email, telephone and SMS.
                        </p>
                      </li>
                      <li>
                        <p className="TermListP">
                          <span className="listnumber">3.1.2.</span>Receive promotional mails/special offers from the Website or any of its partner websites, if any, and/or
                        </p>
                      </li>
                      <li>
                        <p className="TermListP">
                          <span className="listnumber">3.1.3.</span>{" "}
                          <span>
                            be contacted by AEPL in accordance with the privacy settings set by you.
                          </span>
                        </p>
                        <p className="TermListP">
                          Upon registration, the User will be provided with an access through phone number & OTP or code, comprising a User ID and OTP (“User Data”). The User Data allows the User to view or change its data or, as applicable, to withdraw its consent to data processing.
                        </p>
                        <p className="TermListP">The User shall ensure that User Data is not accessible by third parties and is liable for all transactions and other activities carried out under its User Data. At the end of each online session, the User shall log-off from the websites. If and to the extent the User becomes aware that third parties are misusing their data, the User shall notify AEPL thereof without undue delay in writing, or, as the case may be, by e-mail.</p>
                        <p className="TermListP">After receipt of the notice as mentioned above, AEPL will deny access to the product / services  under such User Data. Access by the User will only be possible again upon the User’s application to the Website or upon new registration. Please note that the OTP generated by you for accessing the Website should not be shared by you with anyone else at any time. Please also note that especially with regard to the Aakash PG Plus product/services, the same have been licensed to you by AEPL for your own use only and you cannot share your User data or OTP with others or allow others to use Aakash PG Plus product/services. This will be a gross violation of the license terms governing Aakash PG Plus product/services.</p>
                      </li>
                    </ul>
                  </p>
                </li>
              </ul>
            </li>
            <li>
              <p>
                <strong>4. Rights of Use to Information, Website, and Documentation</strong>
              </p>
              <ul>
                <li>
                  <p className="TermListP">
                    <span className="listnumber">4.1.</span>
                    The right to use the Aakash PG Plus product/services on the Website is on revocable license/permission granted by the AEPL to the User. The License is non-exclusive and non-transferable which may not be sublicensed to use the information, website and documentation made available to the User on or via the Website to the extent agreed or in the event of no such agreement to the extent of the purpose intended by AEPL in making same available to the User on the terms and conditions contained herein. Except the usage of the Aakash PG Plus product/services during the license period, the registered User shall not have any right or title over the Website or any of its Content.
                  </p>
                </li>
                <li>
                  <p className="TermListP">
                    <span className="listnumber">4.2.</span>
                    If you provide any information that is untrue, inaccurate, not up-to-date or incomplete, or if AEPL has reasonable grounds to suspect that such information is untrue, inaccurate, not current or incomplete, AEPL has the right to suspend or terminate your account and refuse any and all current or future use of the Aakash PG Plus product/services or any portion thereof.
                  </p>
                </li>
                <li>
                  <p className="TermListP">
                    <span className="listnumber">4.3.</span>
                    Information, data and documentation shall not be distributed by the User to any third party at any time nor it shall be rented or in any other way made available. Users shall not modify the website or documentation nor shall it disassemble, reverse engineer or decompile the website or separate any part thereof.
                  </p>
                </li>
                <li>
                  <p className="TermListP">
                    <span className="listnumber">4.4.</span>
                    Except for Private use and non-commercial use, none of the video lectures, QBank/Test Series & Notes shall be copied, reproduced, distributed, republished, downloaded, displayed, posted electronically or mechanically, transmitted, recorded in any manner, photocopied, or reproduced without prior written consent of AEPL. Any other use of content on this website, including any commercial use, reproduction for purposes other than that noted above, modification, distribution, or republication without the prior written consent of AEPL is strictly prohibited and shall violate copyright or trademark laws.
                  </p>
                </li>
              </ul>
            </li>
            <li>
              <p>
                <strong>5. Duties of the User</strong>
              </p>
              <ul>
                <li>
                  <p className="TermListP">
                    <span className="listnumber">5.1.</span>AEPL can ask for User's photograph, roll number, admit card copy etc. AEPL reserves the right to use the photograph for publicity in case the User secures position / succeeds in Medical Entrance Exams such as NEET PG, INICET, FMGE, NExT etc. The User shall provide photographs at the time of registration or can be provided later as well.
                  </p>
                  <p className="TermListP">
                    <span className="listnumber">5.2.</span>Users enrolled with AEPL will have to provide a photocopy of Admit Card of all Entrance Exams including Medical Entrance Exam as soon as they receive admit cards from the exam conducting body.
                  </p>
                  <p className="TermListP">
                    <span className="listnumber">5.3.</span>AEPL reserves the right to make any alteration in its Aakash PG Plus product /services /Website without any prior notice to anybody. The decision of the AEPL will be final and binding on the Users.
                  </p>
                  <p className="TermListP">
                    <span className="listnumber">5.4.</span>In order to use the Aakash PG Plus services / product, you further agree not to:
                    <ul>
                      <li>
                        <p className="TermListP">
                          <span className="listnumber">5.4.1.</span>Use data and material for commercial gain (including, but not limited to teaching, group usage etc.). The use of data and material for commercial gain is not permitted and is a violation of the copyrights of the AEPL.
                        </p>
                      </li>
                      <li>
                        <p className="TermListP">
                          <span className="listnumber">5.4.2.</span>Create a false identity or impersonate another person or entity in any way.
                        </p>
                      </li>
                      <li>
                        <p className="TermListP">
                          <span className="listnumber">5.4.3.</span>Restrict, discourage or inhibit any person from using the Aakash PG Plus services / product, disclose personal information about a third person on or through Aakash PG Plus services / product / website or obtained from Aakash PG Plus services / product / website without the consent of such person or collect information about Users of the AEPL's website.
                        </p>
                      </li>
                      <li>
                        <p className="TermListP">
                          <span className="listnumber">5.4.4.</span>Undertake, cause, permit or authorize the modification, creation of derivative works, translation, reverse engineering, decompiling, disassembling or hacking of any aspect of the Aakash PG Plus services / product / website or any part thereof, or attempt to do any of the foregoing, except and solely to the extent permitted by these Terms, the authorized features of the Aakash PG Plus services / product / website, or by law, or otherwise attempt to use or access any portion of the Aakash PG Plus services/product / website other than as intended by AEPL.
                        </p>
                      </li>
                      <li>
                        <p className="TermListP">
                          <span className="listnumber">5.4.5.</span>Gain unauthorized access to the Aakash PG Plus services/product, accessible to other Users' accounts, names or personally identifiable information, or to other computers or websites connected or linked to the Aakash PG Plus product/services.
                        </p>
                      </li>
                      <li>
                        <p className="TermListP">
                          <span className="listnumber">5.4.6.</span>Remove, disable, damage, circumvent or otherwise interfere with any security-related features of the website, features that prevent or restrict the use or copy of any part of the website or any content accessible on or through website, or features that enforce limitations on the use of the website or any content accessible on or through website.
                        </p>
                      </li>
                      <li>
                        <p className="TermListP">
                          <span className="listnumber">5.4.7.</span>Violate any applicable central law or state law or local laws or regulations or these Terms.
                        </p>
                      </li>
                      <li>
                        <p className="TermListP">
                          <span className="listnumber">5.4.8.</span>Assist or permit any persons in engaging in any of the activities described above. Any unauthorized or prohibited use of the Aakash PG Plus services / product / website or AEPL Content may subject the offender to civil liability and criminal prosecution under applicable central law or state law or local laws.
                        </p>
                      </li>
                    </ul>
                  </p>
                  <p className="TermListP">
                    <span className="listnumber">5.5.</span>You represent, warrant, covenant and undertake that you shall not host, display, upload, modify, publish, transmit, update or share any information on the Website, that:
                    <ul>
                      <li>
                        <p className="TermListP">
                          <span className="listnumber">5.5.1.</span>Belongs to another person and to which you do not have any right;
                        </p>
                      </li>
                      <li>
                        <p className="TermListP">
                          <span className="listnumber">5.5.2.</span>Is grossly harmful, harassing, blasphemous, defamatory, obscene, pornographic, paedophilic, libellous, invasive of another's privacy, hateful, or racially, ethnically objectionable, disparaging, relating or encouraging money laundering or gambling, or otherwise unlawful in any manner whatever;
                        </p>
                      </li>
                      <li>
                        <p className="TermListP">
                          <span className="listnumber">5.5.3.</span>Harm minors in any way,
                        </p>
                      </li>
                      <li>
                        <p className="TermListP">
                          <span className="listnumber">5.5.4.</span>Infringes any patent, trademark, copyright or other proprietary rights;
                        </p>
                      </li>
                      <li>
                        <p className="TermListP">
                          <span className="listnumber">5.5.5.</span>Violates any law for the time being in force;
                        </p>
                      </li>
                      <li>
                        <p className="TermListP">
                          <span className="listnumber">5.5.6.</span>Deceives or misleads the addressee about the origin of such messages or communicates any information which is grossly offensive or menacing in nature;
                        </p>
                      </li>
                      <li>
                        <p className="TermListP">
                          <span className="listnumber">5.5.7.</span>Impersonate another person;
                        </p>
                      </li>
                      <li>
                        <p className="TermListP">
                          <span className="listnumber">5.5.8.</span>Contains software viruses or any other computer code, files or programs designed to interrupt, destroy or limit the functionality of any computer resource;
                        </p>
                      </li>
                      <li>
                        <p className="TermListP">
                          <span className="listnumber">5.5.9.</span>Threatens the unity, integrity, defence, security or sovereignty of India, friendly relations with foreign states, or public order or causes incitement to the commission of any cognizable offence or prevents investigation of any offence or is insulting any other nation.
                        </p>
                      </li>
                    </ul>
                  </p>
                  <p className="TermListP">
                    <span className="listnumber">5.6.</span>You further represent, warrant, covenant and undertake that your use of the Website shall not:
                    <ul>
                      <li>
                        <p className="TermListP">
                          <span className="listnumber">5.6.1.</span>Violate any applicable local, provincial, state, national or international law, statute, ordinance, rule or regulation;
                        </p>
                      </li>
                      <li>
                        <p className="TermListP">
                          <span className="listnumber">5.6.2.</span>Interfere with or disrupt computer networks connected to the website;
                        </p>
                      </li>
                      <li>
                        <p className="TermListP">
                          <span className="listnumber">5.6.3.</span>Impersonate any other person or entity, or make any misrepresentation as to your employment by or affiliation with any other person or entity,
                        </p>
                      </li>
                      <li>
                        <p className="TermListP">
                          <span className="listnumber">5.6.4.</span>Forge headers or in any manner manipulate identifiers in order to disguise the origin of any user information;
                        </p>
                      </li>
                      <li>
                        <p className="TermListP">
                          <span className="listnumber">5.6.5.</span>Upload, post, transmit, publish, or distribute any material or information for which you do not have all necessary rights and licenses;
                        </p>
                      </li>
                      <li>
                        <p className="TermListP">
                          <span className="listnumber">5.6.6.</span>Upload, post, transmit, publish or distribute any material which infringes, violates, breaches or otherwise contravenes the rights of any third party, including any copyright, trademark, patent, rights of privacy or publicity or any other proprietary right;
                        </p>
                      </li>
                      <li>
                        <p className="TermListP">
                          <span className="listnumber">5.6.7.</span>Interfere with or disrupt the use of the Website by any other user, nor "stalk", threaten, or in any manner harass another user,
                        </p>
                      </li>
                      <li>
                        <p className="TermListP">
                          <span className="listnumber">5.6.8.</span>Upload, post, transmit, publish, or distribute any material or information which contains a computer virus, or other code, files or programs intending in any manner to disrupt or interfere with the functioning of the Website, or that of other computer systems,
                        </p>
                      </li>
                      <li>
                        <p className="TermListP">
                          <span className="listnumber">5.6.9.</span>Use the website in such a manner as to gain unauthorized entry or access to the computer systems of others;
                        </p>
                      </li>
                      <li>
                        <p className="TermListP">
                          <span className="listnumber">5.6.10.</span>Upload, post, transmit, publish or distribute any material or information which constitutes or encourages conduct that would constitute a criminal offence, give rise to other liability, or otherwise violate applicable law,
                        </p>
                      </li>
                      <li>
                        <p className="TermListP">
                          <span className="listnumber">5.6.11.</span>Upload, post, transmit, publish, or distribute any material or information that is unlawful, or which may potentially be perceived as being harmful, threatening, abusive, harassing, defamatory, libellous, vulgar, obscene, or racially, ethnically, or otherwise objectionable;
                        </p>
                      </li>
                      <li>
                        <p className="TermListP">
                          <span className="listnumber">5.6.12.</span>Reproduce, copy, modify, sell, store, distribute or otherwise exploit for any commercial purposes the Website, or any component thereof (including, but not limited to any materials or information accessible through the Website);
                        </p>
                      </li>
                      <li>
                        <p className="TermListP">
                          <span className="listnumber">5.6.13.</span>Use any device, software or routine to interfere or attempt to interfere with the proper working of the Website;
                        </p>
                      </li>
                      <li>
                        <p className="TermListP">
                          <span className="listnumber">5.6.14.</span>Take any action that imposes an unreasonable or disproportionately large load on the Website infrastructure;
                        </p>
                      </li>
                    </ul>
                  </p>
                  <p className="TermListP">
                    <span className="listnumber">5.7.</span>Any interpretation of test results or study material other than as a tool of learning and enhancing knowledge; or
                    <br />
                    The tests and exercises are created for you to test your understanding of concepts and are not to be interpreted as a sample question paper for any examinations.
                  </p>
                  <p className="TermListP">
                    <span className="listnumber">5.8.</span>Any content uploaded by you on the Website shall be subject to relevant laws and may be disabled and/or may be subject to investigation under appropriate laws. Furthermore, if you are found to be in non-compliance with the laws and regulations, these terms, or the privacy policy of the Website, AEPL may terminate your account/block your access to the Website and AEPL reserve the right to remove any non-compliant content uploaded by you on the Website.
                  </p>
                  <p className="TermListP">
                    <span className="listnumber">5.9.</span>AEPL reserves the right to remove any content or links that allegedly infringes any other person's copyright at any point of time
                  </p>
                </li>
              </ul>
            </li>
            <li>
              <p>
                <strong>6. Intellectual Property</strong>
              </p>
              <p className="TermListP">
                Notwithstanding any content in these Terms & conditions, information, brand names and other Content of the Website may not be changed, copied, reproduced, sold, rented, used, supplemented or otherwise used in any other way without the prior written permission of AEPL.
              </p>
              <p className="TermListP">
                Except for the rights of use and other rights expressly granted herein, no other rights are granted to the User nor shall any obligation be implied requiring the grant of further rights. Any and all copyright, patent rights and licenses are expressly excluded.
              </p>
              <p className="TermListP">
                AEPL may, without charge, use any ideas or proposals stored by a User on the Website for the development, improvement and sale of its Aakash PG Plus product/services.
              </p>
              <p className="TermListP">
                All the Content on the website are proprietary to AEPL except the data / queries posted / uploaded by the users.
              </p>
              <p className="TermListP">
                All material available on the Website including but not limited to, its design, text, graphics, screen shots, files and the selection and arrangement thereof is protected by copyright laws.
              </p>
              <p className="TermListP">
                The owners of the intellectual property, copyrights and trademarks are AEPL, its affiliates or third-party licensors. Any modification, copying, reproduction, republishing, uploading, posting, transmitting or distributing any material on this website including text, graphics, code and/or software is expressly prohibited.
              </p>
              <p className="TermListP">
                No recourse shall be taken against AEPL for any alleged or actual infringement or misappropriation of any proprietary rights in your communications to AEPL. At any stage, if it is believed that the Content appearing on this website constitutes copyright infringement of another person's rights, the User may communicate the same to us.
              </p>
              <p className="TermListP">
                Notice of Copyright Infringement: We are not liable for any infringement of copyright arising out of materials posted on or transmitted through the Website, or items posted on the Website, by end users or any other third parties. In the event, you have any grievance in relation to any content uploaded on the Website, you may contact our Grievance Officer, or write at the following address: e-mailing at{" "}
                <a href="mailto:pgpluscustomercare@aesl.in">
                  pgpluscustomercare@aesl.in
                </a>{" "}
                or can write to us at Aakash EduTech Private Limited, Aakash Tower, 8, Pusa Road, New Delhi - 110005, India.
              </p>
              <p className="TermListP">
                We request you to please provide the following information in your complaint: - (a) A physical or electronic signature of a person authorized to act on behalf of the copyright owner for the purposes of the complaint. (b) Identification of the copyrighted work claimed to have been infringed. (c) Identification of the material on our website that is claimed to be infringing or to be the subject of infringing activity. (d) The address, telephone number or e-mail address of the complaining party. (e) A statement that the complaining party has a good-faith that use of the material in the manner complained of is not authorized by the copyright owner, its agent or the law. (f) A statement, under penalty of perjury, that the information in the notice of copyright infringement is accurate, and that the complaining party is authorized to act on behalf of the owner of the right that is allegedly infringed.
              </p>
            </li>
            <li>
              <p>
                <strong>7. Hyperlinks/ Ask an Expert' Section</strong> (if
                applicable)
              </p>
              <p className="TermListP">
                The Website may contain hyperlinks to the web pages of third parties. AEPL shall have no liability for the Content of such web pages and does not make representations about or endorse such web pages or their Content as its own, as AEPL does not control the information on such web pages and is not responsible for the Content and information given thereon. The use of such web pages shall be at the sole risk of the User.
              </p>
              <p className="TermListP">
                Some Content on the site may belong to third parties. Such Content has been reproduced after taking prior permission from the said party and the copyright of such Content would remain exclusively with the said third party. AEPL shall not be responsible for any mistakes which might appear in such Content.
              </p>
              <p className="TermListP">
                Please note that the answers under the section "Ask an Expert" (if available) or the tests/notes uploaded in the 'Upload Tests & Notes' sub-section (if available) may be uploaded by User directly. Therefore, AEPL will not be liable for the content of the same.
              </p>
              <p className="TermListP">
                Further, AEPL may certify an answer posted by a User to be correct or not. This however does not mean that AEPL has checked whether the answer has or has not been copied by such User from any other source without authorisation. AEPL shall surely look into any concern of this nature that is brought to its attention.
              </p>
            </li>
            <li>
              <p>
                <strong>8. Data Privacy Protection</strong>
              </p>
              <p className="TermListP">
                For collection, use and processing of personally identifiable data of the User of the Website, AEPL shall comply with applicable laws on data privacy protection and the Website Data Protection Privacy Policy, which is available as per hyperlink on the Website
              </p>
            </li>
            <li>
              <p>
                <strong>9. No Liability:</strong>
              </p>
              <p className="TermListP">
                It is for the User to see whether he/she is eligible for a certain Medical Entrance examination or not. The AEPL does not hold itself responsible if a User's admission form cannot be forwarded or is rejected by the examination body on any ground whatsoever.
              </p>
              <p className="TermListP">
                The responsibility of getting admission form forwarded to the examining body is that of the User himself/herself.
              </p>
              <p className="TermListP">
                It is the endeavour of AEPL to ensure that the information provided on the Website is accurate but does not guarantee or warrant its accuracy, adequacy, correctness, validity, completeness or suitability for any purpose, and accepts no responsibility with respect to the information on the Website. Use of this website, by implication, means that you have gone through and agreed to abide by the Terms and Conditions and the Disclaimers of this website. AEPL does not claim that the information downloaded is up to date, correct and error free or that the servers that make AEPL Website available, are free of viruses or harmful components. Any reliance on the service or database available through this service is at the User's own risk. Though due care has been taken to make the database completely reliable and error-free, AEPL claims exemption from any liability arising out of any such error in the database.
              </p>
              <p className="TermListP">
                AEPL disclaims all responsibility for any loss, injury, liability or damage of any kind resulting from and arising out of, or any way related to:
                <ul>
                  <li>
                    <p className="TermListP">
                      <span className="listnumber">9.1.</span>Any errors in or omissions from the Website and its content, including but not limited to technical inaccuracies and typographical errors.
                    </p>
                  </li>
                  <li>
                    <p className="TermListP">
                      <span className="listnumber">9.2.</span>Any third party websites or content therein directly or indirectly accessed through links in the Website, including but not limited to any errors in or omissions there from
                    </p>
                  </li>
                  <li>
                    <p className="TermListP">
                      <span className="listnumber">9.3.</span>The unavailability of this website or any portion thereof.
                    </p>
                  </li>
                  <li>
                    <p className="TermListP">
                      <span className="listnumber">9.4.</span>The use of any equipment or software in connection with the Website by the User.
                    </p>
                  </li>
                </ul>
              </p>
              <p className="TermListP">
                Aakash EduTech Private Limited may also contact the User through SMS, WhatsApp, email, and call to give notifications on various important updates. Therefore, User holds Aakash EduTech Private Limited non-liable for any liabilities including financial penalties, damages, expenses, in case the User's mobile number is registered with Do Not Call (DNC) database.
              </p>
              <p className="TermListP">
                <span className="TermTextUnderLine">
                  Liability for defects of title or quality
                </span>
              </p>
              <p className="TermListP">
                Insofar as any information or documentation is made available at no cost, any liability for defects as to quality or title of the information and documentation especially in relation to the correctness or absence of defects or the absence of claims or third-party rights or in relation to completeness and/or fitness for purpose are excluded except for cases involving wilful misconduct or fraud.
              </p>
              <p className="TermListP">
                The information on the Website may contain specifications or general descriptions related to the technical possibilities of individual Aakash PG Plus product/services which may not be available in certain cases (e.g. due to Aakash PG Plus product/services' changes). The required performance of the Aakash PG Plus product/services shall therefore be mutually agreed in each case at the time of purchase
              </p>
              <p className="TermListP">
                <span className="TermTextUnderLine">
                  Other Liability, Viruses
                </span>
              </p>
              <p className="TermListP">
                The liability of the Website for defects in relation to quality and title shall be determined in accordance with the provisions of these Terms & conditions. Any further liability of AEPL is excluded unless required by law, e.g. under the Act on Product Liability or in cases of wilful misconduct, gross negligence, personal injury or death, failure to meet guaranteed characteristics, fraudulent concealment of a defect or in case of breach of fundamental contractual obligations. The damage in case of breach of fundamental contractual obligations is limited to the contract-typical, foreseeable damage if there is no wilful misconduct or gross negligence.{" "}
              </p>
              <p className="TermListP">
                Although AEPL makes every endeavour to keep the Website free from viruses, AEPL cannot make any guarantee that it is virus-free. The User shall, for its own protection, take the necessary steps to ensure appropriate security measures and shall utilize a virus scanner before downloading any information, software or documentation.
              </p>
              <p className="TermListP">
                However, this does not intend nor imply any changes to the burden of proof to the User's disadvantage.
              </p>
              <p className="TermListP">
                <span className="TermTextUnderLine">
                  Limitation of Liability:
                </span>
              </p>
              <p className="TermListP">
                Under no circumstances shall AEPL be liable to any User for:
                <ul>
                  <li>
                    <p className="TermListP">
                      Loss, injury, claim, liability or damages of any kind resulting from the use of or the inability to use the Aakash PG Plus product/services / data in the Website, even if AEPL had been advised of the possibility of such damages and regardless of the form of action, whether in contract, stated or otherwise.
                    </p>
                  </li>
                  <li>
                    <p className="TermListP">
                      Special, direct, incidental, punitive, exemplary or consequential damages of any kind whatsoever in any way due, resulting from or arising in connection with the use of or the inability to use the website or its content/data.
                    </p>
                  </li>
                  <li>
                    <p className="TermListP">
                      Claim attributable to errors, omission or inaccuracies in or destructive properties of any information.
                    </p>
                  </li>
                </ul>
              </p>
              <p className="TermListP">
                Any material downloaded and used shall be at the risk of the user and no Aakash PG Plus product/services utilised by this Website shall create any warranty. AEPL shall not be responsible, if any information/page is downloaded from AEPL and after downloading complete/partial, text/information is altered/removed/obscured contained therein.
              </p>
              <p className="TermListP">
                AEPL and/or its respective suppliers make no representations about the suitability, reliability, availability, timelines, of the Aakash PG Plus product / services contained on the Website for any purpose. All Aakash PG Plus product/ services are provided "as is" without warranty of any kind, and/or its respective suppliers hereby disclaim all warranties and conditions with regard to this information, Aakash PG Plus product/ services including all implied warranties and conditions of merchantability, fitness for a particular purpose, title and non-infringement.
              </p>
              <p className="TermListP">
                In no event shall AEPL and/or its suppliers be liable for any direct, indirect, punitive, incidental, special, consequential damages or any damages whatsoever including, without limitation, damages for loss of use, data or profits, arising out of or in any way connected with the use or performance of the AEPL Website, with the delay or inability to use the AEPL Website or related Aakash PG Plus product/services, the provision of or failure to provide Aakash PG Plus product/services, or for any information, software, Aakash PG Plus product / services obtained through the AEPL Website, or otherwise arising out of the use of AEPL Website, whether based on contract, negligence, strict liability or otherwise, even if AEPL or any of its suppliers has been advised of the possibility of damages.
              </p>
              <p className="TermListP">
                <span className="TermTextUnderLine">Indemnification:</span>
              </p>
              <p className="TermListP">
                As a User of this Website, you agree to protect and fully compensate AEPL and its domain's associates, such as, service providers and technology partners, from any and all third party claims, liabilities, damages, expenses and costs, including, but not limited to, all legal expenses, arising from your use of Aakash PG Plus product/services on this domain, your violation of the terms or your infringement by any other use of your account of any intellectual property or other right of anyone.
              </p>
            </li>
            <li>
              <p>
                <strong>10. Third Party Advertising</strong>
              </p>
              <p className="TermListP">
                There may be third party information, advertisement, and schemes displayed through this website. AEPL disclaims, to the fullest extent permissible, the correctness, viability, availability, merchantability or fitness of such information, advertisement and scheme. AEPL hereby declares that the advertisers and their clients etc. are neither its agent, partner nor principal and AEPL does not provide any guarantee or warranty or any representation on behalf of any of the advertisers or their clients.
              </p>
            </li>
            <li>
              <p>
                <strong>11. General</strong>
              </p>
              <p className="TermListP">
                AEPL tries its best, but does not guarantee that the Aakash PG Plus product/services will be error free, or continuously available, or that the service will be free of viruses or other harmful components.
              </p>
              <p className="TermListP">
                This Agreement does not in any manner create any relationship whatsoever as between User and the AEPL, either as a joint venture, partnership, employment, or agency relationship. Performance of this agreement by AEPL is subject to existing laws and legal process in India, and nothing contained in this agreement is in derogation of the rights of AEPL to comply with governmental, court and law enforcement requests or requirements relating to your use of AEPL or information provided to or gathered by AEPL with respect to such use.
              </p>
              <p className="TermListP">
                Unless otherwise specified herein, this Agreement constitutes the entire agreement between the User and AEPL with respect to the AEPL site and it supersedes all prior communications and proposals, whether electronic, oral or written, between the User and AEPL with respect to the AEPL site.
              </p>
              <p className="TermListP">
                AEPL can change terms of usage anytime.
              </p>
              <p className="TermListP">
                AEPL reserves the right to make any change in its data/ material/ Aakash PG Plus product/services without any prior notice to anybody.
              </p>
              <p className="TermListP">
                You agree that  these Terms & Conditions  shall be construed in accordance with the laws in force in India and in case of any dispute or difference between you and AEPL regarding the interpretation of these terms and conditions, non-payment of any claim or any dispute arising out of or in pursuant to these terms and conditions, the same shall be referred to the arbitration within 60 days of either Party notifying the other Party to the Agreement of such dispute, for adjudication under the provisions of Arbitration & Conciliation Act, 1996. Parties hereby mutually agree to appoint sole arbitrator for adjudication of disputes between the Parties whose decision shall be final and binding upon the parties. The sole Arbitrator shall conduct the arbitration proceedings at Delhi only.
              </p>
              <p className="TermListP">
                It is also agreed between the parties that arbitration proceedings would be conducted in English only and in no other language. That in the event of such appointed sole Arbitrator is unable to continue with such Arbitral proceedings for any reason whatsoever, a new sole Arbitrator shall be appointed by mutual consent of the Parties who shall continue with the Arbitral proceedings from the stage where the proceedings were left by his predecessor.
              </p>
              <p className="TermListP">
                That the Courts at Delhi shall have exclusive jurisdiction (subject to the arbitration proceedings which are to be also conducted at Delhi) over any or all disputes arising out of this agreement and the parties hereby submit themselves to the jurisdiction of such Courts and/or Tribunals.
              </p>
              <p className="TermListP">
                Viewing of videos should be within a reasonable limit. It is at the discretion of AEPL to put a limit on this.
              </p>
              <p className="TermListP">
                AEPL, on its discretion, blocks access to any User without any prior notice.
              </p>
              <p className="TermListP">
                AEPL is not liable for any poor result of Users.
              </p>
              <p className="TermListP">
                AEPL will try its best to prevent technical issues, portal downtime and mistakes in content, but it cannot be held liable for those.
              </p>
              <p className="TermListP">
                You cannot copy, download or circulate the content by any means (print screen, download, video recording etc.), it is a violation of the copyrights of AEPL.
              </p>
              <p className="TermListP">
                Any attempt to hack or gain unauthorized access is not permitted and is punishable under India Cyber laws.
              </p>
              <p className="TermListP">
                Supplementary Agreements, Applicable Law
              </p>
              <p className="TermListP">
                Any supplementary agreement requires the written form.
              </p>
              <p className="TermListP">
                The individual pages of the Website are operated and administered by AEPL and/or its affiliates. The pages comply with the law applicable in INDIA where AEPL has its business residence. AEPL makes no representation that information, data and/or documentation on the Website are appropriate or available for viewing or downloading at locations outside India. If User accesses the Website from outside India, they are exclusively responsible for compliance with all applicable local laws. Access to Website's information / documentation from countries where such content is unlawful is prohibited. In this case and where User seeks to do business with AEPL, the User should contact the AEPL, at its New Delhi office.
              </p>
              <p className="TermListP">
                These Terms & conditions shall be governed by Indian Laws and all disputes relating to or in connection with these Terms & conditions or their subject matter shall be resolved in accordance with - the laws of India, to the exclusion of its conflict of laws / rules.
              </p>
            </li>
            <li>
              <p>
                <strong>12. Publicity</strong>
              </p>
              <p className="TermListP">
                AEPL reserves the right to use the single / group photograph(s) and name of the User for publicity in all kinds of media, if the User secures position / rank or succeeds in Medical Entrance Exams or any other test/exam in India or at international level at any time. In addition to the photograph, AEPL also reserves the right to record video-audio testimonial of the User for the purpose of referencing or promotion, as required by the AEPL at any stage during and/or after the completion of the course for the purpose of publicity in all kinds of media. No separate permission will be taken from User for the same
              </p>
              <p className="TermListP">
                Any change of address or phone numbers should be notified to the AEPL without any delay. If the dispatch is returned due to change of address or any reason whatsoever, Rs. 500 will be charged for sending the dispatch again.
              </p>
              <p className="TermListP">
                End Use-The educational content provided through Website/ Aakash PG Plus product/services is Licensed Educational Content for personal, single user, non-commercial use by the registered User only. Without limiting the foregoing, the Licensed Educational Content shall not be used, distributed or otherwise exploited for commercial advantage or private monetary compensation under the End Use License. The following are types of uses that AEPL expressly defines as "commercial use"
              </p>
              <ul>
                <li>
                  <p className="TermListP">
                    <span className="listnumber">12.1.</span>the sale or rental of (i) any part of the Licensed Educational Content, (ii) any derivative works based at least in part on the Licensed Educational Content, or (iii) any collective work that includes any part of the Licensed Educational Content
                  </p>
                </li>
                <li>
                  <p className="TermListP">
                    <span className="listnumber">12.2.</span>providing training, support, or editorial Aakash PG Plus services that use or reference the Licensed Educational Content in exchange for a fee or without a fee, and
                  </p>
                </li>
                <li>
                  <p className="TermListP">
                    <span className="listnumber">12.3.</span>sharing of the  Aakash PG Plus product/services/Tablet/SD card with the non-registered User(s), with or without a fee.
                  </p>
                </li>
              </ul>
              <p className="TermListP">
                In case any use of the Licensed Educational Content of AEPL for commercial use or in violation of End Use License comes to the knowledge of the AEPL,  AEPL reserves the right to disable the User account and to initiate the legal proceedings against misuse of the Licensed Educational Content and the infringement of the Intellectual Property Rights (IPR) of the AEPL.
              </p>
            </li>
          </ul>}
        </div>
      </div>
    </StaticPagesLayout>
  );
}

export default Terms;
