import { React, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  authActions,
  supportLogin,
  isUserLoggedIn,
} from "../../store/authenticate";
import { AlertMsg } from "../common/alert";
import HomeHeader from "../layout/homeHeader";
import PhoneVerified from "./phoneVerified";
import { Loader } from "../common/common";

function SupportLogin() {
  const authState = useSelector((state) => state.auth);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  //when login
  const handleSubmit = () => {
    setLoading(true);
    if (username.trim() == "") {
      dispatch(authActions.showErrorMsg({ msg: "Please enter username!!" }));
      setLoading(false);
      return false;
    }

    if (password.trim() == "") {
      dispatch(authActions.showErrorMsg({ msg: "Please enter password!!" }));
      setLoading(false);
      return false;
    }

    dispatch(supportLogin({ username, password }));
    setLoading(false);
  };

  // when component is unmounted clean states (useeffect is called here only once [] so no reloading state)
  const unmountComponent = () => {
    // setUserusername("");
    // setOtp("");
    setLoading(false);
  };
  // to check if user is already logged in
  useEffect(() => {
    // need to refresh login state bcz login component is out of protected route
    console.log("refreshing support-login");

    if (authState.isLoggedIn) {
      setTimeout(() => {
        navigate("/profile");
      }, 2000);
    }

    return () => {
      unmountComponent();
    };
  }, [authState.isLoggedIn]);

  return (
    <>
      {/* <HomeHeader /> */}
      <div className="container-fluid loginpagebg">
        <div className="row LoginPageHeight mbottomnill">
          <div className="col s12 m5 lrnpd LoginBanner">
            <div className="loginpageleftside">
              <div className="LoginBannerContent ShowMobile">
                <div className="bannerCarausalContent ">
                  <h1>
                    Introducing Aakash
                    <br /> NEET-PG Plus
                  </h1>
                  <p>Your Gateway to Medical PG Prep</p>
                </div>
              </div>
            </div>
          </div>
          <div className="col s12 m7 RemoveMinHeight">
            <div className="loginpagerightside">
              <AlertMsg />
              <div className="loginform">
                <LoginForm
                  username={username}
                  password={password}
                  setUsername={setUsername}
                  setPassword={setPassword}
                  loading={loading}
                  handleSubmit={handleSubmit}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

function LoginForm(props) {
  return (
    <>
      <div className="loginotpheading supportLoginHeading">
        <h1>Support Login</h1>
      </div>

      <div className="row RemoveMargin">
        <form className="col s12">
          <div className="row">
            <div className="col s12 m12">
              <div className="loginotp">
                <div className="input-field col s12">
                  <input
                    id="username"
                    type="text"
                    value={props.username}
                    onChange={(e) => {
                      props.setUsername(e.target.value);
                    }}
                    className="validate"
                  />
                  <label htmlFor="username">Enter Your Username</label>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col s12 m12">
              <div className="loginotp">
                <div className="input-field col s12">
                  <input
                    id="password"
                    type="password"
                    value={props.password}
                    onChange={(e) => {
                      props.setPassword(e.target.value);
                    }}
                    className="validate"
                  />
                  <label htmlFor="password">Enter Your Password</label>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="LoginPageBTNBox">
              {!props.loading && (
                <a className="btn LoginPageBTN"
                className="btn LoginPageBTN"
                onClick={(e) => {
                  e.preventDefault();
                  props.handleSubmit();
                }}
              >
                LOGIN
                </a>
               
              )}
              {props.loading && <Loader />}
            </div>
          </div>
        </form>
      </div>
    </>
  );
}

export default SupportLogin;
