import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

const PhoneVerified = () => {
  //check for redirect query string
  const queryString = useLocation().search || "";
  const navigate = useNavigate();
  const authState = useSelector((state) => state.auth);

  useEffect(() => {
    let myTimeout = setTimeout(redirectUser, 1500)
    return (() => {
      clearTimeout(myTimeout);
    })
  }, []);

  const redirectUser = () => {
    if(authState.fullname && authState.stateId && authState.academicYear && authState.colgId){
      navigate("/dashboard")
    }else{
      navigate("/profile"+queryString)
    }
  }

  return (
    <>
      <div className="loginphonesuccesheading center-align">
        <img
          src={
            process.env.REACT_APP_PUBLIC_URL + "dist/img/phoneverifyicon.png"
          }
        />
        <h1>
          Phone Number
          <br />
          Succesfully Verified
        </h1>
        <div className="LoginPageBTNBox">
          <a
            className="btn LoginPageBTN"
            onClick={redirectUser}
          >
            NEXT
          </a>
        </div>
      </div>
    </>
  );
};

export default PhoneVerified;
