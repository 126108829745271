import Footer from "../layout/footer";
import HomeHeader from "../layout/homeHeader";

function StaticPagesLayout(props) {
  return (
    <div className="App">
      {!window.IS_MOBILE_APP && <HomeHeader />}
      <div className={`container-fluid ${!window.IS_MOBILE_APP ? "tabMargin" : ""}`}>
        <div className="container">{props.children}</div>
      </div>
      {!window.IS_MOBILE_APP && <Footer />}
    </div>
  );
}

export default StaticPagesLayout;
