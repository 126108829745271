import axios from "axios";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ApiUrls } from "../../constants/constants";
import { getStates, getColgList, getGradesList } from "../../helpers/address";
import { getLocalItem, setLocalItem, make_tribyte_user_login, get_platform, getMobileOperatingSystem } from "../../helpers/common";
import { gtm_date_time, gtm_event, webEngageUserAttr } from "../../helpers/google_tracking";
import { authActions, isUserLoggedIn } from "../../store/authenticate";
import { AlertMsg } from "../common/alert";
import { Loader } from "../common/common";

const ProfileMandatoryFields = () => {
  const authState = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [fullName, setFullName] = useState("");
  const [selectedAcademicYear, setSelectedAcademicYear] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const [stateList, setStateList] = useState(null);
  const [colgList, setColgList] = useState(null);
  const [academicYearList, setAcademicYearList] = useState(null);
  const [selectedColg, setSelectedColg] = useState("");
  const [step, setStep] = useState(1);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleNext = () => {
    //validae if both field
    if(!fullName || !selectedAcademicYear || fullName=="undefined" || selectedAcademicYear=="undefined"){
      dispatch(
        authActions.showErrorMsg({ msg: "Please fill all fields!!" })
      );
      return false;
    }

    if(fullName.trim() == "" || selectedAcademicYear == ""){
      dispatch(
        authActions.showErrorMsg({ msg: "Please fill all fields!!" })
      );
      return false;
    }
    

    let namePattern = /^[a-zA-Z ]+$/;
    if (namePattern.test(fullName) === false) {
      dispatch(
        authActions.showErrorMsg({ msg: "Please enter valid name!!" })
      );
      return false;
    }

    //save info in local storage
    setLocalItem('profileFullname',fullName)
    setLocalItem('profileAcademicYear',selectedAcademicYear)
    gtm_event({
      event: "tap_name_grade_signup",
      ...gtm_date_time(),
      platform: get_platform(),
      mobile: authState.mobile || "",
      uid: authState.userId,
      name: fullName,
      grade: selectedAcademicYear
    })

    // Add user attributes in webengage
    let clsName = "";
    for (let i = 0; i < academicYearList.length; i++) {
      if (academicYearList[i].id == selectedAcademicYear) {
        clsName = academicYearList[i].name
        break;
      }
    }

    const [fName, lName] = fullName.split(" ")
    const userAttr = {
      first_name: fName,
      last_name: lName || "",
      email: authState.email || "",
      phone: authState.mobile || "",
      className: clsName || "",
      classId: parseInt(selectedAcademicYear || 0),
      boardName: authState?.profileData?.curriculum_name || "",
      boardId: parseInt(authState?.profileData?.curriculum_id || 0),
      referral: authState?.profileData?.referer || "",
      psId: String(authState?.profileData?.psid || ""),
      ssoUsername: authState?.profileData?.sso_username || "",
      sourceId: parseInt(authState?.profileData?.source_id || 0),
    }
    webEngageUserAttr(authState.userId, userAttr)

    //go to next step
    setStep(2)
  }

  const updateProfileData = (firstName, lastName) => {
    return axios.post(
      `${process.env.REACT_APP_SERVER_URL}userapi/v3/users`,
      {
        state_id: selectedState,
        school_id: selectedColg,
        grade_id: selectedAcademicYear,
        first_name: firstName,
        last_name: lastName,
      },
      { withCredentials: true }
    )
      .then((res) => {
        if(!res.data.status || res.data?.status == 0) return res;

        const isMob = window.IS_MOBILE_APP ? (getMobileOperatingSystem() == "iOS" && typeof window.JSPlatform != "undefined" && typeof window.JSPlatform.loadURL == "function") : false;
        return axios.post(ApiUrls.TribyteCreateUser, {user_id: authState.userId, is_mobile:isMob}, { withCredentials: true })
        .then((result) => {
          console.log("Tribyte create user success", result);
          // Redirect to dashboard
          // make_tribyte_user_login(authState.userId);
            return res;
        }).catch((err) => {
          console.log("Tribyte create user error", err);
          return res;
        });
      })
      .catch((err) => {
        console.log(err);
        return false;
      });
  }

  const handleProfileFieldsSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    //validation
    if(!fullName || !selectedAcademicYear || !selectedState || !selectedColg || fullName=="undefined" || selectedAcademicYear=="undefined" || selectedState=="undefined" || selectedColg=="undefined"){
      dispatch(
        authActions.showErrorMsg({ msg: "Please fill all fields!!" })
      );
      setLoading(false);
      return false;
    }

    if (
      fullName.trim() == "" ||
      selectedAcademicYear == "" ||
      selectedState == "" ||
      selectedColg == ""
    ) {
      dispatch(
        authActions.showErrorMsg({ msg: "Please fill all profile fields!!" })
      );
      setLoading(false);
      setStep(1);
      return false;
    }
    

    //save info in local storage
    setLocalItem('profileSelectedState',selectedState)
    setLocalItem('profileSelectedColg',selectedColg)

    // validation for full name
    var re = new RegExp("^[a-zA-Z ]*$");
    if (!re.test(fullName)) {
      dispatch(
        authActions.showErrorMsg({
          msg: "Only string and space are allowed as full name (No special character)!!",
        })
      );
      setLoading(false);
      setStep(1);
      return false;
    }

    if (fullName.length > 40) {
      dispatch(
        authActions.showErrorMsg({
          msg: "Full name can be maximum of 40 characters!!",
        })
      );
      setLoading(false);
      setStep(1);
      return false;
    }

    let nameParts = fullName.trim().split(" ");
    let firstName = nameParts[0];
    let lastName = "";
    nameParts.forEach((val, index) => {
      if (index >= 1) {
        lastName = lastName + " " + val;
      }
    });
    lastName = lastName.trim();

    gtm_event({
      event: "tap_college_state_signup",
      ...gtm_date_time(),
      platform: get_platform(),
      mobile: authState.mobile || "",
      uid: authState.userId,
      name: fullName,
      grade: selectedAcademicYear,
      state: selectedState,
      college: selectedColg,
    })

    let schoolName = "Foreign Medical College";
    if(selectedColg != "1"){
      for (let i = 0; i < colgList.length; i++) {
        if (colgList[i].id == selectedColg) {
          schoolName = colgList[i].name
          break;
        }
      }
    }

    const userAttr = {
      schoolId: selectedColg || "",
      schoolName: schoolName || "",
      stateId: parseInt(selectedState || 0),
      state: stateList[selectedState] || ""
    }
    webEngageUserAttr(authState.userId, userAttr)

    updateProfileData(firstName, lastName).then((res) => {

      if (!res || res.data.status == "0") {
        setLoading(false);
        dispatch(authActions.showErrorMsg({ msg: 'Some error occured while updating profile details!!' }))
        return false;
      }     
       
      dispatch(isUserLoggedIn())
      console.log('Profile updated!!');
      navigate("/dashboard");
    })

    setTimeout(() => {
      setLoading(false)   
    }, 6000);

  };

  //get state is async function so it returns a promise - so we need to wait promise to resolve
  const setStateDataList = () => {
    (async () => {
      setStateList(await getStates("1"));
    })();
  };

  //get state is async function so it returns a promise - so we need to wait promise to resolve
  const setColgDataList = () => {
    (async () => {
      setColgList(await getColgList(selectedState));
    })();
  };

  //get grades is async function so it returns a promise - so we need to wait promise to resolve
  const setAcademicYearDataList = () => {
    (async () => {
      setAcademicYearList(await getGradesList(selectedState));
    })();
  };

  //set local components state based on global state changes
  const setLocalStateFromGlobalState = () => {
    setIsLoggedIn(authState.isLoggedIn)
    setFullName(authState.fullname ? authState.fullname : "")
    setSelectedAcademicYear(authState.academicYear ? authState.academicYear : "")
    setSelectedState(authState.stateId ? authState.stateId : "")
    setSelectedColg(authState.colgId ? authState.colgId : "")
    if(!authState.fullname){
      setFullName(getLocalItem('profileFullname') ? getLocalItem('profileFullname') : "")
    }
    if(!authState.academicYear){
      setSelectedAcademicYear(getLocalItem('profileAcademicYear') ? getLocalItem('profileAcademicYear') : "")
    }
    if(!authState.academicYear){
      setSelectedState(getLocalItem('profileSelectedState') ? getLocalItem('profileSelectedState') : "")
    }
    if(!authState.academicYear){
      setSelectedColg(getLocalItem('profileSelectedColg') ? getLocalItem('profileSelectedColg') : "")
    }
  }

  // when component is unmounted clean states that are reloading
  const unmountComponent = () => {
    setIsLoggedIn("0")
    setFullName("")
    setSelectedAcademicYear("")
    setSelectedState("")
    setSelectedColg("")
    setLoading(false);
  }

  //when state is changed re-render dropdown of colg
  useEffect(() => {
    setAcademicYearDataList();
    setStateDataList();
    if (selectedState && selectedState >= 1) {
      setColgDataList(selectedState);
    }

  }, [selectedState])

  //any authstate is changed re-render global states copy
  useEffect(() => {

    setLocalStateFromGlobalState();
    return (() => {
      unmountComponent();
    })

  }, [authState]);

  return (
    <>
      <AlertMsg/>
      <div className="profileMandatoryFields">
        <form>
          {step == "1" && (
            <>
              <div className="container-fluid loginpagebg">
              <div className="row mbottomnill LoginPageHeight">
                  <div className="col s12 m5 lrnpd LoginBanner">
                    <div className="loginpageleftside">
                    <div className="LoginBannerContent ShowMobile">
            <div className="bannerCarausalContent ">
            <h1>Introducing Aakash<br /> NEET-PG Plus</h1>
            <p>Your Gateway to Medical PG Prep</p>
          </div>
            </div>
                      </div>
                  </div>
                  <div className="col s12 m7">
                    <div className="loginpagerightside">
                      <div className="loginform">                      
                        <div className="loginletsintroheading">
                          <h1>Introduce<br /> <span>yourself</span> </h1>
                        </div>
                        <div className="row ">
                          <div className="col s12 m12">
                            <div className="loginotp">
                              <div className="input-field col s12 profileformcontrol">
                                <input type="text"
                                  id="name"
                                  className="validate"
                                  name="fullName"
                                  value={fullName}
                                  onChange={(e) => setFullName(e.target.value)} />
                                <label htmlFor="name" className={fullName && 'active'}>Your Full Name</label>
                              </div>
                            </div>
                          </div>

                        </div>
                        <div className="row">
                          <div className="col s12 m12">
                            <div className="loginotp">
                              <div className="input-field col s12">
                                <select
                                  id="acad-year"
                                  className="browser-default validate LoginSelectBox"
                                  name="academicYear"
                                  value={selectedAcademicYear}
                                  onChange={(e) => setSelectedAcademicYear(e.target.value)}
                                >
                                  <option value="">Choose Academic Year</option>
                                  {academicYearList &&
                                    Object.keys(academicYearList).map((index, val) => {
                                      return (
                                        <option key={academicYearList[index]['id']} value={academicYearList[index]['id']}>
                                          {academicYearList[index]['name']}
                                        </option>
                                      );
                                    })}
                                </select>
                              </div>
                            </div>
                          </div>

                        </div>
                        <div className="row ">
                          <div className="LoginPageBTNBox" onClick={() => handleNext()}>
                            <a className="btn LoginPageBTN">NEXT</a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}

          {step == "2" && (
            <>
              <div className="container-fluid loginpagebg">
              <div className="row mbottomnill LoginPageHeight">
                  <div className="col s12 m5 lrnpd LoginBanner">
                    <div className="loginpageleftside">
                    <div className="LoginBannerContent ShowMobile">
            <div className="bannerCarausalContent ">
            <h1>Introducing Aakash<br /> NEET-PG Plus</h1>
            <p>Your Gateway to Medical PG Prep</p>
          </div>
            </div>
                      </div>
                  </div>
                  <div className="col s12 m7">
                    <div className="loginpagerightside">
                      <div className="loginform">
                        <div className="loginletsintroheading">
                          <h1>Select <br /> <span>your state & college</span> </h1>
                        </div>

                        <div className="row">
                          <div className="col s12 m12">
                            <div className="loginotp">
                              <div className="input-field col s12 profileselectstatemg">
                                <select
                                  className="browser-default validate LoginSelectBox"
                                  name="state"
                                  id="state"
                                  value={selectedState}
                                  onChange={(e) => setSelectedState(e.target.value)}
                                >
                                  <option value="">Select State</option>
                                  {stateList &&
                                    Object.keys(stateList).map((index) => {
                                      return (
                                        <option key={index} value={index}>
                                          {stateList[index]}
                                        </option>
                                      );
                                    })}
                                </select>
                              </div>
                            </div>
                          </div>

                        </div>
                        <div className="row">
                          <div className="col s12 m12">
                            <div className="loginotp">
                              <div className="input-field col s12">
                                <select
                                  disabled={loading || !colgList ? 'disabled' : ''}
                                  id="college_name"
                                  className="browser-default validate LoginSelectBox"
                                  name="colg"
                                  value={selectedColg}
                                  onChange={(e) => setSelectedColg(e.target.value)}
                                >
                                  <option value="">Select College</option>
                                  <option value="1">Foreign Medical College</option>
                                  {colgList &&
                                    colgList.map((obj, index) => {
                                      return (
                                        <option key={index} value={obj["id"]}>
                                          {obj["name"]}
                                        </option>
                                      );
                                    })}
                                </select>
                              </div>
                            </div>
                          </div>

                        </div>
                        <div className="row ">
                          
                          <div className="LoginPageBTNBox">
                          {!loading && (
                           <a className="btn LoginPageBTN" onClick={handleProfileFieldsSubmit}>submit</a> 
                           )}
                           {loading && <Loader loaderClass="LoginPageBTN" />}
                          </div>
                          
                          
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </form>
      </div>
    </>
  );
};

export default ProfileMandatoryFields;
