const WhyAakash = () => {
  return (
    <div className="container WhyAakashSection" id="whyAakashDiv">
      <div className="row mbottomnill">
        <div className="whyaakash ShowMobile" id="whyAakashTestSeriesDivMob">
          <div className="row mbottomnill">
            <div className="col s6 m6">
              <div className="whyaakash-heading HideMobile">
                <h3>
                  Why <span>Aakash</span> <br />
                  <strong>PG Plus?</strong>
                </h3>
              </div>
              <div className="whyaakash-heading ShowMobile">
                <h3>
                  Why <span>Aakash</span> <br />
                  <strong>PG Plus?</strong>
                </h3>
              </div>
            </div>
            <div className="col s6 m6">
              <div className="whyaakashImage">
                <img
                  className="responsive-img"
                  src={
                    process.env.REACT_APP_PUBLIC_URL +
                    "dist/img/bro_illus_nw1.png"
                  }
                />
              </div>
            </div>
          </div>

          <div className="row mbottomnill">
            <div className="col s12">
              <img
                className="responsive-img"
                src={
                  process.env.REACT_APP_PUBLIC_URL +
                  "dist/img/conceptualized_icon.png"
                }
              />
            </div>
            <div className="col s12 WhyAakashMobile">
              <div className="">
                <h3>Conceptualized Learning</h3>
              </div>
              <div className="whyaakashcontentboxnw">
                <ul>
                  <li>Spaced Repetition: Cover all essentials thrice in a single Video-session with top points and recap feature</li>
                  <li>Smart illustration of concepts for enhanced audio and visual learning</li>
                  <li>Image-rich & Clinically oriented to make you ready for the "NExT NEET"</li>
                  <li>Short videos that are easy to manage, watch and come back to</li>
                  <li>Topic-wise recaps that enable you to cover an entire topic 90% faster than usual</li>
                </ul>
              </div>
            </div>
          </div>
          
          <div className="row mbottomnill" id="whyAakashEbooksDivMob">
            <div className="col s12">
              <img
                className="responsive-img"
                src={
                  process.env.REACT_APP_PUBLIC_URL + "dist/img/smarter_notes_icon.png"
                }
              />
            </div>
            <div className="col s12 WhyAakashMobile">
              <div className="">
                <h3>Smarter Notes</h3>
              </div>
              <div className="whyaakashcontentboxnw">
                <ul>
                  <li>Graphic visualisations and mnemonics that strengthen recall and reduce reading time by 25%</li>
                  <li>Notes prepared by India’s top medical experts and specialists</li>
                  <li>All concepts & facts which are repeatedly asked in exams are highlighted</li>
                  <li>Smartly formatted notes that make it easier to revise faster</li>
                </ul>
              </div>
            </div>
          </div>

          <div className="row mbottomnill" id="whyAakashQbankDivMob">
            <div className="col s12 ">
              <img
                className="responsive-img"
                src={
                  process.env.REACT_APP_PUBLIC_URL +
                  "dist/img/comprehensive_icon.png"
                }
              />
            </div>
            <div className="col s12 WhyAakashMobile">
              <div className="">
                <h3>Optimised QBank</h3>
              </div>
              <div className="whyaakashcontentboxnw">
                <ul>
                  <li>MCQs on topics repeatedly tested in the actual exam</li>
                  <li>Detailed solutions on every question, leaving no room for further doubts</li>
                  <li>5,000+ questions on frequently tested clinical scenario questions</li>
                  <li>3,000+ labelled image-based questions that improve recall and boost confidence</li>
                  <li>The only QBank that breaks down integrated questions into easily reproducible steps</li>
                </ul>
              </div>
            </div>
          </div>
          
        </div>

        <div className="whyaakash HideMobile" id="whyAakashTestSeriesDiv">
          <div className="row mbottomnill">
            <div className="col s12 m6">
              <div className="whyaakash-heading">
                <h3>
                  Why <span>Aakash</span> <br />
                  <strong>PG Plus?</strong>
                </h3>
              </div>
            </div>
            <div className="col s12 m6">
              <div className="whyaakashImage">
                <img
                  className="responsive-img"
                  src={
                    process.env.REACT_APP_PUBLIC_URL +
                    "dist/img/bro_illus_nw1.png"
                  }
                />
              </div>
            </div>
          </div>
          <div className="row ">
            <div className="col s12 m6">
              <div className="whyaakashboxhd">
                <div className="WhyAakashIconHeading">
                  <div className="valign-wrapper">
                    <div className="col s2">
                      <img
                        className="responsive-img"
                        src={
                          process.env.REACT_APP_PUBLIC_URL +
                          "dist/img/conceptualized_icon.png"
                        }
                      />
                    </div>
                    <div className="col s10">
                      <h3>Conceptualized Learning</h3>
                    </div>
                  </div>
                </div>
                <div className="whyaakashcontentboxnw">
                <ul>
                  <li>Spaced Repetition: Cover all essentials thrice in a single Video-session with top points and recap feature</li>
                  <li>Smart illustration of concepts for enhanced audio and visual learning</li>
                  <li>Image-rich & Clinically oriented to make you ready for the "NEXT NEET"</li>
                  <li>Short videos that are easy to manage, watch and come back to</li>
                  <li>Topic-wise recaps that enable you to cover an entire topic 90% faster than usual</li>
                </ul>
              </div>
              </div>
            </div>
            <div className="col s12 m6" id="whyAakashQbankDiv">
              <div className="whyaakashboxhd2">
                <div className="WhyAakashIconHeading">
                  <div className="valign-wrapper">
                    <div className="col s2">
                      <img
                        className="responsive-img"
                        src={
                          process.env.REACT_APP_PUBLIC_URL +
                          "dist/img/comprehensive_icon.png"
                        }
                      />
                    </div>
                    <div className="col s10">
                      <h3>Optimised QBank</h3>
                    </div>
                  </div>
                </div>

                <div className="whyaakashcontentboxnw">
                <ul>
                  <li>MCQs on topics repeatedly tested in the actual exam</li>
                  <li>Detailed solutions on every question, leaving no room for further doubts</li>
                  <li>5,000+ questions on frequently tested clinical scenario questions</li>
                  <li>3,000+ labelled image-based questions that improve recall and boost confidence</li>
                  <li>The only QBank that breaks down integrated questions into easily reproducible steps</li>
                </ul>
              </div>
              </div>
            </div>
          </div>

          <div className="row ">
            <div className="col s12 m6" id="whyAakashEbooksDiv">
              <div className="whyaakashboxhd">
                <div className="WhyAakashIconHeading">
                  <div className="valign-wrapper">
                    <div className="col s2">
                      <img
                        className="responsive-img"
                        src={
                          process.env.REACT_APP_PUBLIC_URL +
                          "dist/img/smarter_notes_icon.png"
                        }
                      />
                    </div>
                    <div className="col s10">
                      <h3>Smarter Notes</h3>
                    </div>
                  </div>
                </div>
                <div className="whyaakashcontentboxnw">
                <ul>
                  <li>Graphic visualisations and mnemonics that strengthen recall and reduce reading time by 25%</li>
                  <li>Notes prepared by India’s top medical experts and specialists</li>
                  <li>All concepts & facts which are repeatedly asked in exams are highlighted</li>
                  <li>Smartly formatted notes that make it easier to revise faster</li>
                </ul>
              </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhyAakash;
