import { scrollToSection } from "../../helpers/common";

const GatewayMedicalSection = () => {
  return (
    <div className="container AakashGatwaySection" id="aakashGatwaySections">
      <div className="gatwaybox">
        <div className="gatwayboxconntent">
          <div className="row mbottomnill">
            <div className="col s12 m7">
              <div className="GatwayInnerContent">
                <h2>The smarter and faster way to achieve your PG Dream</h2>
                <p>
                  1. Covering PG medical exams (FMGE, NEET PG, INICET, NExT)
                  <br />
                  2. Distilled and crisp content
                  <br />
                  3. 50% greater retention
                  <br />
                  4. Top Medical Experts
                </p>
                <div
                  className="GetwayBTNBox"
                  onClick={() => {
                    scrollToSection("whyAakashDiv", 100);
                  }}
                >
                  <a className="btn GatewayBTN">Know More</a>
                </div>
              </div>
            </div>
            <div className="col s12 m5">
              <div className="GatwayInnerImageNW">
                <img
                  className="responsive-img"
                  src={
                    process.env.REACT_APP_PUBLIC_URL + "dist/img/cuate_new.png"
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GatewayMedicalSection;
