import React, { useEffect } from "react";
import { Navigate, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { isUserLoggedIn } from "../../store/authenticate";
import App from "../../App";
import { getUserSession } from "../../helpers/loginFunctions";

const ProtectedRoute = () => {
  const authUser = getUserSession();
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate("/")
  const authState = useSelector((state) => state.auth);

  //check for redirect query string
  const [searchParams] = useSearchParams();
  const redirectTo = searchParams.get('redirectTo') || ''

  useEffect(() => {
    if (authUser && authState.isLoggedIn == undefined) {
      console.log("refreshing login state protected comp")
      dispatch(isUserLoggedIn())
    }
  }, [])

  useEffect(() => {
    if (authState.isLoggedIn == 1) {
      //If authorized and all profile updated then redirect to dashboard
      if (redirectTo == "" && location.pathname == '/profile' && profileCompleted()) {
        console.log("Profile completed, redirecting to dashboard")
        navigate("/dashboard")
        return false
      } else if (location.pathname == '/dashboard' && !profileCompleted()) {
        //If authorized but any profile field is not updated
        console.log("Profile not completed, redirecting to profile")
        navigate("/profile")
        return false
      }
    }
  }, [authState.isLoggedIn])

  const profileCompleted = () => {
    return authState.fullname && authState.stateId && authState.academicYear && authState.colgId ? true : false
  }

  // delete redirectTo and pass other query strings
  if (authState.isLoggedIn && redirectTo !== "") {
    const params = {};
    searchParams.delete('redirectTo')
    searchParams.forEach((value, key) => {
      params[key] = value;
    });

    return <Navigate replace to={'/' + redirectTo} state={params} />;
  }

  // If authorized, return an outlet that will render child elements
  // If not authorized then return to login component - within login check if it is logged in then set
  return authUser ? <App /> : <Navigate replace to="/login" />
};

export default ProtectedRoute;

