import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { ApiUrls } from "../../constants/constants";
import { getLocalItem, get_platform, removeLocalItem } from "../../helpers/common";
import { gtm_date_time, gtm_event } from "../../helpers/google_tracking";
import { authActions } from "../../store/authenticate";
import { Loader, Spinner } from "../common/common";
import Address from "./address";
import { getCurrentDate, getDatethMonthYearFormat } from "../../helpers/dateFunctions";

const ProductCart = (props) => {
  let selectedProduct = getLocalItem("productPgPlus");
  let selectedAddOn = getLocalItem("productPgPlusaddOn");

  const [product, setProduct] = useState([{}]);
  const [isUserDiscount, setIsUserDiscount] = useState(0);
  const [appliedCouponVerified, setAppliedCouponVerified] = useState("");
  const [userAddressId, setUserAddressId] = useState(null);
  const [isCorrectAddress, setIsCorrectAddress] = useState(false);
  const [appliedCoupon, setAppliedCoupon] = useState("");
  const authState = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  var productFeesCalculated = 0.0;
  var discountedAmountCalculated = 0.0;
  var productGSTCalculated = 0.0;
  var finalAmountCalculated = 0.0;
  var amountAfterDiscountCalculated = 0.0;
  const [productFees, setProductFees] = useState(0);
  const [discountedAmount, setDiscountedAmount] = useState(0);
  const [productGST, setProductGST] = useState(0);
  const [finalAmount, setFinalAmount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [pageLoader, setPageLoader] = useState(true);
  const [couponLoading, setCouponLoading] = useState(false);
  const [couponError, setCouponError] = useState("");
  const GST_RATE = process.env.REACT_APP_GST_RATE;
  const openModalRef = useRef(null);

  const changePlan = () => {
    gtm_event({
      event: "tap_changeplan",
      platform: get_platform(),
      ...gtm_date_time(),
      uid: authState.userId || 0,
      name: authState.name || "",
      mobile: authState.mobile || "",
      product_name: product[0].product_name,
      product_id: selectedProduct,
    });

    removeLocalItem("productPgPlusaddOn");
    removeLocalItem("productPgPlus");
    props.setStep(1);
  };

  //func to create order
  const createOrder = () => {
    setLoading(true);
    if (!selectedProduct || finalAmount < 0 || !userAddressId) {
      dispatch(authActions.showErrorMsg({ msg: "Oops! Seems all information not filled for creating order!!" }));
      setLoading(false);
      if (parseFloat(finalAmount) >= parseFloat(process.env.REACT_APP_FULL_ADDRESS_AMOUNT) && isCorrectAddress === false) {
        openModalRef.current.click();
      }

      return false;
    }

    if ((parseFloat(finalAmount) >= parseFloat(process.env.REACT_APP_FULL_ADDRESS_AMOUNT) || selectedAddOn) && isCorrectAddress === false) {
      dispatch(authActions.showErrorMsg({ msg: "Oops! Seems all address fields are not filled!!" }));
      setLoading(false);
      openModalRef.current.click();
      return false;
    }

    const orderParams = {
      bankCode: "0",
      paymentModeId: 4,
      products: selectedProduct,
      addonMapping: selectedAddOn || "",
      amount: parseInt(finalAmount), //only integer accepted in api
      couponCode: appliedCouponVerified,
      bankName: "",
      accountHolder: "",
      chequeNo: "",
      location: "",
      bankName1: "",
      chequeNo1: "",
      chequeDate: "",
      SI: "",
      hasOffline: 0,
      ctpType: 0,
      billingAddressId: userAddressId,
      source: "frontend",
    };

    gtm_event({
      event: "tap_proceedtopay",
      platform: get_platform(),
      ...gtm_date_time(),
      uid: authState.userId || 0,
      name: authState.name || "",
      mobile: authState.mobile || "",
      product_name: product[0].product_name,
      product_id: product[0].id,
      coupon_code: appliedCouponVerified,
      is_study_material: selectedAddOn ? true : false,
      purchase_price: parseFloat(product[0]['price']) - parseFloat(discountedAmount),
      list_price: product[0]['price'],
      discount_amount: discountedAmount,
    });

    axios.post(ApiUrls.PostOrder, { ...orderParams }, { withCredentials: true })
      .then((res) => {
        setLoading(false);
        if (res?.data?.status == "0") {
          throw new Error("Oops! Some error occurred while creating order!!");
        }

        if (res?.data?.data?.gateway) {
          props.setOrderResponse(res.data.data);
          props.setStep(3);
        } else {
          throw new Error("Oops! Some error occurred while creating order!!");
        }
      }).catch((err) => {
        console.log(err);
        setLoading(false);
        dispatch(authActions.showErrorMsg({ msg: "Oops! Some error occurred while creating order!!" }));
        props.setStep("1");
        navigate("/purchase", { replace: true });
        return false;
      });
  };

  //func to get product details
  const getProductDetails = async () => {
    await axios.get(ApiUrls.ProductDetail + selectedProduct)
      .then((res) => {
        setPageLoader(false);
        if (res.data.data.products && res.data.data.products.length > 0) {
          const products = res.data.data.products;
          setProduct(products);

          for (let i = 0; i < products.length; i++) {
            const fee = Math.round(parseFloat(products[i]["price"]))
            productFeesCalculated += fee;
            amountAfterDiscountCalculated = Math.round(parseFloat(fee) - parseFloat(discountedAmount)).toFixed(2);

            const gst = Math.round((parseFloat(amountAfterDiscountCalculated) * GST_RATE) / 100);
            productGSTCalculated += gst
            finalAmountCalculated += Math.round(parseFloat(amountAfterDiscountCalculated) + parseFloat(gst));
          }

          setProductFees(productFeesCalculated.toFixed("2"));
          setProductGST(productGSTCalculated.toFixed(2));
          setFinalAmount(finalAmountCalculated.toFixed(2));

          applyDiscount();
        }
      }).catch((err) => {
        setPageLoader(false);
        console.log(err);
      });
  };

  const removeCoupon = () => {
    setAppliedCoupon("");
    setAppliedCouponVerified("");
    //reset amount
    discountedAmountCalculated = "0";
    amountAfterDiscountCalculated = (
      parseFloat(productFees) - parseFloat(discountedAmountCalculated)
    ).toFixed(2);
    productGSTCalculated = (
      (parseFloat(amountAfterDiscountCalculated) * GST_RATE) /
      100
    ).toFixed(2);
    finalAmountCalculated = (
      parseFloat(amountAfterDiscountCalculated) +
      parseFloat(productGSTCalculated)
    ).toFixed(2);

    setDiscountedAmount(discountedAmountCalculated);
    setProductGST(productGSTCalculated);
    setFinalAmount(finalAmountCalculated);
  };

  const applyDiscount = (removeCoupon = 1) => {
    setCouponError("");
    setCouponLoading(true);
    let targetName = window.event.target.name;
    if (targetName == "CouponApplyBTN" && appliedCoupon == "") {
      setCouponError("Please enter valid coupon code!!");
      setCouponLoading(false);
      return false;
    }

    if (removeCoupon != 1)
      gtm_event({
        event: "tap_coupon",
        platform: get_platform(),
        ...gtm_date_time(),
        uid: authState.userId || 0,
        name: authState.name || "",
        mobile: authState.mobile || "",
        product_name: product[0].product_name,
        product_id: product[0].id,
        coupon_code: appliedCoupon,
      });

    axios.post(ApiUrls.ApplyCoupon, { userId: authState.userId, couponCode: removeCoupon == 1 ? "" : appliedCoupon, products: selectedProduct, }, { withCredentials: true })
      .then((res) => {
        setCouponLoading(false);
        if (res?.data?.status == "0") {
          if (appliedCoupon != "") {
            setCouponError("Please enter valid coupon code!!");
          }

          throw new Error(res.data.message);
        }

        //check if user discount
        if (res?.data?.status == "1" && (res.data.data.discountDetails.isUserDiscount == "1" || res.data.data.discountDetails.isUserDiscount == "0")) {
          discountedAmountCalculated = parseFloat(res.data.data.totalAmounts.discountGiven).toFixed(2);
          amountAfterDiscountCalculated = (parseFloat(productFees) - parseFloat(discountedAmountCalculated));
          productGSTCalculated = ((parseFloat(amountAfterDiscountCalculated) * GST_RATE) / 100);
          finalAmountCalculated = (parseFloat(amountAfterDiscountCalculated) + parseFloat(productGSTCalculated));

          setDiscountedAmount(discountedAmountCalculated);
          setProductGST(productGSTCalculated.toFixed(2));
          setFinalAmount(finalAmountCalculated.toFixed(2));
          if (res.data.data.discountDetails.isUserDiscount == "1") {
            setIsUserDiscount(1);
            console.log(`Yay! You have got ${discountedAmountCalculated} off!!`);
          }

          if (res.data.data.discountDetails.isUserDiscount == "0") {
            setAppliedCouponVerified(res.data.data.discountDetails.couponCode);
            console.log(`Yay! You have got ${discountedAmountCalculated} off!!`);
          }
        } else {
          if (appliedCoupon != "") {
            setCouponError("Please enter valid coupon code!!");
          }

          throw new Error("Invalid state discount api!!");
        }
      }).catch((err) => {
        setCouponLoading(false);
        console.log(err);
      });
  };

  // when component is unmounted clean states
  const unmountComponent = () => {
    setProduct([{}]);
    setIsUserDiscount(0);
    setAppliedCouponVerified("");
    setUserAddressId(null);
    setAppliedCoupon("");
    setProductFees(0);
    setDiscountedAmount(0);
    setProductGST(0);
    setFinalAmount(0);
  };

  useEffect(() => {
    getProductDetails();
    return () => {
      unmountComponent();
    };
  }, []);

  useEffect(() => {
    //check for user discount after getting/updating product details
    if (authState.isLoggedIn == "1" && appliedCoupon == "") {
      applyDiscount();
    }
  }, [finalAmount]);

  return (
    <div className={`container-fluid ${!window.APP_HIDE_HEADER ? "tabMargin" : ""}`}>
      {pageLoader && <Spinner />}
      {!pageLoader && (
        <>
          <div className="container">
            <div className="row">
              <div className="paymentheading">
                <h1>You are subscribing to:</h1>
              </div>
            </div>
            {Object.keys(product).length >= "1" && (
              <div className="row">
                <div className="paymentmainbox">
                  <div className="paymentinnerbox1">
                    <div className="ProductCart">
                      <table className=" ProductTableHeader">
                        <tbody>
                          <tr>
                            <td>
                              <div className="paymentinnerpera1">
                                <p>
                                  <label>
                                    <input type="radio" defaultChecked />
                                    <span>{product[0]["course_category"] + " Plan "}</span>
                                  </label>
                                </p>
                              </div>
                            </td>
                            <td>
                              <div className="paymentinnerpera2">
                                <a onClick={() => changePlan()}>Change Plan?</a>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div className="ProductTerm">
                                <p>
                                  Term
                                </p>
                                <p>
                                  <strong>{product[0]["term"]}</strong>
                                </p>
                              </div>
                            </td>
                            <td>
                              <div className="ProductTerm">
                                <p>
                                  Enrollment date
                                </p>
                                <p>
                                  <strong>{getDatethMonthYearFormat(getCurrentDate())}</strong>
                                </p>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div className="ProductDate">
                                <p>
                                  Term start date
                                </p>
                                <p>
                                  <strong>{getDatethMonthYearFormat(product[0]["course_start_date"])}</strong>
                                </p>
                              </div>
                            </td>
                            <td>
                              <div className="ProductDate">
                                <p>
                                  Term end date
                                </p>
                                <p>
                                  <strong>{product[0]["expiry_date"]}</strong>
                                </p>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td colSpan={2} >
                              <div className="ProductBorderBottom">
                                <hr />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div className="ProductCourseFee">
                                <p>
                                  Total Course Fees:
                                </p>
                              </div>
                            </td>
                            <td>
                              <div className="ProductCourseFeePrice">
                                <p>
                                  <strong>₹ {Math.round(product[0]['price']).toFixed("2")}</strong>
                                </p>
                              </div>
                            </td>
                          </tr>
                          {selectedAddOn && <><tr>
                            <td colSpan={2} >
                              <div className="ProductBorderBottom">
                                <hr />
                              </div>
                            </td>
                          </tr><tr>
                            <td>
                              <div className="ProductCourseFee">
                                <p>
                                  Study Material:
                                </p>
                              </div>
                            </td>
                            <td>
                              <div className="ProductCourseFeePrice">
                                <p>
                                  <strong>₹ {Math.round(product[1] ? product[1]['price']: 0).toFixed("2")}</strong>
                                </p>
                              </div>
                            </td>
                          </tr></>}
                          <tr>
                            <td colSpan={2} >
                              <div className="ProductBorderBottom">
                                <hr />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div className="ProductCourseFee">
                                <p>
                                  Discount Availed:
                                </p>
                              </div>
                            </td>
                            <td>
                              <div className="ProductCourseFeePrice">
                                <p>
                                  <strong>₹ {discountedAmount}</strong>
                                </p>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td colSpan={2} >
                              <div className="ProductBorderBottom">
                                <hr />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div className="ProductCourseFee">
                                <p>
                                  GST:
                                </p>
                              </div>
                            </td>
                            <td>
                              <div className="ProductCourseFeePrice">
                                <p>
                                  <strong>₹ {productGST}</strong>
                                </p>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td colSpan={2} >
                              <div className="ProductBorderBottom">
                                <hr />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div className="ProductCourseFee">
                                <p>
                                  Net Payable Amount:
                                </p>
                              </div>
                            </td>
                            <td>
                              <div className="ProductCourseFeePrice">
                                <p>
                                  <strong>₹ {finalAmount}</strong>
                                </p>
                              </div>
                            </td>
                          </tr>

                        </tbody>
                      </table>
                    </div>
                  </div>

                  <div>
                    {authState.isLoggedIn == "0" && (
                      <div className="row PaymentPayBTN mbottomnill HideMobile">
                        <div className="col s12">
                          <div className="ProceedPageBTNBox">
                            <Link className="btn ProceedBTN" to={"/login?redirectTo=purchase&step=2"}>
                              Proceed To Pay
                            </Link>
                          </div>
                        </div>
                      </div>
                    )}
                    {authState.isLoggedIn == "1" && (
                      <>
                        <div className="row mbottomnill">
                          <div className="col s12 m12">
                            <Address
                              finalAmount={finalAmount}
                              selectedProduct={selectedProduct}
                              product={product}
                              createOrder={createOrder}
                              userAddressId={userAddressId}
                              setUserAddressId={setUserAddressId}
                              isCorrectAddress={isCorrectAddress}
                              setIsCorrectAddress={setIsCorrectAddress}
                              openModalRef={openModalRef}
                            />

                            {!isUserDiscount && (
                              <div className="ProductCartCoupon">
                                <div className="row mbottomnill">
                                  <label>Coupon (Optional)</label>
                                  <div className="col s9 nmp ">
                                    <input
                                      type="text"
                                      name="coupon"
                                      value={appliedCoupon}
                                      placeholder="Enter coupon code"
                                      onChange={(e) => setAppliedCoupon(e.target.value)}
                                    />
                                  </div>
                                  <div className="col s3 nmp ProductCartCouponINBtn">
                                    {!couponLoading && appliedCouponVerified == "" && (
                                      <button
                                        className="btn ProductCartApplyBTN"
                                        id="CouponApplyBTN"
                                        name="CouponApplyBTN"
                                        onClick={applyDiscount}
                                      >
                                        Apply
                                      </button>
                                    )}
                                    {!couponLoading && appliedCouponVerified != "" && (
                                      <button
                                        className="btn ProductCartRemoveBTN"
                                        id="CouponApplyBTN"
                                        name="CouponApplyBTN"
                                        onClick={removeCoupon}
                                      >
                                        Remove
                                      </button>
                                    )}
                                    {couponLoading && <Loader loaderClass={"CouponApplyBTN"} />}

                                  </div>
                                </div>
                                {couponError && <span className="ProductCartCouponError">{couponError}</span>}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="row mbottomnill">
                          <div className={"col s12 m12"}>
                            <div className="ProceedPageBTNBox HideMobile">
                              {!loading && (
                                <a className="btn ProceedBTN" onClick={(e) => createOrder()}>
                                  Proceed to pay
                                </a>
                              )}
                              {loading && <Loader loaderClass="ProceedBTN" />}
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
          {authState.isLoggedIn == "0" && (
            <div className="row PaymentPayBTN mbottomnill ShowMobile">
              <div className="col s12 m12">
                <div className="ProceedPageMobBTNBox">
                  <Link className="btn ProceedMobBTN" to={"/login?redirectTo=purchase&step=2"}>
                    Proceed To Pay
                  </Link>
                </div>
              </div>
            </div>
          )}
          {authState.isLoggedIn == "1" && (
            <div className="row PaymentPayBTN mbottomnill ShowMobile">
              <div className="col s12 m12">
                <div className="ProceedPageMobBTNBox">
                  {!loading && <a className="btn ProceedMobBTN" onClick={(e) => createOrder()}>Proceed To Pay</a>}
                  {loading && <Loader className="ProceedMobBTN" />}
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default ProductCart;
