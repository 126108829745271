import PurchaseLayout from "./purchase/purchaseLayout"


function Purchase(){
    return(
        <div className="App">
            <PurchaseLayout/>
        </div>
    )
}

export default Purchase