import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import HomeHeader from "../../layout/homeHeader";
import PayuCheckout from "../payuCheckout";
import { useNavigate, useParams } from "react-router-dom";
import { authActions } from "../../../store/authenticate";
import InstantProductCart from "./instantProductCart";
import InstantPayuCheckout from "./instantPayuCheckout";

const InstantPayment = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  
  const extactedKey = params.paymentKey.split('~$~');
  const bookingId = extactedKey['0'];
  const paymentId = extactedKey['1'];
  const [step,setStep] = useState(1);
  const [orderResponse,setOrderResponse] = useState({});

  useEffect(() => {
    if(bookingId=="" || bookingId<=0 || paymentId=="" || paymentId<=0){
        console.log("Invalid instant payment link");
        dispatch(
            authActions.showErrorMsg({
              msg: "Oops! seems that payment link is not valid!!",
            })
        );
        navigate('/purchase')
    }
  }, []);

    return (
      <>
      <HomeHeader />
      {step == 1 &&
        <InstantProductCart step={step} setStep={setStep} bookingId={bookingId} paymentId={paymentId} setOrderResponse={setOrderResponse} />
      }
      {step == 2 && orderResponse.hasOwnProperty('gateway') && orderResponse["gateway"] == '4' &&
        <InstantPayuCheckout orderResponse={orderResponse}/>
      }
      {step == 2 && orderResponse.hasOwnProperty('gateway') && orderResponse["gateway"] == '9' &&
        <InstantPayuCheckout orderResponse={orderResponse}/>
      }
      </>
    );
  };
  
  export default InstantPayment;