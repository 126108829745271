import axios from "axios";
import { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { ApiUrls } from "../../../constants/constants";
import {
  make_tribyte_user_login,
  removeLocalItem,
} from "../../../helpers/common";
import { authActions, isUserLoggedIn } from "../../../store/authenticate";
import { AlertMsg } from "../../common/alert";
import { Loader, Spinner } from "../../common/common";
import InstantAddress from "./instantAddress";

const InstantProductCart = (props) => {
  removeLocalItem("productPgPlus");
  removeLocalItem("productPgPlusaddOn");
  const authState = useSelector((state) => state.auth);
  const [product, setProduct] = useState({});
  const [isInvalidLink, setIsInvalidLink] = useState(false);
  const [productApiData, setProductApiData] = useState({});
  const [userAddressId, setUserAddressId] = useState(null);
  const [isCorrectAddress, setIsCorrectAddress] = useState(false); 
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [productFees, setProductFees] = useState(0);
  const [discountedAmount, setDiscountedAmount] = useState(0);
  const [productGST, setProductGST] = useState(0);
  const [finalAmount, setFinalAmount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [pageLoader, setPageLoader] = useState(true);
  const openModalRef = useRef(null);

  //func to getChecksum parameters
  const getChecksum = () => {
    setLoading(true);
    if (!userAddressId) {
      dispatch(
        authActions.showErrorMsg({
          msg: "User Address Missing!! Please save address first.",
        })
      );
      setLoading(false);
      return false;
    }

    if (parseFloat(finalAmount) >= parseFloat(process.env.REACT_APP_FULL_ADDRESS_AMOUNT) && isCorrectAddress === false) {
      dispatch(
        authActions.showErrorMsg({
          msg: "Oops! Seems all address fields are not filled!!",
        })
      );
      setLoading(false);
      openModalRef.current.click();
      return false;
    }
    
    let bodyFormData = new FormData();
    bodyFormData.append(
      "data[ProductPaymentMode][id]",
      productApiData.orderDetail[0].paymentModeId
    );
    bodyFormData.append(
      "data[Product][requestParam]",
      productApiData.requestParam
    );
    bodyFormData.append("data[Product][total]", productApiData.amount);
    bodyFormData.append("data[Product][final]", productApiData.amount);
    bodyFormData.append("data[organisationId]", 1);
    bodyFormData.append("data[Product][baseAmount]", productApiData.baseAmount);
    bodyFormData.append(
      "data[Product][orderid]",
      productApiData.orderDetail[0].orderId
    );
    bodyFormData.append("data[Address][billingAddressId]", userAddressId);
    bodyFormData.append("data[Address][shippingAddressId]", userAddressId);

    axios
      .post(ApiUrls.InstantPaymentPOST, bodyFormData, { withCredentials: true })
      .then((res) => {
        setLoading(false);
        if (res?.data?.status == "0") {
          throw new Error("Oops! Some error occurred while creating order!!");
        }
        if (res?.data?.data?.gateway) {
          props.setOrderResponse(res.data.data);
          props.setStep(2);
        } else {
          throw new Error("Oops! Some error occurred while creating order!!");
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        dispatch(
          authActions.showErrorMsg({
            msg: "Oops! Some error occurred while creating order!!",
          })
        );
        props.setStep("1");
        navigate("/purchase", { replace: true });
        return false;
      });
  };

  //func to get product details
  const getProductDetails = () => {
    axios
      .get(ApiUrls.InstantPayment + props.bookingId + "~$~" + props.paymentId)
      .then((res) => {
        setPageLoader(false);
        if (res.data.status == "0") {
          alert("Invalid payment link");
          dispatch(
            authActions.showErrorMsg({
              msg: "Oops! seems that payment link is not valid!!",
            })
          );
          setIsInvalidLink(true);
          return false;
        }

        if (res.data.data.userId && res.data.data.userId > 0) {
          setProduct(res.data.data.orderDetail[0]);
          setProductApiData(res.data.data);
          let productFeesCalculated = Math.round(
            parseFloat(res.data.data.orderDetail[0]["productDetails"]["fee"])
          ).toFixed("2");
          let discountedAmount = Math.round(
            parseFloat(
              res.data.data.orderDetail[0]["productDetails"]["discount"]
            )
          ).toFixed("2");
          let productGSTCalculated = Math.round(
            parseFloat(
              res.data.data.orderDetail[0]["productDetails"]["govtTax"]
            )
          ).toFixed(2);
          let finalAmountCalculated = Math.round(
            parseFloat(
              res.data.data.orderDetail[0]["productDetails"]["paymentAmount"]
            )
          ).toFixed(2);
          setProductFees(productFeesCalculated);
          setDiscountedAmount(discountedAmount);
          setProductGST(productGSTCalculated);
          setFinalAmount(finalAmountCalculated);
        } else {
          console.log("Invalid response for payment link");
          dispatch(
            authActions.showErrorMsg({
              msg: "Oops! seems that payment link is not valid!!",
            })
          );
          navigate("/purchase");
        }
      })
      .catch((err) => {
        setPageLoader(false);
        console.log(err);
      });
  };

  const redirectToPage = () => {
    if (authState.isLoggedIn) {
      //check if login
      if (
        authState.fullname &&
        authState.stateId &&
        authState.academicYear &&
        authState.colgId
      ) {
        //if all fields are filled redirect to tribyte
        console.log("redirecting to tribyte");
        navigate("/dashboard");
        // make_tribyte_user_login(authState.userId);
        return false;
      } else {
        //if mandatory field is missing redirect to profile
        navigate("/profile");
      }
    } else {
      navigate("/");
    }
  };

  useEffect(() => {
    dispatch(isUserLoggedIn());
    getProductDetails();
  }, []);

  useEffect(() => {
    if (isInvalidLink && authState.isLoggedIn == "1") {
      redirectToPage();
    }
    if (isInvalidLink && authState.isLoggedIn == "0") {
      redirectToPage();
    }
  }, [isInvalidLink]);

  return (
    <div className={`container-fluid ${!window.APP_HIDE_HEADER ? "tabMargin" : ""}`}>
      {pageLoader && <Spinner/>}
      {!pageLoader && (
        <>
      <div className="container">
        <AlertMsg />
        {Object.keys(product).length >= "1" && (
          <>
            <div className="row">
              <div className="paymentheading">
                <h1>You are subscribing to:</h1>
              </div>
            </div>
            <div className="row">
              <div className="paymentmainbox">
                <div className="paymentinnerbox1">
                  <div className="row mbottomnill">
                    <div className="col s12 m12">
                      <div className="col s6 m6">
                        <div className="paymentinnerpera1">
                          <p>
                            <label>
                              <input type="radio" defaultChecked />
                              <span>{product["description"]}</span>
                            </label>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col s12 m12">
                      <div className="col s6 m6">
                        <div className="paymentinnerpera1">
                          <p>
                            <span>{product["title"] + " Course"}</span>
                          </p>
                        </div>
                      </div>
                      <div className="col s6 m6">
                        <div className="paymentinnerpera2">
                          <p>
                            <span>( Valid till {product["validDate"]} )</span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div>
                  <div className="row">
                    <div className="col s12 m12">
                      <div className="col s6 m6">
                        <div className="paymentinnerpera3">
                          <p>
                            Total Fees :<br />
                            Discount Availed :<br />
                            GST :
                          </p>
                        </div>
                      </div>
                      <div className="col s6 m6">
                        <div className="paymentinnerpera4">
                          <p>
                            ₹ {productFees}
                            <br />₹ {discountedAmount}
                            <br />₹ {productGST}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col s12">
                      <hr className="paymentbreakline" />
                    </div>
                    <div className="col s12">
                      <div className="paymentprice">
                        <p>
                          <span>Net Payable Amount:</span> <strong>₹</strong>{" "}
                          {finalAmount}
                        </p>
                      </div>
                    </div>
                  </div>
                  {Object.keys(productApiData).length >= 1 && (
                    <div className="row">
                      <div className="col s12">
                        <InstantAddress
                          finalAmount={finalAmount}
                          productApiData={productApiData}
                          paymentId={props.paymentId}
                          bookingId={props.bookingId}
                          userAddressId={userAddressId}
                          setUserAddressId={setUserAddressId}
                          authState={authState}
                          isCorrectAddress={isCorrectAddress}
                          setIsCorrectAddress={setIsCorrectAddress}
                          openModalRef={openModalRef}
                        />
                      </div>
                    </div>
                  )}
                  <div className="row">
                    <div className="row PaymentPayBTN mbottomnill HideMobile">
                      <div className="col s6 m5 offset-s6 offset-m7">
                        <div className="ProceedPageBTNBox">
                          {!loading && (
                            <a className="btn ProceedBTN" onClick={(e) => getChecksum()}>Proceed to pay</a>
                          )}
                          {loading && <Loader className="ProceedBTN" />}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
      {Object.keys(product).length >= "1" && (
        <div className="row PaymentPayBTN mbottomnill ShowMobile">
          <div className="col s12 m12">
            <div className="ProceedPageMobBTNBox">
              {!loading && (
                <a className="btn ProceedMobBTN" onClick={(e) => getChecksum()}>Proceed To Pay</a>
              )}
              {loading && <Loader className="ProceedMobBTN" />}
            </div>
          </div>
        </div>
      )}
      </>
      )}
    </div>
  );
};

export default InstantProductCart;
