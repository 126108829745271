import axios from "axios";
import { useEffect, useState } from "react";
import { ApiUrls, categoryfeatures } from "../../constants/constants";
import { calculateValidityInMonths } from "../../helpers/dateFunctions";
import M from "materialize-css";
import { get_platform, isMobileDevice, setLocalItem } from "../../helpers/common";
import parse from "html-react-parser";
import { gtm_date_time, gtm_event, webEngageEvent } from "../../helpers/google_tracking";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { Spinner } from "../common/common";
import { createBrowserHistory } from "history";
import Carousel from "../home/carausel";
import ProductDetail from "./productDetail";

export const history = createBrowserHistory({ //update history basename because of subfolder support
  basename: process.env.PUBLIC_URL
})

const ProductTabs = (props) => {
  const [loading, setLoading] = useState(true);
  const [productList, setProductList] = useState([]);
  const [productCategories, setProductCategories] = useState({});
  const authState = useSelector((state) => state.auth);
  const [searchParams, setSearchParams] = useSearchParams();
  let [selectedLocalCat, setSelectedLocalCat] = useState(searchParams.get("category"));
  const [checkedAddOns, setCheckedAddOns] = useState({});

  const carauselOptions = {
    items: 4,
    responsive: { 0: { items: 1 }, 600: { items: 3 }, 1000: { items: 3 } },
    autoplay: false,
    navText: [
      `<div class='nav-btn prev-slide'>
        <a class="prev btn-floating btn sliderarrowcust">
          <i class="material-icons">chevron_right</i>
        </a>
      </div> `,
      `<div class='nav-btn next-slide'>
        <a class="next btn-floating btn sliderarrowcust">
          <i class="material-icons">chevron_left</i>
        </a>
      </div>`],
      loop: false,
      rewind: true
  }

  useEffect(() => {
    return history.listen(location => {
      const urlParams = new URLSearchParams(location.location.search);
      if (urlParams.get("category"))
        setSelectedLocalCat(urlParams.get("category"))
    });
  })

  const buyProduct = (productId, name, pIndex) => {
    let addOnMapping = "";
    if (productList[pIndex]['addons'].length && (checkedAddOns[productId] || checkedAddOns[productId] == undefined)) {
      addOnMapping = `${productId}-${productList[pIndex]['addons'][0].id}`
      productId += "," + productList[pIndex]['addons'][0].id;
    }

    const arr = String(productId).split(",");
    gtm_event({
      event: "tap_BuyNow",
      platform: get_platform(),
      ...gtm_date_time(),
      uid: authState.userId || 0,
      name: authState.name || "",
      mobile: authState.mobile || "",
      product_name: name,
      product_id: arr[0],
      add_on_product_id: arr[1] || 0,
      is_study_material: addOnMapping ? true : false
    });

    setLocalItem("productPgPlusaddOn", addOnMapping);
    setLocalItem("productPgPlus", productId);
    props.setStep(2);
  };

  // Implemented jquery in the logic because when we are using state react renders again
  //  and carousel reset to first element
  const addRemoveAddOn = (e, product) => {
    // initially checkbox is checked
    const isChecked = checkedAddOns[product.id] == undefined ? false : !checkedAddOns[product.id];
    let orig_price = parseFloat(product['origFee']);
    let price_with_tax = parseFloat(product['price_with_tax']);
    if (isChecked) {
      orig_price += parseFloat(product['addons'][0]['original_price'])
      price_with_tax += parseFloat(product['addons'][0]['price_with_tax'])
    }

    window.jQuery(e.target).parents('tr').next().find('.materialPrice del span').html(`₹ ${Math.round(orig_price).toFixed()}`)
    window.jQuery(e.target).parents('tr').next().find('.materialPrice strong').html(` ₹${Math.round(price_with_tax).toFixed()}`)
    checkedAddOns[product.id] = isChecked
    setCheckedAddOns(checkedAddOns)
  }

  const initializeTabs = () => {
    let elem = document.querySelector(".tabs");
    let tabInstance = M.Tabs.init(elem, {});
    //check if category set in url
    let selectedLocalCat = searchParams.get("category");
    if (selectedLocalCat) {
      // tabInstance.select('cat'+selectedLocalCat);
      // history.go(-1)
    }
  };

  const getAllProducts = () => {
    axios.get(ApiUrls.ProductList)
      .then((res) => {
        setLoading(false);
        let pCategories = {};
        setProductList(res.data.data.products);
        if (res.data.data.products && typeof res.data.data.products === "object") {
          let productData = res.data.data.products;
          Object.keys(productData).map((i) => {
            if (!selectedLocalCat) setSelectedLocalCat(productData[i]["category_id"]);

            if (pCategories[productData[i]["category_id"]] === undefined) {
              let newCat = {};
              newCat["category_id"] = productData[i]["category_id"];
              newCat["course_category"] = productData[i]["course_category"];
              newCat["feature_summary"] = productData[i]["feature_summary"];
              pCategories[productData[i]["category_id"]] = newCat;
            }

            if (!pCategories[productData[i]["category_id"]] && productData[i]["feature_summary"])
              pCategories[productData[i]["category_id"]]['feature_summary'] = productData[i]["feature_summary"];
          })

          setProductList(productData);
          setProductCategories(pCategories);
        }

        setProductCategories(pCategories);
        initializeTabs();

      }).catch((err) => {
        setLoading(false);
        console.log(err);
      });
  }

  // when component is unmounted clean states
  const unmountComponent = () => {
    setProductCategories({});
    setProductList([]);
  }

  useEffect(() => {
    getAllProducts();
    return () => { unmountComponent(); }
  }, [])

  return (
    <div className={`container-fluid ${!window.APP_HIDE_HEADER ? "tabMargin" : ""}`}>
      <div className="purchasetopbg">
        <div className="container">
          {loading === true && <Spinner />}
          {loading === false && productList.length == "0" && (
            <div className="row">
              <div className="col s12">
                <div className="purchasemainbox ">
                  <div className="purchaseinnerbox purchaseborderactive">
                    <div className="row mbottomnill">No products found</div>
                  </div>
                </div>
              </div>
            </div>
          )}

          {productList.length > "1" && (
            <div className="productlist-box">
              <h3>Get started. Select your plan now!</h3>

              <div className="col s12">
                <ul className="tabs tabs-fixed-width">
                  {productCategories && Object.keys(productCategories).length > "0" && Object.keys(productCategories).map((cId) => (
                    <li
                      key={productCategories[cId]["category_id"]}
                      className="tab"
                      onClick={(e) => {
                        const cat = (productCategories[cId]["course_category"]).toUpperCase().replace(" ", "_");
                        if (authState.userId)
                          webEngageEvent({ event: "LD_LOGIN_PLAN_" + cat, platform: get_platform(), ...gtm_date_time(), uid: authState.userId });
                        else
                          gtm_event({ event: "LD_NONLOGIN_PLAN_" + cat, platform: get_platform(), ...gtm_date_time() });
                      }}
                    >
                      <a
                        id={"catTab" + productCategories[cId]["category_id"]}
                        href={"#cat" + productCategories[cId]["category_id"]}
                        onClick={() => {
                          setSelectedLocalCat(productCategories[cId]["category_id"])
                          setSearchParams("category=" + productCategories[cId]["category_id"])
                        }}
                        className={selectedLocalCat == productCategories[cId]["category_id"] ? "active" : ""}
                      >
                        {productCategories[cId]["course_category"]}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>

              {productCategories && Object.keys(productCategories).length > "0" && Object.keys(productCategories).map((cId) => (
                <div
                  key={productCategories[cId]["category_id"]}
                  id={"cat" + productCategories[cId]["category_id"]}
                  className={"row " + (selectedLocalCat == productCategories[cId]["category_id"] ? "active" : "")}
                  style={{ display: selectedLocalCat == productCategories[cId]["category_id"] ? "block" : "none" }}
                >
                  <div className="premiumbox">
                    <div className="flex-container">
                      {(categoryfeatures[productCategories[cId]["category_id"]] || categoryfeatures['default']).map((v, i) => (
                        <div key={"category_feature_" + i} className="flex-item-left">
                          <div>
                            <div className="productlisticon">{parse(v)}</div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>

                  {isMobileDevice() || window.IS_MOBILE_APP ? <ProductDetail productList={productList} productCategories={productCategories} addRemoveAddOn={addRemoveAddOn} buyProduct={buyProduct} cId={cId} /> :
                    <Carousel key={"product_slider_" + cId} carouselClass={`productListSlider-${cId}`} showNavigationBtn={true} carauselOptions={{ ...carauselOptions }}>
                      <ProductDetail productList={productList} productCategories={productCategories} addRemoveAddOn={addRemoveAddOn} buyProduct={buyProduct} cId={cId} />
                    </Carousel>
                  }
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProductTabs;
