const WeOfferSection = () => {
  return (
    <div className="container FeatureSection">
      <div className="featureheading">
        <h2>We also offer</h2>
      </div>
      <div className="FeatureMainBOX ">
        <div className="FeatureContentDiv">
          <div className="FeatureInnerBoxBG">
            <div className="FeatureBoxIMG">
              <img
                className=""
                src={"/pgplus/dist/img/dashboard/offer-QBank.png"}
              />
            </div>
            <div className="FeatureContentDiv">
              <div className="OfferHeadingM OfferHeading"> QBank</div>
              <div className="Offertext">20000+ MCQ's to boost your exam prep</div>
            </div>
          </div>

        </div>

        <div className="FeatureContentDiv">
          <div className="FeatureInnerBoxBG">
            <div className="FeatureBoxIMG">
              <img
                className=""
                src={"/pgplus/dist/img/dashboard/offer-test-series.png"}
              />
            </div>
            <div className="FeatureContentDiv">
              <div className="OfferHeadingL OfferHeading">Test Series</div>
              <div className="Offertext">Designed by our top faculty from AIIMS, PGI</div>
            </div>
          </div>
        </div>

        <div className="FeatureContentDiv">
          <div className="FeatureInnerBoxBG">
            <div className="FeatureBoxIMG">
              <img
                className=""
                src={"/pgplus/dist/img/dashboard/offer-smart-notes.png"}
              />
            </div>
            <div className="FeatureContentDiv">
              <div className="OfferHeadingL OfferHeading">Smarter Notes</div>
              <div className="Offertext">Clinically Oriented for better retention</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default WeOfferSection;
