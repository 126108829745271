export const academicYearList = {
  1: "Neet/PG Academic 1",
  2: "Neet/PG Academic 2",
  3: "Neet/PG Academic 3",
  4: "Neet/PG Academic 4",
  5: "Neet/PG Academic 5",
  6: "Neet/PG Academic 6",
  7: "Neet/PG Academic 7",
  8: "Neet/PG Academic 8",
};

export const FacultyDetails = [
  {
    Name: "Dr. Aseem Dewan",
    Qualification: "MD(Medicine) - MAMC, Delhi",
    Qualification2: "",
    Image: "Aseem.jpg"
  },
  {
    Name: "Dr. Pratik Rajendra Mohta",
    Qualification: "MD(Dermatology) - AIIMS, Delhi",
    Qualification2: "",
    Image: "Pratik.jpg"
  },
  {
    Name: "Dr. Rahul Chaudhary",
    Qualification: "MD(Medicine) - AIIMS, Delhi",
    Qualification2: "DM(Cardiology) - PGI, Chandigarh",
    Image: "Rahul.jpg"
  },
  {
    Name: "Dr. Bhaskar Krishnamurthy",
    Qualification: "MD(Pharmacology) - AIIMS, Delhi ",
    Qualification2: "DM(Clinical Pharmacology) - PGI, Chandigarh",
    Image: "Bhaskar.jpg"
  },
  {
    Name: "Dr. Aanchal Bhayana",
    Qualification: "MD(Radiodiagnosis) - VMMC & Safdarjung Hospital, Delhi",
    Qualification2: "",
    Image: "Aanchal.jpg"
  },
  {
    Name: "Dr. Harshit Garg",
    Qualification: "MD(Psychiatry) - AIIMS, Delhi",
    Qualification2: "",
    Image: "Harshit.jpg"
  },
  {
    Name: "Dr. Rakesh Mittal",
    Qualification: "MS(General Surgery) - VMMC & Safdarjung Hospital, Delhi",
    Qualification2: "",
    Image: "Rakesh.jpg"
  },
  {
    Name: "Dr. Ashwani Kumar",
    Qualification: "MS(Othopaedics) - UCMS, Delhi",
    Qualification2: "",
    Image: "Ashwani.jpg"
  },
  {
    Name: "Dr. Shefali Tyagi",
    Qualification: "DGO+DNB - BRDMC, Gorakhpur",
    Qualification2: "MRCOG (UK)",
    Image: "Shefali.jpg"
  },
  {
    Name: "Dr. Farheen Khan",
    Qualification: "DNB(OBGYN) - Batra Hospital, Delhi",
    Qualification2: "MRCOG (UK)",
    Image: "Farheen.jpg"
  },
  {
    Name: "Dr. Somdipta Bhattacharya",
    Qualification: "MD(Community Medicine) - RG Kar Medical College, Kolkata",
    Qualification2: "",
    Image: "Somdipta.jpg"
  },
  {
    Name: "Dr. Syed Ayesha Nikhat",
    Qualification: "MD(Physiology) - VMMC & Safdarjung Hospital, Delh",
    Qualification2: "",
    Image: "Ayesha.jpg"
  },
  {
    Name: "Dr. Vandana Dudeja Dewan",
    Qualification: "DA - MAMC, Delhi",
    Qualification2: "",
    Image: "Vandana.jpg"
  },
  {
    Name: "Dr. Prachi Kansal Agarwal",
    Qualification: "MD(Paediatrics) - Dr. RML Hospital, Delhi",
    Qualification2: "",
    Image: "Prachi_kansal.jpg"
  },
  {
    Name: "Dr. Prachi Jain",
    Qualification: "MS(ENT) - AIIMS, Delhi",
    Qualification2: "",
    Image: "Prachi_jain.jpg"
  },
  {
    Name: "Dr. Garima Anand",
    Qualification: "MD(Pathology) - GMC, Patiala",
    Qualification2: "",
    Image: "Garima.jpg"
  },
  {
    Name: "Dr. Himanshu Sharma",
    Qualification: "DM(Endocrinology) - SMS Medical College, Jaipur",
    Qualification2: "",
    Image: "Himanshu.jpg"
  },
  {
    Name: "Dr. Deepraj Mitra",
    Qualification: "MD(Anatomy) - RG Kar Medical College, Kolkata",
    Qualification2: "",
    Image: "Deepraj.jpg"
  },
  {
    Name: "Dr. Gurmanpreet",
    Qualification: "MD(Biochemistry) - GMC Patiala",
    Qualification2: "",
    Image: "Gurmanpreet.jpg"
  },
  {
    Name: "Dr. Kashika Gupta Kathuria",
    Qualification: `MS(OBGYN) - VMMC & Safdarjung Hospital, Delhi`,
    Qualification2: "DNB, FNB(Reproductive Medicine)",
    Image: "Kashika.jpg"
  },
  {
    Name: "Dr. Surbhi Gupta",
    Qualification: "MS(OBGYN) - BJMC, Ahmedabad",
    Qualification2: "DNB, FNB(Reproductive Medicine) - BACC, Bangalore",
    Image: "Surbhi.jpg"
  },
  {
    Name: "Dr. Tanu Singh",
    Qualification: "DNB(Ophthalmology) - L.V Prasad Eye Institute, Hyderabad",
    Qualification2: "FICO",
    Image: "Tanu.jpg"
  },
  {
    Name: "Dr. Shreya Chaudhuri",
    Qualification: "MD(Clinical Microbiology & Infectious Diseases) - GMCH, Nagpur",
    Qualification2: "",
    Image: "Shreya.jpg"
  },
  {
    Name: "Dr. Shilpa Singh",
    Qualification: "MD(Forensic Medicine) - AFMC, Pune",
    Qualification2: "",
    Image: "Shilpa.jpg"
  },
  {
    Name: "Dr. Ankit Tyagi",
    Qualification: "MS(General Surgery) - LHMC and Dr. RML Hospital, DU",
    Qualification2: "DNB(Urology) - Sir Gangaram Hospital, Delhi",
    Image: "Ankit.jpg"
  },
  {
    Name: "Dr. Amit Khurana",
    Qualification: "MD(Medicine) - KMC, Mangalore",
    Qualification2: "Fellowship in Clinical Haematology - TNMC and Nair Charitable Hospital, Mumbai",
    Image: "Amit.jpg"
  },
  {
    Name: "Dr. Shruti Bajad",
    Qualification: "(MBBS, MD-Medicine, DNB Rheumatology)",
    Qualification2: "",
    Image: "Shruti.jpg"
  },
];

export const ApiUrls = {
  CurrentOfferProducts: `${process.env.REACT_APP_SERVER_URL}purchaseapi/v1/products?type=advanced&filters=is_active:1|tag:CURRENT_OFFERS&sort=price`,
  TalkToExpert: `${process.env.REACT_APP_CRM_DOMAIN}crmapi/campaignleads`,
  FacultyList: `${process.env.REACT_APP_SERVER_URL}etutorapi/v2/professors`,
  HomePageOfferProducts: `${process.env.REACT_APP_SERVER_URL}purchaseapi/v1/products?type=advanced&filters=is_active:1|tag:HOME_PAGE_OFFERS&sort=price`,
  ProductList: `${process.env.REACT_APP_SERVER_URL}purchaseapi/v1/products?type=advanced&filters=is_active:1&sort=price,product_name`,
  ProductDetail: `${process.env.REACT_APP_SERVER_URL}purchaseapi/v1/products?type=advanced&filters=is_active:1|product_id:`,
  GetUserAddress: `${process.env.REACT_APP_SERVER_URL}purchaseapi/v1/address`, //filters are set within axios call
  PostOrder: `${process.env.REACT_APP_SERVER_URL}purchaseapi/v1/saveorders`,
  Cities: `${process.env.REACT_APP_SERVER_URL}commonapi/v1/cities?state_id=`,
  PinCodeDetails: `${process.env.REACT_APP_SERVER_URL}commonapi/v1/pincodedetails`,
  SaveAddress: `${process.env.REACT_APP_SERVER_URL}purchaseapi/v1/address`,
  UpdateShippingAddress: `${process.env.REACT_APP_SERVER_URL}purchaseapi/v1/address`,
  PaymentStatus: `${process.env.REACT_APP_SERVER_URL}purchaseapi/v1/paymentStatus`,
  ApplyCoupon: `${process.env.REACT_APP_SERVER_URL}purchaseapi/v1/applyCoupon`,
  TribyteCreateUser: `${process.env.REACT_APP_SERVER_URL}tribyteapi/v1/create_user`,
  TribyteRedirectUser: `${process.env.REACT_APP_SERVER_URL}tribyteapi/v1/user_login`,
  InstantPayment: `${process.env.REACT_APP_SERVER_URL}purchaseapi/v2/instantpayment?paymentLink=`,
  InstantPaymentPOST: `${process.env.REACT_APP_SERVER_URL}purchaseapi/v2/instantpayment`,
  PGResponse: `${process.env.REACT_APP_SERVER_URL}purchaseapi/v1/pgResponse`,
  StateList: `${process.env.REACT_APP_SERVER_URL}commonapi/v1/states?country_id=`,
  CityList: `${process.env.REACT_APP_SERVER_URL}commonapi/v1/cities?state_id=`,
  ColgList: `${process.env.REACT_APP_SERVER_URL}schoolapi/v1/schools/_search?state_id=`,
  GradeList: `${process.env.REACT_APP_SERVER_URL}contentapi/v2/grades?filters[isActive]=1`,
  bannerImagesApi: `${process.env.REACT_APP_SERVER_URL}utilityapi/v1/web_config`,
};

export const faqs = [
  {
    que: "What is Aakash PG Plus?",
    ans: "Aakash PG Plus is the latest course from the house of Aakash specially designed to help students prepare for the medical PG entrance exams such as NEET PG / NEXT / INI CET/ FMGE."
  },
  {
    que: 'How is this course better than other products available in market?',
    ans: 'The content is prepared under the guidance of best medical faculty from top institutes. Our content is up to date and reflects the latest changes in exam pattern and type of questions asked. Our QBank is a collection of 20,000+ high yield MCQs with in depth conceptual explanations.'
  },
  {
    que: 'Who are going to be the teachers?',
    ans: 'Our content is prepared by topper faculty from top institutes such as AIIMS, PGI, MAMC, Safdarjang, UCMS, etc.'
  },
  {
    que: 'Is the course updated to prepare me for NEXT?',
    ans: 'Our content is up to date and reflects the latest changes in exam pattern and type of questions asked. The course has latest QBank, Test, and videos which will help students cover clinical scenarios, image based questions and integrated concepts and make them NEXT ready'
  },
  {
    que: 'Which subjects will be covered under this course',
    ans: 'Our course will cover all 19 subjects asked in NEET PG / INI CET / NEXT / FMGE exams'
  },
  {
    que: 'A few section inside topics does not have content. When will this be updated?',
    ans: 'We have content in 700+ topics on our app and web portal. We are working to complete content coverage for remaining few topics and the same will be updated soon'
  },
  {
    que: 'Is the Product compatible with all devices',
    ans: 'All the content can be accessed on both tablet and mobile devices through our Aakash PG Plus app. The mobile device should be of RAM 2.0 and above and OS version 5.0 and above. Students can access QBank, test and e-books on laptops and desktops also through our portal on browser.'
  },
  {
    que: 'What advantages do I have with Aakash PG Plus over offline coaching institutes?',
    ans: 'You get access to video lectures from top medical faculty from anywhere in country and lectures can be consumed multiple times.'
  },
  {
    que: 'What is Recap Video in Aakash PG Plus?',
    ans: `We have Recap videos for each topic inside subjects. Our topic wise recap videos helps in<ul><li>The summary of entire topic is covered in 1 / 5 the duration</li><li>Difficult / Volatile topics can be covered even a week before the exam</li></ul>`
  },
  {
    que: 'How to use Recap Videos in Aakash PG Plus?',
    ans: `Recap Videos can be used for following purpose:<ul><li>While studying topic first time, go through videos to get a quick recap of important topics for better retention</li><li>Post completion of course content and closer to exam, watch only the recap videos for quick revision of entire course</li></ul>`
  },
  {
    que: 'Can I watch Aakash PG Plus lecture videos on laptop or desktop through web portal?',
    ans: 'We do not provide access to video lectures on web portal. Videos can be accessed only on mobile phone or tablet through our app. This is done to counter piracy risk.'
  },
  {
    que: 'Can I download videos and watch in offline mode?',
    ans: 'Yes, videos can be downloaded and watch the same in offline mode. The downloaded videos can be accessed within the app only.'
  },
  {
    que: 'What kind of MCQs are there in QBank?',
    ans: 'You will get access to our High Yield QBank, series of Grand test, subject test, mock test and previous year NEET PG, AIIMS PG and INI CET recall based question paper.'
  },
  {
    que: 'Can I solve the MCQs of QBank more than once?',
    ans: 'Yes, MCQs of the QBank can be solved only twice. However, you can always re-visit the MCQ to review your answer or view the solution.'
  },
  {
    que: 'Can I attempt the test more than once?',
    ans: 'No, test (under test series) can be taken only once. Once you submit, your responses for the test are saved. However, you can always re-visit the test to review your answer or view the solution.'
  },
  {
    que: 'Does the course have past years NEET PG or AIIMS PG or other Medical PG entrance exam test for practice?',
    ans: 'Yes, you can access Past Years Papers through the TEST tab. Our course has recall tests with questions available for NEET PG, AIIMS PG, INI CET and other medical PG exams.'
  },
  {
    que: 'Where can I access Aakash PG Plus Notes',
    ans: 'You can access softcopy of our notes in the form of e-books both on our app as well as web browser. Hardcopy of the notes may be available in future on purchase'
  },
  {
    que: 'How do I get physical copy of Aakash PG Plus Notes',
    ans: 'We are working on this. Physical copy will be made available soon for purchase'
  },
  {
    que: 'Is the money refundable after I cancel my subscription?',
    ans: 'No, the money is not refundable'
  },
  {
    que: 'How to change my phone number associated with my subscription',
    ans: 'Kindly contact us through <a href="mailTo:pgpluscustomercare@aesl.in">pgpluscustomercare@aesl.in</a> for the same with details on your old and new contact details.'
  },
  {
    que: 'How can I avail discounts for subscription?',
    ans: `<p>You can avail discounts through coupons. We may have discount coupons published on our website and app during offer.</p><p>We also give away special discount coupons to selected customer and same will be communicated through sms or notification on app.You can apply special discounts in "Have a coupon" section while making the purchase.</p>`
  },
  {
    que: 'What does Premium Plan offer?',
    ans: `<p>- Access to 1000+ hours of concept lecture, revision videos, MCQ discussion video</p><p>- Access to QBank with 20, 000 + MCQs across all 19 subjects</p><p>- Access to Test series with 50 + test</p>`
  },
  {
    que: 'What does Practice Plan offer?',
    ans: `<p>- Access to QBank with 20,000+ MCQs across all 19 subjects</p><p>- Access to Test series with 50 + test</p>`
  },
  {
    que: 'I purchased Practice Plan. Can I upgrade to Premium Plan?',
    ans: 'Please connect with our customer support executive @ 9289680055 for further clarities'
  },
  {
    que: 'My subscription is about to expire. Can I renew or extend the validity of my subscription?',
    ans: 'Please connect with our customer support executive @ 9289680055 for further clarities'
  },
  {
    que: 'Can I use Aakash PG Plus in more than one device?',
    ans: 'Your login credential cannot be used on more than one device simultaneously'
  },
  {
    que: 'What are the devices that Aakash PG Plus is compatible with?',
    ans: `<p>Android OS 5.0 and above</p><p>RAM 2 GB and above</p><p>Will launch soon for iOS users as well</p>`
  },
  {
    que: 'Can we access the Aakash PG Plus content on mobile app',
    ans: `<p>We have the Aakash PG Plus app available on Google PlayStore</p><p>We are working on the iOS app and it will be launched soon</p>`
  },
]

export const categoryfeatures = {
  1: [
    `<img src="dist/img/purchase/pgp-pur-icon1.png" />
    <p><strong>Conceptualised Learning</strong></p>
    <p>1,000+ hours of concept & revision Videos</p>`,

    `<img src="dist/img/purchase/pgp-pur-icon3.png" />
    <p><strong>Digital Notes</strong></p>
    <p>Image Rich and Clinically Oriented for Better Retention</p>`,

    `<img src="dist/img/purchase/pgp-pur-icon2.png" />
    <p><strong>Optimised QBank</strong></p>
    <p>QBank with 20,000+ MCQ’s</p>`,

    `<img src="dist/img/purchase/pgp-pur-icon3.png" />
    <p><strong>Test Series</strong></p>
    <p>Test series with 50+ Tests</p>`
  ],
  2: [
    `<img src="dist/img/purchase/pgp-pur-icon1.png" />
    <p><strong>Optimised QBank</strong></p>
    <p>QBank with 20,000+ MCQ’s</p>`,

    `<img src="dist/img/purchase/pgp-pur-icon3.png" />
    <p><strong>Test Series</strong></p>
    <p>Test series with 50+ Tests</p>`,
  ],
  3: [
    `<img src="dist/img/purchase/pgp-pur-icon3.png" />
    <p><strong>Test Series</strong></p>
    <p>Grand test, previous year papers, subject wise tests</p>`
  ],
  4: [
    `<img src="dist/img/purchase/pgp-pur-icon1.png" />
    <p><strong>Concept & revision Videos></p>
    <p>1,000+ hours of concept & revision Videos</p>`,

    `<img src="dist/img/purchase/pgp-pur-icon2.png" />
    <p><strong>Optimised QBank</strong></p>
    <p>QBank with 20,000+ MCQ’s</p>`
  ],
  5: [
    `<img src="dist/img/purchase/pgp-pur-icon1.png" />
    <p><strong>Concept & revision Videos</strong></p>
    <p>1,000+ hours of concept & revision Videos</p>`,

    `<img src="dist/img/purchase/pgp-pur-icon2.png" />
    <p><strong>Optimised QBank</strong></p>
    <p>QBank with 20,000+ MCQ’s</p>`,

    `<img src="dist/img/purchase/pgp-pur-icon3.png" />
    <p><strong>Digital Notes</strong></p>
    <p>Image Rich and Clinically Oriented for Better Retention</p>`
  ],
  6: [
    `<img src="dist/img/purchase/pgp-pur-icon1.png" />
    <p><strong>Conceptualised Learning</strong></p>
    <p>1,000+ hours of concept & revision Videos</p>`,

    `<img src="dist/img/purchase/pgp-pur-icon3.png" />
    <p><strong>Digital Notes</strong></p>
    <p>Image Rich and Clinically Oriented for Better Retention</p>`,

    `<img src="dist/img/purchase/pgp-pur-icon2.png" />
    <p><strong>Optimised QBank</strong></p>
    <p>QBank with 20,000+ MCQ’s</p>`,

    `<img src="dist/img/purchase/pgp-pur-icon3.png" />
    <p><strong>Test Series</strong></p>
    <p>Test series with 50+ Tests</p>`
  ],
  "default": [
    `<img src="dist/img/purchase/pgp-pur-icon1.png" />
    <p><strong>Conceptualised Learning</strong></p>
    <p>1,000+ hours of concept & revision Videos</p>`,

    `<img src="dist/img/purchase/pgp-pur-icon2.png" />
    <p><strong>Optimised QBank</strong></p>
    <p>QBank with 20,000+ MCQ’s</p>`,

    `<img src="dist/img/purchase/pgp-pur-icon3.png" />
    <p><strong>Test Series</strong></p>
    <p>Test series with 50+ Tests</p>`
  ],
}

export const GA_TRACKING_ID = "G-FYHTZYGSNQ";
export const GTM_TRACKING_ID = "GTM-N9PGZ6R";
