import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { authActions } from "../../store/authenticate";
import { Loader } from "../common/common";

const AfterOtp = (props) => {
  const dispatch = useDispatch()

  // when component is unmounted clean states (useeffect is called here only once [] so no reloading state)
  const unmountComponent = () => {
    props.setLoading(false);
  };
  // to check if user is already logged in
  useEffect(() => {
    return () => {
      unmountComponent();
    };
  }, []);

  return (
    <>
      <div className="loginotpheading">
        <h1>
          Enter OTP
          <br /> <span>We've sent to </span>
          <strong>{props.userMobile}</strong>
        </h1>
      </div>
      <div className="row RemoveMargin">
        <form className="col s12" onSubmit={props.handleOtpSubmit}>
          <div className="row">
            <div className="col s12 m12">
              <div className="loginotp">
                <div className="input-field col s12">
                  <input
                    id="mobile"
                    type="text"
                    value={props.otp}
                    onChange={(e) => {
                      props.setOtp(e.target.value);
                    }}
                    className="validate"
                  />         
                  <label htmlFor="mobile">Enter OTP</label>         
                  <p>
                    <small>
                      <a onClick={props.resendOtp}>Resend OTP</a>
                    </small>
                  </p>
                  <p>
                    <small>
                      <a
                        onClick={() => {
                          props.setOtp("");
                          props.setUserMobile("");
                          dispatch(authActions.logoutState());
                        }}
                      >
                        Change Mobile
                      </a>
                    </small>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row ">
            <div className="LoginPageBTNBox">
              {!props.loading &&
              <a className="btn LoginPageBTN" onClick={props.handleOtpSubmit}>Verify OTP</a>
              }
              {props.loading && <Loader/>}
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default AfterOtp;