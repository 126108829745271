const DownloadAppSection = () => {
  return (
    <div className="container DownloadAakashSection">
      <div className="DownloadPageContainer ">
        <div className="">
          <div className="col s12 m6 l6 offset-m6 DownloadContainerDiv">
            <div className="downloadbox">
              <h4>
                Download
                <br /> <span>Aakash PG Plus</span> App
              </h4>
              <p>
                Learn From the most
                <br /> accomplished Experienced Faculty.
              </p>
              <div className="downloadboxgabtn">
                <a
                  href={
                    "https://play.google.com/store/apps/details?id=com.tribyte.aakashpgplus"
                  }
                  target="_blank"
                >
                  <img
                    className="responsive-img"
                    src={
                      process.env.REACT_APP_PUBLIC_URL +
                      "dist/img/google-play-btn.png"
                    }
                  ></img>
                </a>
              </div>
            </div>
          </div>
          <div className="col s12 m6 l6  DownloadContainerDiv">
            <div className="DownloadIMGBox">
              <div className="DownloadInnerIMGBox">
                <div className="col m6 l6 offset-m6 offset-l6 DesktopDownloadContent">
                  <img
                    className="responsive-img "
                    src={
                      process.env.REACT_APP_PUBLIC_URL +
                      "dist/img/download_app_img.png"
                    }
                  ></img>
                </div>
                <div className="col s12 m12 MobileDownloadContent">
                  <img
                    className="responsive-img "
                    src={
                      process.env.REACT_APP_PUBLIC_URL +
                      "dist/img/mobile-phone.png"
                    }
                  ></img>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DownloadAppSection;
