import { scrollToSection } from "../../helpers/common";

const OurOfferingsSection = () => {
  return (
    <div className="container FeatureSection">
      <div className="row">
        <div className="featureheading">
          <h2>Our Offering</h2>
        </div>
      </div>
      <div className="HideMobile">
        <div className="FeatureMainBOX ">
          <div className="FeatureContentDiv">
            <div className="FeatureInnerBoxBG">
              <div className="FeatureBoxIMG">
                <img
                  className=""
                  src={
                    process.env.REACT_APP_PUBLIC_URL +
                    "dist/img/feature-icon1.png"
                  }
                />
              </div>
              <div className="FeatureContentDiv">
                <div className="OfferHeadingL">1000+ Hours</div>
                <div className="OfferHeadingM">of Video Lectures</div>
                <div className="Offertext">
                concept videos, Recap Videos, and PYP Discussion Videos
                </div>
              </div>
            </div>
            <div
              className="ManageFeatureBtn"
              onClick={() => {
                scrollToSection("videoCarouselDiv", 100);
              }}
            >
              <div className="FeatureBTNBox">
                <a className="btn FeatureBTN1">Know More</a>
              </div>
            </div>
          </div>

          <div className="FeatureContentDiv">
            <div className="FeatureInnerBoxBG">
              <div className="FeatureBoxIMG">
                <img
                  className=""
                  src={
                    process.env.REACT_APP_PUBLIC_URL +
                    "dist/img/feature-icon2.png"
                  }
                />
              </div>
              <div className="FeatureContentDiv">
                <div className="OfferHeadingM"> QBank with 20,000+ MCQs</div>
                {/* <div className="OfferHeadingL"></div> */}
                <div className="Offertext">5000+ Clinical Scenario Based Questions</div>
              </div>
            </div>
            <div
              className="ManageFeatureBtn"
              onClick={() => {
                scrollToSection("whyAakashQbankDiv", 100);
              }}
            >
              <div className="FeatureBTNBox">
                <a className="btn FeatureBTN2">Know More</a>
              </div>
            </div>
          </div>

          <div className="FeatureContentDiv">
            <div className="FeatureInnerBoxBG">
              <div className="FeatureBoxIMG">
                <img
                  className=""
                  src={
                    process.env.REACT_APP_PUBLIC_URL +
                    "dist/img/feature-icon3.png"
                  }
                />
              </div>
              <div className="FeatureContentDiv">
                <div className="OfferHeadingL">Test Series</div>
                <div className="Offertext">Designed by</div>
                <div className="Offertext">Top Faculty from AIIMS, MAMC, UCMS, and PGI</div>
              </div>
            </div>
            <div
              className="ManageFeatureBtn"
              onClick={() => {
                scrollToSection("whyAakashTestSeriesDiv", 100);
              }}
            >
              <div className="FeatureBTNBox">
                <a className="btn FeatureBTN3">Know More</a>
              </div>
            </div>
          </div>

          <div className="FeatureContentDiv">
            <div className="FeatureInnerBoxBG">
              <div className="FeatureBoxIMG">
                <img
                  className=""
                  src={
                    process.env.REACT_APP_PUBLIC_URL +
                    "dist/img/feature-icon4.png"
                  }
                />
              </div>
              <div className="FeatureContentDiv">
                <div className="OfferHeadingL">Smarter Notes</div>
                <div className="Offertext">Image Rich & Clinically Oriented for Better Retention</div>
              </div>
            </div>
            <div
              className="ManageFeatureBtn"
              onClick={() => {
                scrollToSection("whyAakashEbooksDiv", 100);
              }}
            >
              <div className="FeatureBTNBox">
                <a className="btn FeatureBTN4">Know More</a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row mbottomnill ShowMobile">
        <div className="col s6 m6">
          <div className="featurebox">
            <div className="featureimagebox">
              <div className="featureimage">
                <img
                  src={
                    process.env.REACT_APP_PUBLIC_URL +
                    "dist/img/feature-icon1.png"
                  }
                />
              </div>
            </div>
            <div className="featurecontentbox">
              <div className="OfferHeadingL">1000+ hours</div>
              <div className="OfferHeadingM">of Video Lectures</div>
              <div className="Offertext">Concept Videos, Recap Videos, and PYP Discussion Videos</div>
            </div>
            <div
              className="featurebtntop"
              onClick={() => {
                scrollToSection("videoCarouselDiv", 100);
              }}
            >
              <div className="FeatureBTNBox">
                <a className="btn FeatureBTN1">Know More</a>
              </div>
            </div>
          </div>
        </div>
        <div className="col s6 m6">
          <div className="featurebox">
            <div className="featureimagebox">
              <div className="featureimage">
                <img
                  src={
                    process.env.REACT_APP_PUBLIC_URL +
                    "dist/img/feature-icon2.png"
                  }
                />
              </div>
            </div>
            <div className="featurecontentbox">
              <div className="OfferHeadingM"> QBank with 20,000+ MCQs</div>
              {/* <div className="OfferHeadingL"></div> */}
              <div className="Offertext">5000+ Clinical Scenario Based Questions</div>
            </div>
            <div
              className="featurebtntop"
              onClick={() => {
                scrollToSection("whyAakashQbankDivMob", 75);
              }}
            >
              <div className="FeatureBTNBox">
                <a className="btn FeatureBTN2">Know More</a>
              </div>
            </div>
          </div>
        </div>
        <div className="col s6 m6">
          <div className="featurebox">
            <div className="featureimagebox">
              <div className="featureimage">
                <img
                  src={
                    process.env.REACT_APP_PUBLIC_URL +
                    "dist/img/feature-icon3.png"
                  }
                />
              </div>
            </div>
            <div className="featurecontentbox">
              <div className="OfferHeadingL"> Test series</div>
              <div className="Offertext">Designed by</div>
              <div className="Offertext">Top Faculty from AIIMS, MAMC, UCMS, and PGI</div>
            </div>
            <div
              className="featurebtntop"
              onClick={() => {
                scrollToSection("whyAakashTestSeriesDivMob", 75);
              }}
            >
              <div className="FeatureBTNBox">
                <a className="btn FeatureBTN3">Know More</a>
              </div>
            </div>
          </div>
        </div>
        <div className="col s6 m6">
          <div className="featurebox">
            <div className="featureimagebox">
              <div className="featureimage">
                <img
                  src={
                    process.env.REACT_APP_PUBLIC_URL +
                    "dist/img/feature-icon4.png"
                  }
                />
              </div>
            </div>
            <div className="featurecontentbox">
              <div className="OfferHeadingL">Smarter Notes</div>
              <div className="Offertext">Image Rich & Clinically Oriented for Better Retention</div>
            </div>
            <div
              className="featurebtntop"
              onClick={() => {
                scrollToSection("whyAakashEbooksDivMob", 100);
              }}
            >
              <div className="FeatureBTNBox">
                <a className="btn FeatureBTN4">Know More</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default OurOfferingsSection;
