import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { getLocalItem } from "../../helpers/common";
import { isUserLoggedIn } from "../../store/authenticate";
import { AlertMsg } from "../common/alert";
import HomeHeader from "../layout/homeHeader";
import PayuCheckout from "./payuCheckout";
import ProductCart from "./productCart";
import ProductTabs from "./productTabs";
import DashboardHeader from "../layout/dashboardHeader";

const PurchaseLayout = () => {
  const authState = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [orderResponse,setOrderResponse] = useState({});
  const location = useLocation();
  let selectedProduct = getLocalItem("productPgPlus") || "";
  let initialStep = 1;
  if(location?.state?.step && selectedProduct>='1'){ // called when state->step is set navigate("/purchase",{state: {step:2}})
    initialStep = 2;
  }
  const [step,setStep] = useState(initialStep);

  useEffect(() => {
    if (authState.isLoggedIn == 1) { //check if login
      if (!authState.fullname ||!authState.stateId ||!authState.academicYear ||!authState.colgId) {
        //if all fields are not filled redirect to profile
        navigate("/profile");
      }
    }else if(authState.isLoggedIn == undefined){
      console.log("refreshing login state purchase layout comp");
      dispatch(isUserLoggedIn());
    }
  }, [authState.isLoggedIn]);

    return (
      <>
      <AlertMsg/>
      {!window.APP_HIDE_HEADER && (authState.isLoggedIn == 1 ? <div className="dashboard"><DashboardHeader setStep={setStep} /></div> : <HomeHeader setStep={setStep} />)}
      {step == 1 &&
        <ProductTabs step={step} setStep={setStep}/>
      }
      {step == 2 &&
        <ProductCart step={step} setStep={setStep} setOrderResponse={setOrderResponse} />
      }
      {step == 3 && orderResponse.hasOwnProperty('gateway') && orderResponse["gateway"] == '4' &&
        <PayuCheckout orderResponse={orderResponse}/>
      }
      {step == 3 && orderResponse.hasOwnProperty('gateway') && orderResponse["gateway"] == '9' &&
        <PayuCheckout orderResponse={orderResponse}/>
      }
      </>
    );
  };
  
  export default PurchaseLayout;