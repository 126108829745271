import axios from "axios";
import { ApiUrls } from "../constants/constants";
import { gtm_date_time, webEngageEvent } from "./google_tracking";

//append js dynamically
export const appendScript = (scriptToAppend) => {
  const script = document.createElement("script");
  script.src = scriptToAppend;
  script.async = true;
  document.body.appendChild(script);
};

//sort array of objects by key
export const sort2DArrayByKey = (array, key = "date") => {
  return array.sort(function (a, b) {
    var x = a[key];
    var y = b[key];
    return x < y ? -1 : x > y ? 1 : 0;
  });
};

export function htmlToCSV(selector, filename) {
  var data = [];


  // = document.querySelector(selector);

  var rows = document.querySelectorAll("#" + selector + " tr");
  for (var i = 0; i < rows.length; i++) {
    var row = [], cols = rows[i].querySelectorAll("td, th");

    for (var j = 0; j < cols.length; j++) {
      row.push('"' + cols[j].innerText + '"');
    }

    data.push(row.join(","));
  }

  downloadCSVFile(data.join("\n"), filename);
}

function downloadCSVFile(csv, filename) {
  var csv_file, download_link;

  csv_file = new Blob([csv], { type: "text/csv" });

  download_link = document.createElement("a");

  download_link.download = filename;

  download_link.href = window.URL.createObjectURL(csv_file);

  download_link.style.display = "none";

  document.body.appendChild(download_link);

  download_link.click();
}

// get item from local storage
export const getLocalItem = (keyName) => {
  const keyData = localStorage.getItem(keyName);
  try {
    if (keyData) return atob(keyData);
  } catch (e) {
    return null;
  }
};

// remove item from local storage
export const removeLocalItem = (keyName) => {
  localStorage.removeItem(keyName);
};

// set item in local storage base64 encoded
export const setLocalItem = (keyName, valueObj) => {
  localStorage.setItem(keyName, btoa(valueObj));
};

// remove all items from local storage except pgplus
export const removeLocalStorageData = (keepCartProduct = false) => {
  const keysToremove = ['profileFullname', 'profileAcademicYear', 'profileSelectedState', 'profileSelectedColg', 'csLoginUser'];
  if (!keepCartProduct) {
    keysToremove.push('productPgPlus')
    keysToremove.push('productPgPlusaddOn')
  }

  for (let i = 0; i < keysToremove.length; i++) {
    removeLocalItem(keysToremove[i])
  }
};

export const make_tribyte_user_login = (userId, orderStatus) => {
  if (!userId) return false;

  if (window.IS_MOBILE_APP && (window.APP_LOGIN_SUCCESS_URL || window.BUY_NOW_SUCCESS_URL)) {
    let redirectUrl = orderStatus && window.BUY_NOW_SUCCESS_URL ? window.BUY_NOW_SUCCESS_URL : window.APP_LOGIN_SUCCESS_URL || window.BUY_NOW_SUCCESS_URL;
    redirectUrl = decodeURIComponent(redirectUrl);

    webEngageEvent({ event: "LMS_LANDING", platform: get_platform(), ...gtm_date_time(), uid: userId });
    if (redirectUrl.indexOf("?") > -1) redirectUrl += "&username=" + process.env.REACT_APP_TRIBYTE_ENVIRONMENT + '-' + userId
    else redirectUrl += "?username=" + process.env.REACT_APP_TRIBYTE_ENVIRONMENT + '-' + userId

    if(getMobileOperatingSystem() == "iOS" && typeof window.JSPlatform != "undefined" && typeof window.JSPlatform.loadURL == "function"){
      window.JSPlatform.loadURL(redirectUrl);
      return true
    }

    window.location.href = redirectUrl
  } else {
    return axios.get(ApiUrls.TribyteRedirectUser + "?user_id=" + userId, { withCredentials: true })
      .then((res) => {
        if (res.data?.data?.auto_login_url) {
          webEngageEvent({ event: "LMS_LANDING", platform: get_platform(), ...gtm_date_time(), uid: userId });
          window.location.href = res.data?.data?.auto_login_url
          return res
        } else {
          throw new Error("Error while redirecting to tribyte!!")
        }
      }).catch((err) => {
        console.log(err)
        alert(err)
        window.location.href = process.env.REACT_APP_PUBLIC_URL + "purchase"
        return false
      });
  }
}

export const scrollToSection = (id, margin = 0, scrollToBottom = false) => {
  if (id) {
    const element = document.getElementById(id);
    if (!element) return

    window.scrollTo({
      top: element.offsetTop - margin,
      behavior: "smooth"
    });
  } else {
    window.scrollTo({ top: !scrollToBottom ? 0 : document.body.scrollHeight, behavior: 'smooth' });
  }
}

export const get_platform = () => {
  return window.IS_MOBILE_APP ? getMobileOperatingSystem() : isMobileDevice() ? "mobile web" : "web";
}

export const getMobileOperatingSystem = () => {
  var userAgent = navigator.userAgent || navigator.vendor || window.opera;

  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
    return "Windows Phone";
  }

  if (/android/i.test(userAgent)) {
    return "Android";
  }

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return "iOS";
  }

  return "mobile web";
}

export const isMobileDevice = function () {
  let check = false;
  /* eslint-disable no-useless-escape */
  (function (a) {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
        a
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        a.substr(0, 4)
      )
    )
      check = true;
  })(navigator.userAgent || navigator.vendor || window.opera);
  return check;
}