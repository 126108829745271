import ReactGA from "react-ga4";
import TagManager from "react-gtm-module";
import { GA_TRACKING_ID, GTM_TRACKING_ID } from "../constants/constants";

const event_names = {
    tap_landing_DM: { name: 'LD_TAP_LANDING_DM', gtm: 1, webengage: 0 },
    tap_getstarted: { name: 'LD_TAP_GET_STARTED', gtm: 1, webengage: 0 },
    tap_login_business: { name: 'LD_TAP_LOGIN_BUSINESS', gtm: 1, webengage: 0 },
    tap_createnewaccount: { name: 'LD_TAP_CREATE_NEW_ACCOUNT', gtm: 1, webengage: 0 },
    tap_mobile_signup: { name: 'LD_TAP_MOBILE_SIGNUP', gtm: 1, webengage: 0 },
    tap_verifyOTP_signup: { name: 'LD_TAP_VERIFY_OTP_SIGNUP', gtm: 0, webengage: 1 },
    tap_name_grade_signup: { name: 'LD_TAP_NAME_GRADE_SIGNUP', gtm: 0, webengage: 1 },
    tap_college_state_signup: { name: 'LD_TAP_COLLEGE_STATE_SIGNUP', gtm: 0, webengage: 1 },
    tap_login_login: { name: 'LD_TAP_LOGIN_LOGIN', gtm: 1, webengage: 0 },
    tap_verifyOTP_login: { name: 'LD_TAP_VERIFY_OTP_LOGIN', gtm: 0, webengage: 1 },
    tap_resendOTP_login: { name: 'LD_TAP_RESEND_OTP_LOGIN', gtm: 0, webengage: 1 },
    tap_resendOTP_signup: { name: 'LD_TAP_RESEND_OTP_SIGNUP', gtm: 0, webengage: 1 },
    tap_downloadapp_business: { name: 'LD_TAP_DOWNLOAD_APP_BUSINESS', gtm: 1, webengage: 0 },
    tap_callback_business: { name: 'LD_TAP_CALL_BACK_BUSINESS', gtm: 1, webengage: 0 },
    tap_callus_business: { name: 'LD_TAP_CALL_US_BUSINESS', gtm: 1, webengage: 0 },
    tap_share_business: { name: 'LD_TAP_SHARE_BUSINESS', gtm: 1, webengage: 0 },
    tap_whatsapp_business: { name: 'LD_TAP_WHATSAPP_BUSINESS', gtm: 1, webengage: 0 },
    tap_demovideo_business: { name: 'LD_TAP_DEMO_VIDEO_BUSINESS', gtm: 1, webengage: 0 },
    tap_kmowmore_business: { name: 'LD_TAP_KNOW_MORE_BUSINESS', gtm: 1, webengage: 0 },
    tap_BuyNow: { name: 'LD_TAP_BUY_NOW', gtm: 1, webengage: 1 },
    tap_changeplan: { name: 'LD_TAP_CHANGE_PLAN', gtm: 1, webengage: 1 },
    tap_coupon: { name: 'LD_TAP_COUPON', gtm: 0, webengage: 1 },
    tap_proceedtopay: { name: 'LD_TAP_PROCEED_TO_PAY', gtm: 1, webengage: 1 },
    tap_tryagain_payment: { name: 'LD_TAP_TRY_AGAIN_PAYMENT', gtm: 1, webengage: 0 },
    tap_dashbaord_startlearning_payment: { name: 'LD_TAP_DASHBAORD_START_LEARNING_PAYMENT', gtm: 0, webengage: 1 },
    tap_plans_business: { name: 'LD_TAP_PLANS_BUSINESS', gtm: 1, webengage: 0 },
    tap_about_us: { name: 'LD_TAP_ABOUT_US', gtm: 1, webengage: 0 },
    tap_our_faculty: { name: 'LD_TAP_OUR_FACULTY', gtm: 1, webengage: 0 },
    TAP_CONTACT_US_PHONE: { name: 'TAP_CONTACT_US_PHONE', gtm: 1, webengage: 1 },
    LD_NONLOGIN_PLAN_PREMIUM: { name: 'LD_NONLOGIN_PLAN_PREMIUM ', gtm: 1, webengage: 0 },
    LD_NONLOGIN_PLAN_PRACTICE: { name: 'LD_NONLOGIN_PLAN_PRACTICE ', gtm: 1, webengage: 0 },
    PLANS_LAND: { name: 'PLANS_LAND ', gtm: 0, webengage: 1 },
}

export const int_ga = () => {
    ReactGA.initialize(GA_TRACKING_ID);
}

export const page_view = (path = "") => {
    ReactGA.send({ hitType: "pageview", page: (path.trim()) || window.location.pathname });
}

export const ga_event = (opt = {}) => {
    console.log('ga_event', opt)
    ReactGA.event({
        category: opt.category || "your category",
        action: opt.action || "your action",
        label: opt.label || "your label", // optional
        value: opt.value || 0, // optional, must be a number
        nonInteraction: true, // optional, true/false
        transport: "xhr", // optional, beacon/xhr/image
    });
}

export const init_gtm = () => {
    const tagManagerArgs = { gtmId: GTM_TRACKING_ID }
    TagManager.initialize(tagManagerArgs)
}

export const gtm_event = (opt = {}) => {
    const isGTM = opt.event ? (event_names[opt.event]?.gtm == undefined ? 1 : event_names[opt.event]?.gtm) : 1
    const iswebEngage = opt.event ? (event_names[opt.event]?.webengage || 0) : 0

    opt.event = opt.event ? (event_names[opt.event]?.name || opt.event) : "unknown_event"

    if (iswebEngage && opt.uid) webEngageEvent({ ...opt });

    if (isGTM == 0) return;

    // ga_event({ category: opt.event, action: opt.event })
    opt = { event: getEventStr(opt.event), eventProps: { ...opt } }
    delete opt.eventProps.event

    console.log("gtm_event", opt)
    const data = { dataLayer: { ...opt } }
    TagManager.dataLayer(data)
}

export const gtm_datalayer = (opt = {}, name = "PageDataLayer") => {
    const data = {
        dataLayer: opt || {},
        // dataLayerName: name || 'PageDataLayer'
    }

    TagManager.dataLayer(data)
}

export const gtm_date_time = () => {
    return {
        date: new Date().toLocaleDateString(), //new Date().toISOString().split("T")[0]
        time: new Date().toLocaleTimeString(), //new Date().toISOString().split("T")[1],
    }
}

export const webEngageUserLogin = (uid) => {
    if (uid) {
        if (typeof window.Webengage != "undefined" && typeof window.Webengage.webEngageLogin == "function")
            window.Webengage.webEngageLogin(String(uid))
        else if(window.webengage)
            window.webengage.user.login(uid);
    }
}

export const webEngageUserLogout = () => {
    if (typeof window.Webengage != "undefined" && typeof window.Webengage.webEngageLogout == "function")
        window.Webengage.webEngageLogout()
    else
        window.webengage.user.logout();
}

export const webEngageEvent = (opt = {}) => {
    if (opt.event && opt.uid) {
        if (opt.event.indexOf("TB") < 0 && window.IS_MOBILE_APP) {
            if (opt.event.indexOf("LD_") == 0) opt.event = opt.event.replace("LD_", "TB_")
            else opt.event = "TB_" + opt.event
        }

        const evnt = opt.event;
        const uid = opt.uid

        delete (opt.event)
        delete (opt.uid)

        webEngageUserLogin(uid);
        if (typeof window.Webengage != "undefined" && typeof window.Webengage.setCustomAttribute == "function"){
            window.Webengage.setCustomAttribute(JSON.stringify({ ...opt, eventName: getEventStr(evnt) }))
            console.log("Tribyte Webengage Event", opt)
        }else if(window.webengage){
            console.log("webengage_event", opt)
            window.webengage.track(getEventStr(evnt), opt)
        }
    }
}

export const webEngageUserAttr = (uid = false, opt = {}) => {
    if(uid){
        webEngageUserLogin(uid)
        if(opt && typeof opt == "object" && Object.keys(opt).length){
            if (typeof window.Webengage != "undefined" && typeof window.Webengage.setUserAttribute == "function"){
                opt.uid = parseInt(uid);
                window.Webengage.setUserAttribute(JSON.stringify(opt))
                console.log("Tribyte Webengage user attribute", opt)
            }else if(window.webengage){
                console.log("webengage user attribute", opt)
                window.webengage.user.setAttribute(opt)
            }
        }
    }
}

const getEventStr = (str = "") => {
    if (window.IS_MOBILE_APP && str.indexOf("TB_") != 0) {
        if (str.indexOf("LD_") == 0) str = str.replace("LD_", "TB_")
        else str = "TB_" + str
    }

    return str
}