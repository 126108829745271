import { React, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  authActions,
  login,
  sentOtp,
  logout,
  isUserLoggedIn,
} from "../../store/authenticate";
import { AlertMsg } from "../common/alert";
import HomeHeader from "../layout/homeHeader";
import PhoneVerified from "./phoneVerified";
import BeforeOtp from "./beforeOtp";
import AfterOtp from "./afterOtp";
import axios from "axios";
import { gtm_date_time, gtm_event } from "../../helpers/google_tracking";
import { get_platform } from "../../helpers/common";

function Login() {
  const authState = useSelector((state) => state.auth);
  const [userMobile, setUserMobile] = useState("");
  const [otp, setOtp] = useState("");
  const [loginSignUpToggle, setLoginSignupToggle] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [userToLogin, setUserToLogin] = useState({})

  //function to check if user exist by mobile
  const isUserExist = () => {
    return axios
      .get(
        `${process.env.REACT_APP_SERVER_URL}userapi/v3/search?type=profile&mobile[]=` +
        userMobile,
        { withCredentials: true }
      )
      .then((response) => {
        if (response.data.status && response.data.status == 1 && response.data.data.hasOwnProperty(userMobile) === true) {
          return { status: true, data: response.data.data };
        } else {
          return { status: false };
        }
      })
      .catch((err) => {
        return { status: false };
      });
  };

  //when mobile is submitted call otp api
  const handleMobileSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    if (userMobile.trim() == "") {
      dispatch(
        authActions.showErrorMsg({ msg: "Please enter mobile number!!" })
      );
      setLoading(false);
      return false;
    }
    let mob = /^[6-9]\d{9}$/;
    if (mob.test(userMobile) === false) {
      dispatch(
        authActions.showErrorMsg({ msg: "Please enter valid mobile number!!" })
      );
      setLoading(false);
      return false;
    }
    if (loginSignUpToggle == "" || loginSignUpToggle == "1") {
      //login btn is clicked
      isUserExist().then((res) => {
        if (res.status === true) {
          gtm_event({ event: "tap_login_login", ...gtm_date_time(), platform: get_platform(), mobile: userMobile, uid: res.data[userMobile][0]?.profile?.uid })
          setUserToLogin(res.data[userMobile][0]?.profile || {})
          dispatch(sentOtp({ userMobile: userMobile }));          
        }
        else {
          dispatch(
            authActions.showErrorMsg({ msg: "User does not exist!! Please create new account." })
          );
          setLoading(false);
        }
      });
    }
    else { //when create new account clicked
      isUserExist().then((res) => {
        if (res.status === false) {
          gtm_event({ event: "tap_mobile_signup", ...gtm_date_time(), platform: get_platform(), mobile: userMobile })
          dispatch(sentOtp({ userMobile: userMobile }));          
        }
        else {
          dispatch(
            authActions.showErrorMsg({ msg: "User already exist!! Please login to continue." })
          );
          setLoading(false);
        }
      });      
    }
    setTimeout(() => {
      setLoading(false)   
    }, 3000);
  };

  //when otp is submitted call login/register api
  const handleOtpSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    if (otp.trim() == "") {
      dispatch(authActions.showErrorMsg({ msg: "Please enter otp!!" }));
      setLoading(false);
      return false;
    }

    if (loginSignUpToggle == "" || loginSignUpToggle == "1") {
      gtm_event({ event: "tap_verifyOTP_login", ...gtm_date_time(), platform: get_platform(), mobile: userMobile, uid: userToLogin?.uid, name: userToLogin?.name, grade: userToLogin?.grade_id })
    } else {
      gtm_event({ event: "tap_verifyOTP_signup", ...gtm_date_time(), platform: get_platform(), mobile: userMobile })
    }

    dispatch(login({ userMobile: userMobile, otp: otp }));
    setTimeout(() => {
      setLoading(false)   
    }, 3000);
  };

  //to resend otp
  const resendOtp = (e) => {
    e.preventDefault();
    setLoading(true)
    if (loginSignUpToggle == "" || loginSignUpToggle == "1") {
      gtm_event({ event: "tap_resendOTP_login", ...gtm_date_time(), platform: get_platform(), mobile: userMobile, uid: userToLogin?.uid })
    } else {
      gtm_event({ event: "tap_resendOTP_signup", ...gtm_date_time(), platform: get_platform(), mobile: userMobile })
    }

    dispatch(sentOtp({ userMobile: userMobile }));
    dispatch(authActions.showErrorMsg({ msg: "Otp resent to mobile!!" }));
    setTimeout(() => {
      setLoading(false)   
    }, 3000);
  };

  // when component is unmounted clean states (useeffect is called here only once [] so no reloading state)
  const unmountComponent = () => {
    setUserMobile("");
    setOtp("");
    setLoading(false);
  };
  // to check if user is already logged in
  useEffect(() => {
    // need to refresh login state bcz login component is out of protected route
    console.log("refreshing login state login comp");
    dispatch(isUserLoggedIn());
    return () => {
      unmountComponent();
    };
  }, []);

  return (
    <>
      {!window.IS_MOBILE_APP && <HomeHeader setUserMobile={setUserMobile} />}
      <div className="container-fluid loginpagebg">
        <div className="row LoginPageHeight mbottomnill">
          <div className="col s12 m5 lrnpd LoginBanner">
            <div className="loginpageleftside">
              <div className="LoginBannerContent ShowMobile">
                <div className="bannerCarausalContent ">
                  <h1>
                    Introducing Aakash
                    <br /> NEET-PG Plus
                  </h1>
                  <p>Your Gateway to Medical PG Prep</p>
                </div>
              </div>
            </div>
          </div>
          <div className="col s12 m7 RemoveMinHeight">
            <div className="loginpagerightside">
              <AlertMsg />
              <div className="loginform">
                {authState.otpVerified === true ? ( //after otp verification
                  <PhoneVerified />
                ) : !authState.otpSent ? (
                  <BeforeOtp
                    userMobile={userMobile}
                    setUserMobile={setUserMobile}
                    loginSignUpToggle={loginSignUpToggle}
                    handleMobileSubmit={handleMobileSubmit}
                    setLoginSignupToggle={setLoginSignupToggle}
                    loading={loading}
                    setLoading={setLoading}
                  />
                ) : (
                  <AfterOtp
                    userMobile={userMobile}
                    setUserMobile={setUserMobile}
                    otp={otp}
                    setOtp={setOtp}
                    resendOtp={resendOtp}
                    handleOtpSubmit={handleOtpSubmit}
                    loading={loading}
                    setLoading={setLoading}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Login;
