import { useLocation } from "react-router-dom";
import HomeHeader from "./homeHeader";

function Layout(props) {
  const location = useLocation();
  const showHeader = !window.IS_MOBILE_APP || location.pathname.indexOf("/profile") == -1

  return (
    <div
      className="main-container"
      data-open="click"
      data-menu="vertical-modern-menu"
      data-col="2-columns"
    >
      {showHeader && <HomeHeader />}
      <div id="main-body-conatiner">
        {props.children}
      </div>
    </div>
  );
}

export default Layout;

